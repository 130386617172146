import { AbstractFormType, AddressType, EmailType, PhoneType, SelectType, StringType, UrlType } from '@f2w/form';
import { translate } from 'Services/Translator';
export class MandateType extends AbstractFormType {
    buildFields() {
        return {
            name: new StringType({
                label: translate('mandate.name'),
                required: true,
                constraints: {
                    max: 255,
                },
            }),
            isCorporate: new SelectType({
                label: translate('mandate.type'),
                default: 'true',
                choices: {
                    false: translate('mandate.naturalType'),
                    true: translate('mandate.corporateType'),
                },
            }),
            address: new AddressType({
                inherit: true,
                street: {
                    required: true
                },
                houseNumber: {
                    required: false,
                },
                city: {
                    required: true
                },
                zip: {
                    required: true
                },
            }),
            email: new EmailType({
                label: translate('mandate.emailAddress'),
                required: false,
                constraints: {
                    max: 255,
                },
            }),
            phone: new PhoneType({
                label: translate('mandate.phoneNumber'),
                required: false,
            }),
            website: new UrlType({
                label: translate('mandate.website'),
                required: false,
            }),
        };
    }
}
