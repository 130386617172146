import { createUnitHelper } from '../utils';
import { TypographyVariant } from './TypographyVariant';
import { typographyTokens } from './typography.tokens';
export * from './typography.tokens';
export function createTypography({ htmlFontSize = 14, fontFamily = '"Rubik", "Roboto", "Helvetica", "Arial", sans-serif', fontSize = 14, lineHeight = 1.5, borderRadius = '0.5em', fontWeight = {}, outputUnit = 'px', variants: variantsOptions = {}, }) {
    const unit = createUnitHelper(fontSize, htmlFontSize);
    const instance = {
        unit,
        outputUnit,
        fontSize: unit.create('px', fontSize, outputUnit),
        lineHeight: unit.create('em', lineHeight, 'em'),
        borderRadius: unit.create('px', borderRadius, outputUnit),
        fontFamily,
        fontWeight: {
            $300: 300,
            $400: 400,
            $500: 500,
            $600: 600,
            $700: 700,
            ...fontWeight
        },
        pxToRem: (v) => unit.px.rem(v),
        remToPx: (v) => unit.rem.px(v),
    };
    const variants = {};
    const resolveVariant = options => (typeof options === 'function' ? options(instance) : options);
    const createVariant = (name) => (new TypographyVariant({
        ...resolveVariant(typographyTokens[name]),
        ...resolveVariant(variantsOptions[name])
    }, instance));
    for (let name in typographyTokens) {
        variants[name] = createVariant(name);
    }
    return {
        ...instance,
        variants,
        ...variants,
    };
}
;
