import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import UserServices, { getAccountUsers, getUsersInAllMandates } from 'Services/UserServices';
import { translate } from 'Services/Translator';
import { SelectBadgeInline, SearchSelect, useSelectValueManager } from 'Atoms/Select';
import { Button } from 'Atoms/Button';
import { FlexRow } from 'Atoms/Layout';
import { useToastDispatcherApi } from 'Components/Dispatcher';
import useConfirmHandler from 'Hooks/useConfirmHandler';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { Table } from '@f2w/data-table/table-types';
import MandateServices from './MandateServices';
import { useEventCallback } from '@restart/hooks';
import { Icon } from 'Atoms/Icon';
import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';
export const getAccountUsersOptions = () => {
    return getAccountUsers()
        .then((data) => data.map(v => ({
        value: v.id,
        label: v.fullName,
        user: v,
    })));
};
export const MandateTableModel = Table.factory()(() => ({
    tableId: 'Mandate',
    usePagination: {},
    manualControl: true,
    useGlobalFilter: {},
    getData: async ({ request }) => MandateServices.getPaginatedMandates(request),
    Actions: ({ openMandateForm, openUserForm }) => (_jsxs(FlexRow, { gap: 10, children: [openUserForm && _jsx(Button, { onClick: () => openUserForm(), icon: 'plus', label: translate('mandates.newUser') }), openMandateForm && _jsx(Button, { onClick: () => openMandateForm(), icon: 'plus', label: translate('mandates.new') })] })),
    RowActions: ({ refresh, toast, openMandateForm, row: { original: data }, row }) => {
        return (_jsxs(ActionsDropdown, { id: row.id, children: [openMandateForm && _jsxs(ActionsDropdown.Link, { onClick: () => openMandateForm(data.id), title: translate('serviceProvider.edit'), children: [_jsx(Icon, { icon: "edit-3", size: undefined }), " ", _jsx("span", { children: translate('mandates.edit') })] }), _jsx(ActionsDropdown.Link, { onClick: useConfirmHandler(() => {
                        MandateServices.deleteMandate(data.id).then(() => {
                            refresh();
                            toast?.success?.({
                                message: translate('mandate.successfullyDeleted')
                            });
                        })
                            .catch((e) => {
                            toast?.error?.({
                                message: String(e)
                            });
                            console.error(e);
                        });
                    }, translate('mandates.removePromptMessage')), title: translate('mandates.remove'), icon: 'trash-2', children: translate('mandates.remove') })] }));
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        name: {
            accessor: (mandate) => mandate.name,
            Header: translate('mandates.name'),
            minWidth: 180,
            useSortBy: {},
            width: 200,
        },
        users: {
            accessor: 'userIds',
            CustomHeader: ({ userOptions, dataResponse, data: dataResult, state: { pageIndex }, allMandatesUsers, addUserToAllMandates, setAllMandatesUsers, }) => {
                const select = useSelectValueManager({
                    options: userOptions,
                    value: allMandatesUsers,
                    isMulti: true,
                    onChange: useDebouncedCallback((selectedOptions) => {
                        const selectedIds = selectedOptions.map(o => o.value);
                        const newUserIds = selectedIds.filter(v => !allMandatesUsers.includes(v));
                        dataResponse.updateResults(mandate => {
                            mandate.userIds = [...new Set([...mandate.userIds, ...newUserIds])];
                            return mandate;
                        });
                        addUserToAllMandates(newUserIds)
                            .then(() => {
                            setAllMandatesUsers(selectedIds);
                        });
                    }, 1000),
                });
                return pageIndex > 0 || dataResult?.length > 4 ? (_jsx(SearchSelect, { select: select, label: translate('mandates.addUserToAllMandates') })) : null;
            },
            Cell: ({ value, userOptions, loadUsersInAllMandates, dataResponse, updateMandateUserAccess, row: { original: { id } }, }) => {
                const { error } = useToastDispatcherApi();
                const [selectedUsers, setSelectedUsers] = useState(value);
                const select = useSelectValueManager({
                    options: userOptions,
                    value,
                    isMulti: true,
                    onChange: useDebouncedCallback((selectedOptions) => {
                        const newValues = selectedOptions.map(o => o.value);
                        dataResponse.updateResults(mandate => {
                            if (mandate.id === id) {
                                mandate.userIds = newValues;
                            }
                            return mandate;
                        });
                        updateMandateUserAccess(id, { userIds: newValues })
                            .then(d => {
                            setSelectedUsers(newValues);
                            loadUsersInAllMandates().then(() => { });
                            return d;
                        })
                            .catch(({ response }) => {
                            if (response.status === 400) {
                                error({
                                    message: response.data.message,
                                });
                                dataResponse.updateResults(mandate => {
                                    if (mandate.id === id) {
                                        mandate.userIds = selectedUsers;
                                    }
                                    return mandate;
                                });
                            }
                        });
                    }, 1000),
                });
                return (_jsx(SelectBadgeInline, { select: select, showRemoveAll: true, fixedDropdown: true, removeAllLabel: translate('mandates.removeAll'), removeAllPromptMessage: translate('mandates.removeAllPromptMessage') }));
            },
        },
    },
}));
export function useMandateTable({ updateMandateUserAccess = MandateServices.updateMandateUserAccess, addUserToAllMandates = UserServices.addUserToAllMandates, ...props }) {
    const [userOptions, setUserOptions] = useState([]);
    const [allMandatesUsers, setAllMandatesUsers] = useState([]);
    const loadAccountUsersOptions = useCallback(async () => {
        return getAccountUsersOptions().then(d => {
            setUserOptions(d);
            return d;
        });
    }, []);
    const loadUsersInAllMandates = useCallback(async () => {
        return getUsersInAllMandates().then(d => {
            setAllMandatesUsers(d);
            return d;
        });
    }, []);
    const loadUsers = useCallback(async () => {
        table.setLoading(true);
        try {
            await loadAccountUsersOptions();
            await loadUsersInAllMandates();
        }
        catch (e) {
            toast?.error?.({
                message: String(e)
            });
        }
        table.setLoading(false);
    }, []);
    const toast = useToastDispatcherApi();
    const tableModel = React.useMemo(() => MandateTableModel.create(), []);
    const table = tableModel.useTable({
        loadUsersInAllMandates,
        userOptions,
        allMandatesUsers,
        setAllMandatesUsers,
        toast,
        updateMandateUserAccess,
        addUserToAllMandates,
        ...props
    });
    const _refresh = table.refresh;
    table.refresh = useEventCallback(async () => {
        await loadUsers();
        _refresh();
    });
    useEffect(() => {
        loadUsers();
    }, []);
    return table;
}
