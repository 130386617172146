import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import './Tooltip.styles.scss';
import { OverlayTrigger } from './OverlayTrigger';
import { Tooltip } from 'Components/Atoms';
export const Tooltip2 = ({ id, children, content, size = 'md', ...props }) => {
    if (!children || !content) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsx(OverlayTrigger, { placement: 'bottom', ...props, overlay: _jsx(Tooltip, { id: id, size: size, children: content }), children: children }));
};
Tooltip2.displayName = 'Tooltip2';
