import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import Actions from './RentDataTableActions';
import RentState from './components/RentState';
import { formatAmount, formatMoney } from 'Services/NumberHelper';
import { RentStateFilter } from './components/RentStateFilter';
import { RentalObjectCard } from 'Components/App/card';
import moment from 'moment';
import { DateType } from '@f2w/form';
import { Text } from 'Atoms/Text';
import { RentPotentialCalculationButton } from 'Pages/Rent/components/RentPotentialCalculationButton';
const formatBillingCycleMonths = (billingCycle) => {
    if (billingCycle === 1) {
        return translate('monthly');
    }
    let months = [];
    for (let month = billingCycle; month <= 12; month += billingCycle) {
        months.push(moment(month, 'M').format('MMM'));
    }
    return months.join(', ');
};
const model = Table.factory()(() => ({
    tableId: 'Rent',
    RowActions: Actions,
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    initialState: {
        pageSize: 25,
        sortBy: []
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        property: {
            Header: translate('contract.table.column.rentalObject'),
            accessor: () => 'property.name',
            width: 400,
            Cell: ({ row: { original: { building, rentalObject } } }) => {
                return (_jsx(RentalObjectCard, { rentalObjectName: rentalObject.name, rentalObjectId: rentalObject.id, buildingName: building.name }));
            },
            useSortBy: {},
        },
        parties: {
            Header: translate('contract.parties'),
            accessor: (rent) => rent.contract.parties,
            Cell: ({ value: parties }) => {
                return (_jsx(_Fragment, { children: parties.join(', ') }));
            },
            useSortBy: {},
        },
        rentPeriod: {
            Header: translate('contract.table.column.contractPeriod'),
            Cell: ({ row: { original: { startsAt, endsAt } } }) => {
                return (_jsxs(_Fragment, { children: [DateType.format(startsAt), " / ", endsAt ? DateType.format(endsAt) : translate('contract.ends.notLimited')] }));
            },
            useSortBy: {}
        },
        state: {
            Header: translate('rent.status'),
            accessor: (rent) => rent.state,
            Cell: ({ row }) => {
                const rent = row.original;
                return (_jsx(RentState, { state: rent.state }));
            },
            useFilters: {
                Filter: RentStateFilter
            },
        },
        grossAmount: {
            Header: translate('contract.table.column.netAndGrossRent'),
            accessor: 'grossAmount',
            cellProps: { noWrap: true },
            Cell: ({ row }) => (_jsxs(Text, { children: ["CHF ", _jsx(Text, { as: 'strong', body: 500, primary: 500, children: formatAmount(row.original.netAmount) }), "/", formatAmount(row.original.grossAmount)] })),
            useSortBy: {},
        },
        rentPotential: {
            Header: '',
            Cell: ({ row, showRentPotential }) => {
                return _jsx(RentPotentialCalculationButton, { rent: {
                        id: row.original.id,
                        canCalculateRentPotential: row.original.canCalculateRentPotential,
                    }, onClick: showRentPotential });
            },
        },
        ancillaryExpense: {
            Header: translate('rent.ancillaryExpenses'),
            accessor: (rent) => rent.ancillaryExpense,
            Cell: ({ value }) => formatMoney(value),
            useSortBy: {},
        },
        billingCycle: {
            Header: translate('rent.invoicing'),
            Cell: ({ value }) => formatBillingCycleMonths(value)
        }
    },
}));
export default model;
