import { jsx as _jsx } from "react/jsx-runtime";
import { createWithScope } from '@f2w/theme';
import { isString } from 'Utils';
import { renderContent } from 'Components/Base';
import { iconsList } from './data';
export { iconsList };
export const IconRoot = createWithScope('icon', 'root', {
    tag: 'svg',
    className: 'fw-icon',
});
export const renderIcon = (icon, { width = '1em', height = '1em', fontSize, style, stroke, strokeWidth, ...rest }) => {
    const props = {
        viewBox: "0 0 24 24",
        fill: 'none',
        width,
        height,
        style: { ...style, fontSize },
        ...rest,
        xmlns: "http://www.w3.org/2000/svg",
    };
    const IconSvg = iconsList[icon] || icon;
    if (isString(IconSvg)) {
        return (_jsx(IconRoot, { ...props, children: _jsx("g", { stroke: stroke ?? 'currentColor', strokeWidth: strokeWidth ?? 1.8, dangerouslySetInnerHTML: { __html: IconSvg } }) }));
    }
    return renderContent(IconSvg, props);
};
