import { tokenBuilder } from '@f2w/theme/system';
import { sizeToken } from '@f2w/theme';
export const inputStateToken = tokenBuilder()({
    default: {
        isDefault: true,
    },
    primary: {},
    success: {
        icon: 'check',
        styles: ({ palette: { success } }) => ({
            color: success,
        }),
    },
    error: {
        icon: 'alert-circle',
        styles: ({ palette: { error } }, {}) => ({
            color: error,
        }),
    },
    disabled: {
        disabled: true,
        styles: ({ palette: { error } }) => ({}),
    },
    readonly: {
        readonly: true,
    },
    loading: {
        icon: 'spinner',
        loading: true,
    },
    validating: {
        icon: 'spinner',
        loading: true,
        styles: ({ palette: { error, neutral } }, {}) => ({
            color: error,
            iconColor: neutral,
        }),
    },
});
export const inputSizeToken = sizeToken.extend({
    xs: {
        styles: (prev) => ({
            ...prev,
            paddingX: 6,
            paddingY: 2,
        }),
    },
    sm: {
        styles: (prev) => ({
            ...prev,
            paddingX: 10,
            paddingY: 4,
        }),
    },
    md: {
        styles: (prev) => ({
            ...prev,
            paddingX: 12,
            paddingY: 6,
        }),
    },
    lg: {
        styles: (prev) => ({
            ...prev,
            paddingX: 16,
            paddingY: 8,
        }),
    },
});
