import { jsx as _jsx } from "react/jsx-runtime";
import { Schema, SimpleType } from '../core';
import { FormControl } from 'Atoms/Input';
import { useEventCallback } from '@restart/hooks';
import classnames from 'classnames';
import { ImageField } from '../core/form/ImageField';
import { isString } from 'Utils';
import { createImage } from '@f2w/utils';
export class ImageType extends SimpleType {
    constructor(options, parent) {
        super({
            ...options,
        }, parent, Schema.string());
    }
    _createField(props) {
        return new ImageField(this, props);
    }
    getFormValue() {
        return this.field.state.image;
    }
    cast(value, ignoreDefaults) {
        if (value == null || value === "") {
            if (this.isNullable)
                return null;
            if (!ignoreDefaults) {
                return this.cast(this.schema.getDefault(), true);
            }
            return undefined;
        }
        if (isString(value))
            return value;
        if (value instanceof Blob) {
            return URL.createObjectURL(value);
        }
        if (this.isNullable)
            return null;
    }
    _renderWidget(props) {
        const onChange = useEventCallback((value) => {
            this.onChange(value);
        });
        const onBlur = useEventCallback((event) => {
            this.field.setTouched(true, true);
        });
        return this.WidgetComponent({
            ...this.getWidgetProps(),
            onChange,
            onBlur,
            onDelete: () => this.field.setValue(null),
            onLoad: (src) => {
                createImage(src)
                    .then(image => {
                    this.options.onImageChange?.(image, this);
                })
                    .catch(e => {
                    console.error('Image load error', e.message);
                });
            },
            value: (this.field.value ?? '').toString(),
            ...props,
            className: classnames('fw-form__widget', props?.className),
        });
    }
    WidgetComponent(props) {
        switch (this.options.variant) {
            case 'avatar':
                return _jsx(FormControl.AvatarImage, { ...props });
            default:
                return _jsx(FormControl.Image, { ...props });
        }
    }
}
