import { Breakpoint } from "./createBreakpoints";
import { createPalette } from "./palette";
import { createTypography } from "./typography";
import { setDebugVariable } from '@f2w/utils';
export default function createTheme(options = {}) {
    const palette = createPalette(options.palette || {});
    const typography = createTypography(options.typography || {});
    const breakpoint = Breakpoint.create(options?.breakpoint);
    const theme = {
        typography,
        breakpoint,
        palette,
        rem: typography.pxToRem,
        px: typography.remToPx,
        mode: 'light',
        tokens: {},
    };
    setDebugVariable({ theme });
    return theme;
}
