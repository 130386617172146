import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import camelize from 'dom-helpers/camelize';
import classNames from 'classnames';
export const dataAttr = (value) => (value === true ? "" : value ? String(value) : undefined);
export const ariaAttr = (value) => (value === true ? true : value ? String(value) : undefined);
export const slotAttr = (scope, part) => ({
    'data-scope': scope,
    'data-part': part,
});
export const slotCss = (scope, ...parts) => {
    if (!parts.length) {
        return `[data-scope="${scope}"]`;
    }
    if (parts.length > 1) {
        const dataParts = parts.map(part => `[data-part="${part}"]`);
        return `[data-scope="${scope}"]:is(${dataParts.join(',')})`;
    }
    return `[data-scope="${scope}"][data-part="${parts.shift()}"]`;
};
export function createWithScope(scope, part, options = Object.create(null)) {
    const { tag: InitialTag, defaultProps, props: getProps, className: initialClassName, displayName = pascalCase(`${scope}-${part}`), } = options;
    const scopeProps = slotAttr(scope, part);
    const RenderFn = ({ tag: Tag = InitialTag || 'div', ...rest }, ref) => {
        let props = { ref, ...scopeProps, ...rest };
        if (getProps)
            props = getProps(props) || props;
        if (initialClassName)
            props.className = classNames(initialClassName, props.className);
        return (_jsx(Tag, { ref: ref, ...scopeProps, ...props }));
    };
    const RefComponent = React.forwardRef(RenderFn);
    RefComponent.displayName = displayName;
    RefComponent.defaultProps = defaultProps;
    return RefComponent;
}
export class SlotFactory {
    scope;
    constructor(scope) {
        this.scope = scope;
        this.create = this.create.bind(this);
        this.attr = this.attr.bind(this);
        this.css = this.css.bind(this);
    }
    create(slot, options = Object.create(null)) {
        return createWithScope(this.scope, slot, options);
    }
    attr(slot) {
        return slotAttr(this.scope, slot);
    }
    css(...slots) {
        return slotCss(this.scope, ...slots);
    }
    scopeCss() {
        return slotCss(this.scope);
    }
}
export const Slot = ({ scope, part, as: Tag = 'div', ...props }) => {
    return _jsx(Tag, { ...slotAttr(scope, part), ...props });
};
const pascalCase = (str) => str[0].toUpperCase() + camelize(str).slice(1);
