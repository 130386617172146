import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { getPrefix } from './theme';
const prefix = getPrefix('container');
const BaseContainer = forwardRef(({ fluid, as: Component = 'div', className, ...props }, ref) => {
    const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid';
    return (_jsx(Component, { ref: ref, ...props, className: classNames(className, fluid ? `${prefix}${suffix}` : prefix) }));
});
export const Container = Object.assign(BaseContainer, {
    Root: `.${prefix}`,
});
