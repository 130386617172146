import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useEventCallback } from '@restart/hooks';
import * as IMask from 'imask';
import classnames from 'classnames';
import { AbstractType } from './AbstractType';
import * as Yup from '../schema';
import { orFunction, OrFunction } from 'Utils/types';
import { MaskedField, ScalarField, } from '../form';
import { CollectionType } from './CollectionType';
import { FormControl } from 'Components/Atoms/Input';
export class AbstractSimpleType extends AbstractType {
    constructor(options, parent, schema) {
        super(options, parent, schema);
    }
    format(value) {
        const val = this.cast(value, true);
        return (val == null ? '' : val.toString());
    }
    static format(value) {
        const type = new this({});
        return type.format(value);
    }
    static useFormat(value) {
        return useMemo(() => this.format(value), [value]);
    }
    cast(value, ignoreDefaults) {
        if (value == null || value === "") {
            if (this.isNullable)
                return null;
            if (!ignoreDefaults)
                return this.cast(this.schema.getDefault(), true);
            switch (this.schema.type) {
                case 'string': return '';
            }
            return undefined;
        }
        try {
            return this.schema.cast(value);
        }
        catch (error) {
            console.error('Can not cast field %o', this.field.path, { value, error });
        }
        if (this.isNullable)
            return null;
    }
    onChange(value, event) {
        this.field.setValue(value);
        this.field.setTouched(true, false);
    }
    onBlur(event) {
        this.field.setTouched(true, true);
    }
    getWidgetProps() {
        return {
            id: `${this.field.id}--widget`,
            key: this.field.id,
            name: this.field.path,
            disabled: orFunction(this.options?.disabled),
            placeholder: this.options?.placeholder,
            ...this.options?.getProps?.(),
        };
    }
    _renderWidget(props) {
        const onChange = useEventCallback((value, event) => {
            this.onChange(value, event);
        });
        const onBlur = useEventCallback((event) => {
            this.onBlur(event);
        });
        return this.WidgetComponent({
            ...this.getWidgetProps(),
            onChange,
            onBlur,
            $size: this.parent?.parent instanceof CollectionType ? 'sm' : 'md',
            $variant: this.field.showError ? 'error' : 'default',
            value: (this.field.value ?? '').toString(),
            ...props,
            className: classnames('fw-form__widget', props?.className),
        });
    }
    WidgetComponent(props) {
        if (this.options.autocomplete) {
            return _jsx(FormControl.Autocomplete, { ...props, ...this.options.autocomplete });
        }
        return _jsx(FormControl.Input, { type: 'text', ...props });
    }
}
export class SimpleType extends AbstractSimpleType {
    _createField(props) {
        return new ScalarField(this, props);
    }
}
export class MaskedType extends AbstractSimpleType {
    constructor(options, parent, schema) {
        super(options, parent, schema);
    }
    getMask() {
        if (!this._mask)
            this._mask = IMask.createMask(OrFunction(this.options.mask));
        return this._mask;
    }
    format(value) {
        const _mask = this.getMask();
        _mask.value = value == null ? '' : value.toString();
        return _mask.value;
    }
    _createField(props) {
        return new MaskedField(this, props);
    }
    WidgetComponent({ onChange, value, ...props }) {
        return super.WidgetComponent({
            type: 'text',
            ...props,
            defaultValue: this.field.initialValue ?? '',
            ref: this.field.maskRef,
        });
    }
}
(function (SimpleType) {
    class ExtendNumber extends SimpleType {
        constructor(options, parent) {
            super(options, parent, Yup.number());
        }
    }
    SimpleType.ExtendNumber = ExtendNumber;
    class ExtendString extends SimpleType {
        constructor(options, parent) {
            super(options, parent, Yup.string());
        }
    }
    SimpleType.ExtendString = ExtendString;
    class MaskedNumber extends MaskedType {
        constructor(options, parent) {
            super(options, parent, Yup.number());
        }
    }
    SimpleType.MaskedNumber = MaskedNumber;
    class MaskedString extends MaskedType {
        constructor(options, parent) {
            super(options, parent, Yup.string());
        }
    }
    SimpleType.MaskedString = MaskedString;
})(SimpleType || (SimpleType = {}));
