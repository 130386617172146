import { jsx as _jsx } from "react/jsx-runtime";
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { translate } from 'Services/App';
import { BaseDataServices } from './services';
import { RentalObjectDataTableType } from './table/RentalObjectDataTableType';
import { useOpenRentalObject } from './hooks/useOpenRentalObject';
export const RentalObjectPage = (props) => {
    useOpenRentalObject(props);
    const table = RentalObjectDataTableType.useTable({}, () => ({
        manualControl: true,
        getData: ({ request }) => BaseDataServices.getBaseDataRentalObjectsList(request),
    }));
    return (_jsx(PageOverview, { instance: table, variant: 'content', title: translate("rentalObjectPage.title"), inlineFilters: true }));
};
