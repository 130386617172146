import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Body, Container } from "./body";
export const Page = {
    Root: ({ children, ...props }) => {
        return _jsx(Body, { split: true, style: { height: '100%' }, paddingX: '25px', children: children });
    },
    Preview: ({ children, side, ...props }) => {
        return _jsxs(Body, { split: true, style: { height: '100%' }, paddingX: '25px', children: [_jsx(Container, { maxWidth: '800px', children: children }), _jsx(Container, { bg: 'neutral.$50', style: { borderLeft: '1px solid #d7d8db' }, children: side })] });
    },
};
