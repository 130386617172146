import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as rt from 'react-table';
import React, { useCallback } from 'react';
import { useDropzone as useReactDropzone } from 'react-dropzone';
import { Icon } from 'Atoms/Icon';
import { translate } from 'Services/App';
import { Td, Tr } from 'Atoms/Table';
import { FlexRow } from 'Atoms/Layout';
import { formatBytes } from '@f2w/utils';
import { ProgressBar } from 'Atoms/ProgressBar';
import { Text } from 'Atoms/Text';
const actions = {
    updateLimits: 'updateLimits',
};
export const useDropzone = {
    initOptions: (options, table) => ({
        initialState: {
            spaceAllowed: 0,
            spaceUsed: 0,
        }
    }),
    hook: hooks => {
        hooks.stateReducers.push((state, action, prev, instance) => {
            if (action.type === actions.updateLimits) {
                return {
                    ...state,
                    spaceUsed: action.spaceUsed ?? prev.spaceUsed,
                    spaceAllowed: action.spaceAllowed ?? prev.spaceAllowed,
                };
            }
        });
        hooks.useInstance.push(useInstance);
        hooks.getTableProps.push((props, meta) => useTableProps(props, meta.userProps, meta.instance));
    },
};
function useInstance(instance) {
    const { onDrop, onDropAccepted, onDropRejected, dropzoneOptions, dispatch, } = instance;
    const getInstance = rt.useGetLatest(instance);
    const dropzone = useReactDropzone({
        noDragEventsBubbling: true,
        noClick: true,
        noKeyboard: true,
        ...dropzoneOptions,
        onDrop: (acceptedFiles, fileRejections, event) => onDrop({ acceptedFiles, fileRejections }, getInstance()),
        onDropAccepted: (files, event) => onDropAccepted(files, getInstance()),
        onDropRejected: (files, event) => onDropRejected(files, getInstance()),
    });
    const getRootProps = React.useCallback((props) => {
        return dropzone.getRootProps(props);
    }, [dropzone.getRootProps]);
    const setDropzoneLimits = React.useCallback((spaceUsed, spaceAllowed) => dispatch({ type: actions.updateLimits, spaceUsed, spaceAllowed }), [dispatch]);
    Object.assign(instance, {
        setDropzoneLimits,
        dropzone: {
            ...dropzone,
            getRootProps,
        },
    });
}
function useTableProps(props, userProps, instance) {
    const { dropzone } = instance;
    const handleClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dropzone.open();
    }, [dropzone.open]);
    const EmptyRow = useCallback((theme) => {
        const { spaceUsed, spaceAllowed } = instance.state;
        return (_jsxs("div", { className: theme?.Css?.tableDropzonePlaceholder, children: [_jsx(Icon, { size: 68, icon: 'upload-cloud' }), _jsxs("div", { children: [_jsxs("span", { children: [translate('components.dragAndDrop'), " "] }), _jsx("a", { href: '#', onClick: handleClick, children: translate(('components.dragAndDrop.browse')) })] }), !!spaceAllowed && _jsxs(FlexRow, { alignCenter: true, gap: 10, children: [_jsx(ProgressBar, { size: 32, borderSize: 4, value: spaceUsed, maxValue: spaceAllowed }), _jsxs(Text, { as: 'span', h6: true, neutral: 200, children: [formatBytes(spaceUsed), spaceAllowed > 0 && _jsxs(_Fragment, { children: [" / ", formatBytes(spaceAllowed)] })] })] })] }));
    }, [handleClick]);
    const HeaderRow = useCallback((theme) => {
        const { spaceUsed, spaceAllowed } = instance.state;
        return (_jsx(Tr, { bodyRow: false, className: theme?.Css?.rowDropzone, children: _jsx(Td, { sticky: 'left', type: 'content', colSpan: true, align: 'center', valign: 'middle', children: _jsxs("div", { onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dropzone.open();
                    }, children: [_jsxs(FlexRow, { alignCenter: true, justifyCenter: true, textCenter: true, gap: 16, font: 'medium', children: [_jsx(Icon, { icon: 'upload-cloud', fontSize: 24 }), " ", _jsxs("span", { children: [translate('components.dragAndDrop'), " ", _jsx("u", { children: translate('components.dragAndDrop.browse') })] })] }), !!spaceAllowed && _jsxs(FlexRow, { inline: true, alignCenter: true, gap: 16, className: theme?.Css?.limits, children: [_jsx(ProgressBar, { size: 24, borderSize: 4, value: spaceUsed, maxValue: spaceAllowed }), _jsxs(Text, { medium: true, neutral: 600, children: [formatBytes(spaceUsed), spaceAllowed > 0 && _jsxs(_Fragment, { children: [" / ", formatBytes(spaceAllowed)] })] })] })] }) }) }));
    }, [dropzone.open]);
    return {
        ...props,
        EmptyRow,
        HeaderRow: instance.hasData ? HeaderRow : undefined,
    };
}
