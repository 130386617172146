import * as rt from 'react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as tableHooks from '../hooks';
import { useDispatcherApi } from 'Components/Dispatcher';
import { getUrlParams } from 'Utils/getUrlParams';
import { isDevEnv, LocalStorage, setDebugVariable } from '@f2w/utils';
import { renderContent } from 'Components/Base';
import { useEventCallback } from '@restart/hooks';
export var UseCore;
(function (UseCore) {
    function _createInstance({ isLoading: _initialIsLoading = false, stateCachedKeys: _cachedStateKeys, columns, ...options }, hooks) {
        const getRowId = useCallback((row, relativeIndex, parent) => {
            const id = options.getId?.(row) || row?.original?.['id'] || row?.id || row.index || relativeIndex;
            return parent
                ? [parent.original?.['id'] || parent.index, id].filter(v => !!v).join('-')
                : id;
        }, [options.getId]);
        const [isLoading, setLoading] = useState(_initialIsLoading);
        const dispatcher = useDispatcherApi();
        const _render = useEventCallback((as) => renderContent(as, { ...instance }));
        const { Actions, BulkActions } = options;
        const renderActions = useCallback(() => renderContent(Actions, { ...instance }), [Actions]);
        const renderBulkActions = useCallback(() => renderContent(BulkActions, { ...instance }), [BulkActions]);
        const { tableId, disableCache, } = options;
        const { initialState, cache } = useMemo(() => {
            const keys = _cachedStateKeys?.length ? _cachedStateKeys : [];
            const cache = {
                id: !disableCache && tableId && keys.length ? `dt/${tableId}` : null,
                keys,
            };
            let storageData = cache.id ? LocalStorage.get(cache.id) : {};
            return {
                cache,
                initialState: {
                    ...options?.initialState,
                    ...storageData,
                    ...getUrlParams()
                }
            };
        }, [tableId, disableCache]);
        const instance = rt.useTable({
            columns: columns,
            getRowId,
            isLoaded: true,
            hasData: options.data.length > 0,
            data: options.data,
            ...options,
            options,
            isLoading,
            setLoading,
            initialState,
            _render,
            hasActions: !!Actions,
            hasBulkActions: !!BulkActions,
            renderActions,
            renderBulkActions,
            dispatcher,
        }, ...[tableHooks.PreTableHook, ...hooks, tableHooks.PostTableHook]);
        const { state: _state } = instance;
        useEffect(() => {
            if (cache.id) {
                const _prev = LocalStorage.get(cache.id, initialState);
                const _data = Object.create(null);
                const updatedKeys = _cachedStateKeys.filter(k => {
                    if (_prev[k] !== _state[k]) {
                        _data[k] = _state[k] ?? undefined;
                        return true;
                    }
                });
                if (updatedKeys.length > 0) {
                    isDevEnv() && console.log('update storage', { update: _data });
                    LocalStorage.set(cache.id, _data);
                }
            }
        }, [cache.id, ...cache.keys.map(k => _state[k])]);
        Object.assign(instance, {
            pageRows: (!instance.manualPagination && instance.page) || instance.rows,
            pageTotalElements: instance?.manualControl ? instance?.dataResponse?.totalElements : instance?.rows?.length,
        });
        window['reactDataTable'] = instance;
        setDebugVariable('fw_table', instance);
        return instance;
    }
    UseCore._createInstance = _createInstance;
})(UseCore || (UseCore = {}));
