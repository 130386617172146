import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { SectionBase } from './base';
import { Icon } from 'Atoms/Icon';
import { FlexCol, FlexRow } from '../Flex';
import { Div } from 'Atoms/Layout';
import { Title } from 'Atoms/Text';
import { Button } from 'Atoms/Button';
export const Footer = styled(SectionBase).attrs(p => ({ as: p.as ?? 'footer' })) ``;
const HeaderBase = styled(SectionBase).attrs((props) => ({
    as: 'header',
    'data-slot': 'header',
    ...props,
})) ``;
export const ContentHeader = styled(HeaderBase) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
`;
const PageHeaderRoot = styled(HeaderBase) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    flex-wrap: wrap;
    gap: 10px;
`;
const PageHeader = ({ title, toolbar, backLink, ...props }) => {
    if (!(title || toolbar))
        return null;
    const _title = title && _jsx(Title, { as: 'h1', size: 'h3', children: title });
    return (_jsxs(PageHeaderRoot, { ...props, children: [backLink ? (_jsxs(FlexRow, { alignCenter: true, justifyStart: true, children: [_jsx(Button, { icon: 'arrow-left', variant: 'link', color: 'neutral', href: backLink, style: { padding: 0, fontSize: '23px', justifyContent: 'start', marginLeft: -4 } }), _title] })) : (_title), toolbar && _jsx(Div, { ml: 'auto', children: toolbar })] }));
};
const Avatar = {
    Root: (props) => {
        const { avatarProps, avatarText, avatarIcon, pretitle, title, subtitle, actions, children, ...rest } = props;
        return (_jsxs(FlexRow, { wrap: 'wrap', justifySpaceBetween: true, alignCenter: true, ...rest, children: [_jsxs(FlexRow, { wrap: 'wrap', inline: true, alignCenter: true, children: [_jsx(Header.AvatarRoot, { ...avatarProps, children: avatarText ?? _jsx(Icon, { icon: avatarIcon ?? 'file-text' }) }), _jsxs(FlexCol, { gap: 2, children: [pretitle && (_jsx(Avatar.Small, { children: pretitle })), title && (_jsx(Avatar.Title, { children: title })), subtitle && (_jsxs(Avatar.Text, { children: [_jsx(Icon, { icon: "corner-down-right", size: 14 }), " ", subtitle] }))] })] }), !!actions && _jsx(FlexRow, { gap: 10, alignCenter: true, justifyFlexEnd: true, children: actions }), children] }));
    },
    Small: styled('span') `
        display: block;
        color: ${p => p.theme.palette.neutral.$300};
        ${p => p.theme.typography.pretitle.css()};
    `,
    Text: styled('span') `
        display: block;
        margin: 4px 0;
        color: ${p => p.theme.palette.neutral.$900};
        ${p => p.theme.typography.small.css()};

        ${Icon} {
            position: relative;
            top: -3px;
            margin-right: 5px;
        }
    `,
    Title: styled('h2') `
        ${p => p.theme.typography.large.css({ fontWeight: 500, fontFamily: undefined })};
        color: ${p => p.theme.palette.neutral.$800};
        margin: 0;

        small {
            display: block;
            color: ${p => p.theme.palette.neutral.$300};
            ${p => p.theme.typography.pretitle.css()};
        }
    `,
};
const blocks = {
    Base: HeaderBase,
    Split: ContentHeader,
    Sticky: ({ sticky = 0, ...props }) => _jsx(ContentHeader, { sticky: sticky, ...props }),
    Page: PageHeader,
    AvatarRoot: styled.div `
        border-radius: 50%;
        background: ${p => p.theme.palette.primary.$50};
        color: ${p => p.theme.palette.primary.$500};
        width: 48px;
        height: 48px;
        font-size: 22px;
        line-height: 23px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
    `,
    Avatar: Avatar.Root,
};
export const Header = Object.assign(HeaderBase, blocks);
