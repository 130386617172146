import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Text } from './text';
function getText(value) {
    if (Array.isArray(value)) {
        return value.map(getText).filter(v => !!v).join('');
    }
    if (typeof value === "string") {
        return value;
    }
    if (React.isValidElement(value)) {
        if (value.props['children']) {
            return getText(value.props['children']);
        }
    }
    return '';
}
export const EllipsisText = ({ title, text, children, ...props }) => {
    const resolvedTitle = useMemo(() => (typeof title === "string" ? title : (title !== false ? getText(children) : undefined)), [title]);
    return _jsx(EllipsisTextRoot, { title: resolvedTitle, ...props, children: _jsx(Text, { ...text, children: children }) });
};
const EllipsisTextRoot = styled.div `
  position: relative;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  > span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
