import { jsx as _jsx } from "react/jsx-runtime";
import { registerEntrypoint } from '@fw/FairwalterApp';
import './Administration/AdministrationPageEntry';
import './Administration/Maintenance/MaintenanceBannerEntry';
import './Administration/Maintenance/MaintenancePageEntry';
import './GettingStartedPage/GettingStartedPageEntry';
import './Creditor/CreditorPageEntry';
import './BankAccount/UpsertBankAccountSegmentEntry';
import './BankAccount/BankAccountPageEntry';
import './Person/Overview/PersonPageEntry';
import './BaseData/BaseDataPageEntry';
import './Dashboard/DashboardPageEntry';
import './Debitor/DebitorPageEntry';
import './Contract/ContractPageEntry';
import './Mandate/MandatePageEntry';
import './Rent/RentPageEntry';
import './Asset/AssetPageEntry';
import './Accounting/AccountingPageEntry';
import './Accounting/AccountingNoPropertyPageEntry';
import './AncillaryExpense/AncillaryExpensePageEntry';
import './CustomerDocuments/CustomerDocumentsPageEntry';
import './AutoInvoicing/AutoInvoicingPageEntry';
import './ContractUploadPage/ContractUploadPageEntry';
import './ServiceProvider/ServiceProviderPageEntry';
import './Process/ProcessPageEntry';
import './Subscription/SubscriptionTableEntry';
import './DocumentTemplates/DocumentTemplatesEntry';
import './Damage/Overview/DamageListPageEntry';
import './User/Overview/UserListPageEntry';
import './BankingReconciliation/BankingReconciliationPageEntry';
import './Profile/ProfilePageEntry';
import './Handover/HandoverIntegrationPageEntry';
import './Quickschild/QuickschildIntegrationPageEntry';
import './Dunning/DunningPageEntry';
import './Auth/LoginPageEntry';
import RegistrationPage from './Registration/RegistrationPage';
import RegistrationCompletePage from './Registration/RegistrationCompletePage';
import MortgagePage from './Mortgage/MortgagePage';
import { UseObjectViewModalProvider } from "Components/ObjectViewModal";
registerEntrypoint('MortgagePage', (props) => (_jsx(UseObjectViewModalProvider, { children: _jsx(MortgagePage, { ...props }) })));
registerEntrypoint('RegistrationPageEntry', (props) => (_jsx(RegistrationPage, { ...props })));
registerEntrypoint('CompleteAccountPageEntry', (props) => (_jsx(RegistrationCompletePage, { ...props })));
