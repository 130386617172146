import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FwEditor from '@f2w/ckeditor-build';
import { RichTextMode } from "Components/CKEditor/enum/RichTextMode";
import { getLocale } from "Services/Translator";
import { cssVars } from '@f2w/theme';
import { useEventCallback } from '@restart/hooks';
const CKEditorWrapper = ({ initialValue, placeholder, onChange: _onChange, onBlur: _onBlur, toolBarType, hasError, ...props }) => {
    const EditorType = React.useMemo(() => {
        switch (toolBarType) {
            case RichTextMode.SIMPLE: return FwEditor.SimpleEditor;
            case RichTextMode.BLOCK: return FwEditor.BlockEditor;
            case RichTextMode.BLOCKV2: return FwEditor.BlockEditorV2;
            default: return FwEditor.FullEditor;
        }
    }, [toolBarType]);
    const onChange = useEventCallback((event, editor) => _onChange(editor.getData()));
    const onBlur = useEventCallback((event, editor) => _onBlur(editor.getData()));
    return (_jsx("div", { style: {
            border: hasError ? '1px solid red' : 'none',
            borderRadius: '5px',
            ...cssVars({
                '--ck-color-toolbar-background': '#fefefe'
            })
        }, children: _jsx(CKEditor, { ...props, editor: EditorType, config: {
                language: getLocale()
            }, data: initialValue, placeholder: placeholder, onChange: onChange, onBlur: onBlur }) }));
};
export default CKEditorWrapper;
