import generateRoute from 'Services/Router';
import client from 'Utils/client';
import moment from "moment";
import { translate } from "Services/Translator";
export var BankAccountServices;
(function (BankAccountServices) {
    BankAccountServices.getBankAccounts = async (type) => {
        const route = generateRoute('search_bank_accounts', {
            type: type
        });
        const { data } = await client.get(route);
        return data;
    };
    BankAccountServices.getBankAccountsForDropdown = async () => {
        const route = generateRoute('api.bankAccounts.dropdown');
        const { data } = await client.get(route);
        return data;
    };
    BankAccountServices.listBankAccounts = () => {
        const listBankAccountsRoute = generateRoute('api.bankAccounts.list');
        return client
            .get(listBankAccountsRoute)
            .then(response => response.data);
    };
    BankAccountServices.getBankAccountDetails = (bankAccountId) => {
        const route = generateRoute('api.bankAccounts.details', {
            bankAccount: bankAccountId,
        });
        return client
            .get(route)
            .then(response => response.data);
    };
    BankAccountServices.createBankAccount = (data) => {
        const createBankAccountRoute = generateRoute('api.bankAccounts.create');
        return client
            .post(createBankAccountRoute, data)
            .then(response => response.data);
    };
    BankAccountServices.updateRentBankAccount = (id, data) => {
        const updateBankAccountRoute = generateRoute('api.bankAccounts.updateRent', {
            bankAccount: id,
        });
        return client
            .put(updateBankAccountRoute, data)
            .then(response => response.data);
    };
    BankAccountServices.updatePersonBankAccount = (id, data) => {
        const updateBankAccountRoute = generateRoute('api.bankAccounts.updatePerson', {
            bankAccount: id,
        });
        return client
            .put(updateBankAccountRoute, data)
            .then(response => response.data);
    };
    BankAccountServices.updateCreditBankAccount = (id, data) => {
        const updateBankAccountRoute = generateRoute('api.bankAccounts.updateCredit', {
            bankAccount: id,
        });
        return client
            .put(updateBankAccountRoute, data)
            .then(response => response.data);
    };
    BankAccountServices.updateBankAccountProperties = (id, propertiesRefs) => {
        const route = generateRoute('update_bank_account_properties', {
            bankAccount: id,
        });
        return client
            .put(route, { propertiesRefs })
            .then(response => response.data);
    };
    BankAccountServices.deleteBankAccount = (bankAccountId) => {
        const deleteBankAccountRoute = generateRoute('delete_bank_account', {
            bankAccount: bankAccountId,
        });
        return client.delete(deleteBankAccountRoute);
    };
    BankAccountServices.validateIban = (iban, isQrIban) => {
        const validateIbanRoute = generateRoute('validate_bank_account_iban', {
            iban,
            isQrIban: isQrIban ? 1 : 0,
        });
        return client
            .get(validateIbanRoute)
            .then(r => {
            return '';
        })
            .catch((error) => {
            return error.response?.data?.data ?? error.response.data;
        });
    };
    BankAccountServices.getIncomeAndExpenditure = (bankAccountId, startDate, endDate) => {
        return client.get(generateRoute('api.bankAccounts.incomeAndExpenditure', {
            bankAccountId: bankAccountId,
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
        })).then(response => response.data);
    };
    BankAccountServices.getBalance = (bankAccountId, date) => {
        return client.get(generateRoute('api.bankAccounts.balance', {
            bankAccountId: bankAccountId,
            date: moment(date).format('YYYY-MM-DD')
        }))
            .then(response => response.data);
    };
    BankAccountServices.groupBankAccounts = (bankAccounts) => {
        const mappedBankAccounts = new Map();
        mappedBankAccounts.set('other', {
            label: translate('bankAccounts.group.other'),
            options: []
        });
        for (const bankAccount of bankAccounts) {
            if (bankAccount.primaryProperty) {
                if (mappedBankAccounts.has(bankAccount.primaryProperty.id)) {
                    const propertyGroup = mappedBankAccounts.get(bankAccount.primaryProperty.id);
                    mappedBankAccounts.set(bankAccount.primaryProperty.id, {
                        ...propertyGroup,
                        options: [...propertyGroup.options, {
                                label: bankAccount.name,
                                value: bankAccount.id
                            }]
                    });
                }
                else {
                    mappedBankAccounts.set(bankAccount.primaryProperty.id, {
                        label: bankAccount.primaryProperty.name,
                        options: [{
                                label: bankAccount.name,
                                value: bankAccount.id
                            }]
                    });
                }
            }
            else {
                const otherGroup = mappedBankAccounts.get('other');
                mappedBankAccounts.set('other', {
                    ...otherGroup,
                    options: [...otherGroup.options, {
                            label: bankAccount.name,
                            value: bankAccount.id
                        }]
                });
            }
        }
        return Array.from(mappedBankAccounts.values());
    };
    BankAccountServices.getTenantBankAccountsNotAssignedToPersonApiAction = (personId) => {
        return client
            .get(generateRoute('api.bankAccounts.tenant.notAssignedToPerson', {
            includePerson: personId
        }))
            .then(res => res.data);
    };
})(BankAccountServices || (BankAccountServices = {}));
export default BankAccountServices;
