import { jsx as _jsx } from "react/jsx-runtime";
import BaseController from './BaseController';
import { getGlobalContext } from 'Services/GlobalContext';
import { registerEntrypoint } from '@fw/FairwalterApp';
registerEntrypoint('BaseControllerEntry', {
    resolveProps: (props) => {
        const { patchNote, showAccountLimitsWarningOnPageLoad, limits, usages, } = getGlobalContext();
        return {
            patchNote,
            limits,
            usages,
            showAccountLimitsWarningOnPageLoad,
        };
    },
}, props => _jsx(BaseController, { ...props }));
