import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { FormError } from '@f2w/form';
import { translate } from 'Services/Translator';
import { useViewType } from '@f2w/view';
import { Footer, Section } from 'Atoms/Layout/components';
import { RegistrationType } from '../types/RegistrationType';
import { Button, Text } from 'Components/Atoms';
import generateRoute from 'Services/Router';
import RegistrationManager from '../services/RegistrationManager';
import { RegistrationTemplate } from '../components/RegistrationTemplate';
import { sendRegistrationStep } from 'Services/GtmServices';
import { isAppEnv } from '@f2w/utils';
export const useRegistrationView = ({ manager = RegistrationManager, membershipType, initialValues, salesReference, }) => useViewType(() => RegistrationType.create(membershipType, salesReference), {
    id: 'register',
    defaultState: {
        membershipType
    },
    initialView: 'register',
    enableForms: true,
    closeFormOnSave: false,
    load: async () => ({
        ...initialValues,
        membershipType: null,
    }),
    saveError: (error) => {
        return error?.response?.status === 422 || error?.response?.data?.message
            ? new FormError(error?.response?.data.message)
            : error;
    },
    DefaultTemplate: ({ view, view: { activeGroup: group } }) => {
        const footer = group?.render('ContentFooter');
        useEffect(() => {
            if (group.key === 'register') {
                sendRegistrationStep({
                    path: 'registration-start',
                    title: 'Start',
                    partner: membershipType
                });
            }
            else if (group.key === 'success') {
                sendRegistrationStep({
                    path: 'registration-confirmation',
                    title: 'Confirmation',
                    partner: membershipType
                });
            }
        }, []);
        return _jsxs(RegistrationTemplate, { view: view, children: [group?.render('Content'), view.isEditingEnabled && view.renderForm(RegistrationTemplate.Form), footer && _jsx(Footer, { children: footer })] });
    },
}, () => ({
    register: {
        ContentHeader: ({ view: { type: { templateProps: { title, lead } } } }) => (_jsxs(_Fragment, { children: [title && _jsx(Text, { as: 'h3', size: 'h3', children: title }), lead && _jsx(Text, { align: 'left', as: 'p', size: 'body', children: lead })] })),
        ContentFooter: ({}) => (_jsxs(Text, { dark: 500, children: [translate('account.register.haveAccount'), " ", _jsx("a", { href: generateRoute('app_login'), children: translate('account.toLogin') })] })),
        Form: ({ type }) => {
            return type.renderGroup('register');
        },
        save: async (values, view) => {
            const formData = {
                ...view.type.getGroupData('register'),
            };
            view.updateData(formData);
            if (isAppEnv()) {
                await manager.registerUser(formData);
            }
            view.open('success');
        },
    },
    success: {
        title: ({ type: { templateProps: { success: { title } } } }) => title,
        Content: ({ group: { data, type: { templateProps: { success: { emailSent, requestSent } } } } }) => (_jsxs(_Fragment, { children: [_jsxs(Text, { large: true, as: Section, children: [_jsxs("p", { children: [emailSent, "."] }), _jsx("p", { children: _jsx("strong", { children: data.email }) }), _jsx("p", { children: requestSent }), _jsx("p", { children: translate('registration.success.help') }), _jsx("p", { dangerouslySetInnerHTML: { __html: translate('registration.success.contactUs') } })] }), _jsx(Footer, { children: _jsx(Button, { label: translate('account.toHomepage'), "$size": "lg", block: true, href: generateRoute('app_login') }) })] })),
    },
}));
