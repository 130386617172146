import client from "Utils/client";
import generateRoute from "Services/Router";
const listPersonsForDropdown = (withContractedRentalObjects = false) => {
    const listPersonsForDropdownRoute = generateRoute("people_dropdown", {
        withContractedRentalObjects: withContractedRentalObjects ? 1 : 0
    });
    return client
        .get(listPersonsForDropdownRoute)
        .then(response => response.data);
};
export default {
    listPersonsForDropdown
};
