import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import styled, { css } from "styled-components";
import { useTheme } from "@f2w/theme";
import { Input } from "Components/Atoms/Input";
const AuthCodeInputWrapper = styled.div(() => {
    const { typography } = useTheme();
    return css `
        display: inline-flex;
        gap: 10px;
        input {
            font-size: ${typography.large.fontSize} !important;
            width: 100%;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
        }
    `;
});
const onSingleInputKeyDown = (e) => {
    if (e.key === "Backspace") {
        e.preventDefault();
        if (e.target.value === "") {
            const prevSibling = e.target.closest('[role="textbox"]').previousSibling;
            if (prevSibling) {
                const input = prevSibling.querySelector('input');
                input.focus();
                input.select();
            }
            return false;
        }
        else {
            e.target.value = "";
            return true;
        }
    }
    if (e.ctrlKey && ["v", "V"].includes(e.key)) {
        return false;
    }
    if (e.key.length === 1 && !(e.key + "").match(/^\d$/)) {
        e.preventDefault();
        return false;
    }
    return false;
};
const onSingleInputChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    value = value.substring(value.length - 1);
    e.target.value = value;
    const nextSibling = e.target.closest('[role="textbox"]').nextSibling;
    if (nextSibling) {
        const input = nextSibling.querySelector('input');
        input.focus();
    }
};
const parsePaste = (e) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData('Text');
    if (pasteText.length === 0) {
        return undefined;
    }
    const parsedText = pasteText.replace(/\s/g, '');
    if (!/^\d+$/.test(parsedText)) {
        return undefined;
    }
    let enteredText = "";
    let currentInput = e.target;
    for (const digit of parsedText.split('')) {
        const nextInput = currentInput.closest('[role="textbox"]').nextSibling?.querySelector('input');
        currentInput.value = digit;
        enteredText += digit;
        if (!nextInput) {
            break;
        }
        currentInput = nextInput;
    }
    currentInput.focus();
    return enteredText;
};
const AuthCodeSingleInput = ({ autoFocus, defaultValue, onChange, onPasteSuccess }) => {
    return _jsx(Input, { tabIndex: 0, onPaste: (e) => {
            const pastedValue = parsePaste(e);
            if (pastedValue) {
                onPasteSuccess();
            }
        }, autoFocus: autoFocus, type: "number", inputMode: "numeric", pattern: "[0-9]{1}", min: 0, max: 9, maxLength: 1, autoComplete: "off", "data-lpignore": "true", "data-form-type": "other", defaultValue: defaultValue, onChange: (v, e) => {
            onSingleInputChange(e);
            onChange(e);
        }, onKeyDown: (e) => {
            const changed = onSingleInputKeyDown(e);
            if (changed) {
                onChange(e);
            }
        }, "$size": "sm", style: {
            paddingLeft: 0,
            paddingRight: 0,
            height: 50,
            width: 50,
            textAlign: "center",
        } });
};
const getArrayValueFromString = (value, length) => {
    let newValue = value.substring(0, length);
    const extra = length - newValue.length;
    if (extra > 0) {
        newValue += " ".repeat(extra);
    }
    return newValue.split("");
};
const AuthCodeInput = ({ autoFocus = false, defaultValue = "", length = 6, onChange = undefined }) => {
    const ref = useRef();
    const valueArray = getArrayValueFromString(defaultValue, length);
    const getValue = () => {
        const wrapper = ref.current;
        if (!wrapper) {
            return;
        }
        let value = "";
        const inputs = wrapper.querySelectorAll('input');
        for (const input of inputs) {
            value += (input.value !== "" ? input.value : " ");
        }
        return value;
    };
    return (_jsx(AuthCodeInputWrapper, { ref: ref, children: valueArray.map((value, index) => (_jsx(AuthCodeSingleInput, { autoFocus: autoFocus && index === 0, defaultValue: value, onPasteSuccess: () => {
                const value = getValue();
                onChange(value);
            }, onChange: () => {
                const value = getValue();
                onChange(value);
            } }, index))) }));
};
export default AuthCodeInput;
