import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ModalNew } from 'Components/Modal';
import { Button, Icon, SpinnerNew } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { isObject, isString } from 'Utils/types';
import { Input } from 'Components/Atoms/Input';
import { useModalDispatcher } from './useModalDispatcher';
import useForceUpdate from '@restart/hooks/useForceUpdate';
export const ModalDispatcher = forwardRef((props, ref) => {
    const modalApi = ref.current = useModalDispatcher(props);
    const { isActive } = modalApi;
    return (_jsx(ModalNew, { show: isActive, size: { width: 'auto', minWidth: 320, maxWidth: 'calc(100% - 80px)' }, position: "CENTER", autoFocus: true, onHide: () => {
            modalApi.options?.onCancel && modalApi.options?.onCancel();
        }, backdrop: true, zIndex: 1000011000, children: modalApi.options.doubleConfirm
            ? _jsx(DoubleConfirmTemplate, { ...modalApi })
            : _jsx(ConfirmTemplate, { ...modalApi }) }));
});
const ConfirmMessage = styled.p `
  margin-bottom: var(--body-spacing);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 4px;

  span {
    ${p => p.theme.typography.medium.fontSizeStyles};
    color: ${p => p.theme.palette.dark.$700};
    background-color: ${p => p.theme.palette.dark.$100}aa;
    font-weight: 400;
    padding: 1px 5px;
    border-radius: 4px;
  }
`;
const BasicConfirmRoot = styled.div `
  --spacing-y: 24px;
  --body-padding: 16px;

  width: 380px;
  max-width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  color: ${p => p.theme.palette.dark.$800};
  ${p => p.theme.typography.medium.fontStyles};

  ._title {
    color: inherit;
    display: block;
    ${p => p.theme.typography.body.fontSizeStyles};
    font-weight: 500;
    margin: 10px 0;
  }

  small {
    color: ${p => p.theme.palette.dark.$400};
    ${p => p.theme.typography.small.fontStyles};
  }

  b {
    font-weight: 600
  }

  p {
    margin: 8px 0;
  }

  header, footer, section {
    padding: 0 var(--body-padding);
    margin: var(--spacing-y) 0;
  }

  header {
    --spacing-y: 36px;
  }

  section {
    --body-padding: 32px;
  }
`;
const SeverityConfirmRoot = styled(BasicConfirmRoot) `
  text-align: left;
  width: 620px;

  header {
    --spacing-y: 24px;
  }

  section {
    --body-padding: 16px;

    &[data-loading] {
      --spacing-y: 36px;
    }
  }

  footer {
    text-align: right;
  }

  ${ConfirmMessage} {
    align-items: flex-start;
    text-align: left;
  }

  section[data-severity] {
    display: flex;
    align-items: flex-start;
    padding: var(--body-padding);
    margin: var(--spacing-y) var(--body-padding);
    gap: var(--body-padding);
    border-radius: 2px;
    border: 1px solid #00000022;

    ._severity--icon {
      flex-grow: 0;
      color: var(--icon-color, #000000aa);
      margin-top: -1px;
      font-size: 20px;
    }

    ._severity--content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      color: #000000af;

      > * {
        margin: .75em 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  section[data-severity="1"] {
    --icon-color: ${p => p.theme.palette.dark.$900};
    background-color: ${p => p.theme.palette.dark.$50};
  }

  section[data-severity="2"] {
    --icon-color: ${p => p.theme.palette.warning.$900};
    background-color: ${p => p.theme.palette.warning.$50};
  }

  section[data-severity="3"] {
    --icon-color: ${p => p.theme.palette.error.$900};
    background-color: ${p => p.theme.palette.error.$50};
  }

  section[data-severity="4"] {
    --icon-color: ${p => p.theme.palette.error.$900};
    background-color: ${p => p.theme.palette.error.$50};
  }
`;
function renderButton(options, props) {
    return isObject(options) ? _jsx(Button, { ...props, ...options }) : _jsx(Button, { ...props, label: options });
}
function renderMessage(message, help) {
    return _jsxs(_Fragment, { children: [isString(message) ? _jsx("p", { children: message }) : message, isString(help) ? _jsx("p", { children: _jsx("small", { children: help }) }) : help] });
}
const ConfirmTemplate = ({ options: { Body, content, customSection, message, help, icon = 'alert-circle', severity, onConfirm, onCancel, loading, btnCancel = translate('Cancel'), btnConfirm = translate('Continue'), }, disableButton, children, }) => {
    return severity > 0 ? (_jsxs(SeverityConfirmRoot, { children: [message && _jsx("header", { children: isString(message) ? _jsx("h3", { className: "_title", children: message }) : message }), loading ? (_jsx("section", { "data-loading": true, children: _jsx(SpinnerNew, { alignItems: 'center' }) })) : (_jsxs("section", { "data-severity": severity, children: [icon && _jsx("div", { className: "_severity--icon", children: isString(icon) ? _jsx(Icon, { icon: icon }) : icon }), _jsx("div", { className: "_severity--content", children: Body ? _jsx(Body, {}) : _jsxs(_Fragment, { children: [isString(content) ? _jsx("p", { children: content }) : content, isString(help) ? _jsx("p", { children: _jsx("small", { children: help }) }) : help] }) })] })), isString(customSection) ? _jsx("section", { children: customSection }) : customSection, children && _jsx("section", { children: children }), _jsxs("footer", { children: [onCancel && renderButton(btnCancel, {
                        color: 'dark',
                        variant: 'link',
                        onClick: onCancel,
                    }), onConfirm && renderButton(btnConfirm, {
                        color: ['primary', 'warning', 'error', 'error'][severity - 1],
                        variant: 'fill',
                        onClick: onConfirm,
                        disabled: disableButton,
                        tabIndex: 0
                    })] })] })) : (_jsxs(BasicConfirmRoot, { children: [loading ? (_jsx("header", { children: _jsx(SpinnerNew, { alignItems: 'center' }) })) : (icon && _jsx("header", { children: isString(icon) ? _jsx(Icon, { icon: icon, size: 58 }) : icon })), (message || help) && _jsxs("section", { children: [isString(message) ? _jsx("p", { className: "_title", children: message }) : message, isString(help) ? _jsx("p", { children: _jsx("small", { children: help }) }) : help] }), isString(customSection) ? _jsx("section", { children: customSection }) : customSection, children && _jsx("section", { children: children }), _jsxs("footer", { children: [onCancel && renderButton(btnCancel, {
                        color: 'dark',
                        variant: 'link',
                        onClick: onCancel,
                    }), onConfirm && renderButton(btnConfirm, {
                        color: 'error',
                        variant: 'fill',
                        onClick: onConfirm,
                        disabled: disableButton,
                        tabIndex: 0
                    })] })] }));
};
const DoubleConfirmTemplate = ({ options: { doubleConfirm, onConfirm: initialOnConfirm, ..._options }, ...props }) => {
    const dispatchUpdate = useForceUpdate();
    const inputRef = useRef();
    const keyword = useMemo(() => {
        const keywords = (isString(doubleConfirm) ? doubleConfirm : 'DELETE').split(',').map(v => v.trim());
        return {
            label: keywords[0],
            value: keywords.map(v => v.toLowerCase())
        };
    }, []);
    const isValid = useCallback(() => {
        const inputEl = inputRef.current;
        return inputEl?.value && keyword.value.includes(inputEl?.value?.toLowerCase?.());
    }, [inputRef.current?.value]);
    const onDoubleConfirm = useCallback((...args) => {
        if (isValid()) {
            initialOnConfirm && initialOnConfirm(...args);
        }
    }, [isValid]);
    const options = {
        ..._options,
        onConfirm: onDoubleConfirm,
    };
    return (_jsxs(ConfirmTemplate, { ...props, disableButton: !isValid(), options: options, children: [_jsx(ConfirmMessage, { dangerouslySetInnerHTML: { __html: translate('modalDispatcher.doubleConfirm', { keyword: keyword.label }) } }), _jsx(Input, { ref: inputRef, onChange: () => dispatchUpdate() })] }));
};
