import moment from 'moment';
export function DateManager({ minDate: _min, maxDate: _max, }) {
    let min = DateManager.parseMoment(_min, DateManager.MIN_DATE);
    let max = DateManager.parseMoment(_max, DateManager.MAX_DATE);
    if (min.isAfter(max)) {
        console.warn('Min date (%d) should not be after max date (%d)!', min.format(DateManager.FORMAT), max.format(DateManager.FORMAT));
        max = min;
    }
    const cast = (value) => {
        const date = DateManager.parseMoment(value);
        if (date) {
            if (date.isBefore(min)) {
                return min;
            }
            if (date.isAfter(max)) {
                return max;
            }
            return date;
        }
    };
    const castDate = (value) => {
        return cast(value)?.toDate();
    };
    return {
        min,
        max,
        cast,
        minDate: min.toDate(),
        maxDate: max.toDate(),
        castDate,
    };
}
(function (DateManager) {
    DateManager.FORMAT = 'YYYY-MM-DD';
    DateManager.MIN_DATE = '1900-01-01';
    DateManager.MAX_DATE = '2099-12-31';
    DateManager.parseDate = (value, defaultValue) => {
        return DateManager.parseMoment(value, defaultValue)?.toDate?.();
    };
    DateManager.parseMoment = (value, defaultValue) => {
        if (value && (value = moment(value)) && value.isValid())
            return value;
        if (defaultValue)
            return DateManager.parseMoment(defaultValue);
    };
})(DateManager || (DateManager = {}));
