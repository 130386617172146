import styled, { css } from 'styled-components';
import { useLayoutProps, useStickyCss } from '../hooks';
export const DividerBase = styled('hr') `
  display: block;
  height: 0;
  left: 0;
  position: sticky;
  border-bottom: ${p => p.size ?? 8}px solid var(--divider-color, #00000022);
  ${p => p.withPadding ? {
    width: 'auto',
    margin: `${p.spacing ?? 0} var(--fw-padding-x, 24px)`,
} : {
    width: '100%',
    margin: `${p.spacing ?? 0} 0`,
}}
`;
export const BlockBase = styled('div').attrs(p => useLayoutProps(p))(p => ({
    ...useStickyCss(p.sticky)
}));
export const SectionBase = styled('div').attrs((props) => ({
    ...useLayoutProps(props)
}))((p) => {
    const sticky = useStickyCss(p.sticky);
    return css `
        ${p => p.spacing ? `--fw-padding-y: ${p.spacing}px;` : ``};
        max-width: var(--fw-max-width, none);
        min-width: ${p.fitContent ? 'fit-content' : 'auto'};
        width: 100%;
        margin: var(--fw-margin-y, 0) var(--fw-margin-x, auto);
        padding: var(--fw-padding-y, 0) var(--fw-padding-x, 0);
        flex-grow: ${p.auto ? 1 : 0};
        background-color: var(--background-color, white);
        text-align: ${p.align ?? 'left'};
        ${sticky};

        > ${DividerBase} {
            width: calc(100% + (var(--fw-padding-x, 0) * 2));
            margin-left: calc(var(--fw-padding-x, 0) * -1);
            margin-right: calc(var(--fw-padding-x, 0) * -1);
        }

        &:first-child {
            padding-top: calc(2 * var(--fw-padding-y));
        }

        &:last-child {
            padding-bottom: calc(2 * var(--fw-padding-y));
        }
    `;
});
