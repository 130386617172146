import { jsx as _jsx } from "react/jsx-runtime";
import ReactDom from 'react-dom';
import { parseJsonObject } from 'Utils/parseJson';
import { FairwalterApp } from './FairwalterApp';
const parseEntryProps = (el) => {
    return parseJsonObject(el?.dataset?.props);
};
export async function registerEntrypoint(id, ...args) {
    const el = document.getElementById(id);
    if (el) {
        const Component = args.pop();
        const { resolveProps, ...props } = { ...args.shift() };
        const entryProps = resolveProps ? resolveProps(parseEntryProps(el)) : parseEntryProps(el);
        ReactDom.render((_jsx(FairwalterApp, { ...props, children: _jsx(Component, { ...entryProps }) })), el);
    }
}
