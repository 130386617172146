import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Table } from '@f2w/data-table/table-types';
import { translate } from 'Services/Translator';
import { Button } from 'Atoms/Button';
import { Icon } from 'Atoms/Icon';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { CopyIcon, DeleteIcon } from "Components/App/Icons";
import { Div, FlexRow } from "Atoms/Layout";
import moment from "moment/moment";
import { hasFeature } from "Services/FeatureFlagManager";
import { Dropdown } from "Atoms/Dropdown";
const types = ['document'];
const BaseTable = Table.factory()(() => ({
    tableId: 'DocumentTemplates',
    Actions: ({ openSettingsForm, createNewDocument }) => (_jsxs(FlexRow, { gap: 12, children: [hasFeature('TEMPLATES_ADD') && _jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { children: translate('btn.addDocument') }), _jsx(Dropdown.Menu, { children: types.map((type) => _jsx(Dropdown.Item, { onClick: () => createNewDocument(type), children: translate('document.v2.type.' + type, {}, 'documents') })) })] }), _jsxs(Button, { variant: "outline", color: "dark", onClick: openSettingsForm, title: translate('documentTemplates.settings'), children: [_jsx(Icon, { icon: 'settings' }), " ", translate('documentTemplates.settings')] })] })),
    manualControl: true,
    useSortBy: {},
    useGlobalFilter: {},
    usePagination: {},
    useRowState: {},
    initialState: {
        pageSize: 25,
    },
    columns: {
        $actions: {
            sticky: 'left',
        },
        label: {
            Header: translate('documentTemplate.list.document'),
            useSortBy: {},
        },
        areas: {
            Header: translate('documentTemplate.list.areas'),
            Cell: ({ value: areas }) => areas.map((area) => translate('document_area.title.' + area)).join(', '),
        },
        mandate: {
            Header: translate('documentTemplate.list.mandate'),
            Cell: ({ value: mandate }) => mandate?.name || '—',
            useSortBy: {},
        },
        lastUpdateAt: {
            Header: translate('documentTemplate.list.lastUpdate'),
            Cell: ({ value }) => {
                return _jsx(Div, { style: { whiteSpace: 'nowrap' }, children: moment(value).format('DD.MM.YYYY HH:mm') });
            },
            useSortBy: {},
        },
    },
    RowActions: ({ row: { original: { id, instanceKey, mandate, isDeletable } }, openDocumentView, openCloneModal, onDelete }) => {
        return (_jsx(ActionsDropdown, { id: id, scrollable: true, quickActions: [
                {
                    icon: 'eye',
                    title: translate('creditor.view'),
                    onClick: () => openDocumentView(instanceKey, mandate?.id)
                },
            ], dropdownActions: ({ Link }) => (_jsxs(_Fragment, { children: [_jsxs(Link, { onClick: () => openCloneModal(id, instanceKey), variant: 'outline', title: translate('creditor.actions.export'), children: [_jsx(CopyIcon, {}), " ", translate('btn.copy')] }), isDeletable && _jsxs(Link, { onClick: () => onDelete(id), variant: 'outline', title: translate('creditor.actions.export'), children: [_jsx(DeleteIcon, { size: 16 }), " ", translate('btn.delete')] })] })) }));
    },
}));
export const DocumentTemplateDataTable = Object.assign(BaseTable, {
    use({ documentTemplateServices, openSettingsForm, createNewDocument, openDocumentView, openCloneModal, onDelete }) {
        return DocumentTemplateDataTable.useTable({ openSettingsForm, createNewDocument, openDocumentView, openCloneModal, onDelete }, () => ({
            getData: ({ request }) => documentTemplateServices.listDocumentTemplates({ ...request }),
        }));
    }
});
