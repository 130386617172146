import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { frc } from 'Components/Base/helpers';
import classNames from 'classnames';
import { useTableTheme } from '../theme';
import { cssVars } from '@f2w/theme';
import { ActionsDropdown } from '@f2w/data-table/components/ActionsDropdown';
import { OverlayTrigger, Tooltip } from 'Components/Atoms/Overlay';
import { isObject, isString } from 'Utils';
export const TdRoot = styled.td ``;
export const ThRoot = styled.th ``;
export var TableCellType;
(function (TableCellType) {
    TableCellType["HEAD"] = "head";
    TableCellType["DATA"] = "data";
    TableCellType["ACTIONS"] = "actions";
    TableCellType["CONTENT"] = "content";
    TableCellType["EMPTY"] = "empty";
    TableCellType["SPACER"] = "spacer";
})(TableCellType || (TableCellType = {}));
export const TableCell = forwardRef(({ type: _type, header, actions, dropdownActions, align, valign, sticky, noWrap, min, auto, className, tooltip, children, ...props }, ref) => {
    const theme = useTableTheme();
    const { Css, colSpan } = theme;
    const Component = header ? ThRoot : TdRoot;
    if (actions) {
        _type = TableCellType.ACTIONS;
        if (Array.isArray(actions)) {
            children = _jsx(ActionsDropdown, { children: dropdownActions ?? children, quickActions: actions });
        }
        else if (isObject(actions)) {
            children = _jsx(ActionsDropdown, { children: dropdownActions, ...actions });
        }
        else if (Array.isArray(children)) {
            children = _jsx(ActionsDropdown, { children: dropdownActions, quickActions: children });
        }
    }
    else if (!_type) {
        _type = header ? TableCellType.HEAD : TableCellType.EMPTY;
    }
    if (props.colSpan === true) {
        props.colSpan = colSpan;
    }
    if (_type === TableCellType.ACTIONS) {
        children = _jsx("div", { children: children });
    }
    props.style = {
        ...cssVars({ align, valign }, 'cell-'),
        ...props.style,
    };
    let cell = _jsx(Component, { "data-cell": _type, "data-sticky": sticky, ...props, ref: ref, className: classNames(Css.cell, {
            [Css.cell__min]: min,
            [Css.cell__auto]: auto,
            [Css.cell__noWrap]: noWrap,
        }, className), children: children });
    if (tooltip) {
        return _jsx(OverlayTrigger, { placement: 'bottom-start', flip: true, rootClose: true, overlay: isString(tooltip) ? _jsx(Tooltip, { id: "overlay-example", ...props, children: tooltip }) : tooltip, children: cell });
    }
    return cell;
});
TableCell.displayName = 'TableCell';
export const Td = Object.assign(frc(`${TableCell.displayName}Data`, (props, ref) => _jsx(TableCell, { type: TableCellType.DATA, ...props, ref: ref })), {
    Root: TdRoot
});
export const Th = frc(`${TableCell.displayName}Header`, (props, ref) => _jsx(TableCell, { ...props, header: true, ref: ref }));
const resolveActionsProps = ({ id, style, offset, alignRight, buttonProps, drop, quickActions, children, ...props }) => ({
    ...props,
    actions: { id, style, offset, alignRight, buttonProps, drop, quickActions, children },
});
export const TdActions = frc(`${TableCell.displayName}DataActions`, (props, ref) => (_jsx(TableCell, { ...resolveActionsProps(props), ref: ref })));
export const ThActions = frc(`${TableCell.displayName}HeaderActions`, (props, ref) => (_jsx(TableCell, { ...resolveActionsProps(props), header: true, ref: ref })));
