import { isObject } from 'Utils';
import { defaultTheme } from '@f2w/theme';
export function tokenFromProps(tokens, props) {
    return Object.keys(tokens).find(name => !!props[name]);
}
export function tokenGetStyles(tokens, variant, defaultValue = undefined) {
    if (isObject(variant)) {
        variant = tokenFromProps(tokens, variant) || variant['variant'];
    }
    return tokens?.[variant] || tokens?.[defaultValue];
}
export function tokenBuilder() {
    return function createTokens(_tokens) {
        const _keys = Object.keys(_tokens);
        const _defaultKey = _keys.find(key => _tokens[key]?.isDefault) || _keys[0];
        const resolveKey = (key) => (key === true ? _defaultKey : _tokens[key] ? key : (isObject(key) && _keys.find(k => !!key[k])));
        const getToken = (...args) => {
            const name = resolveKey(args.shift());
            const token = _tokens[name] || (args.length && getToken(...args));
            if (!token)
                return null;
            const { styles, ..._baseProps } = token;
            return {
                ..._baseProps,
                $variant: name,
                styles,
                use: (opts) => {
                    const props = styles && styles(defaultTheme, opts);
                    return {
                        ..._baseProps,
                        ...props,
                    };
                },
            };
        };
        const getVars = (key, props) => {
            const { theme, defaultKey = true, ...options } = props ?? Object.create(null);
            const token = api.get(key, defaultKey);
            return token?.styles(theme ?? defaultTheme, options);
        };
        const api = {
            get tokens() {
                return _tokens;
            },
            get keys() {
                return _keys;
            },
            get: (key, fallback) => {
                return getToken(key, fallback);
            },
            getVariant: (...args) => {
                return getToken(...args);
            },
            map: (fn) => {
                return _keys.map(key => fn(key, _tokens[key]));
            },
            getStyles: (key, props) => {
                const { fonts, ...vars } = getVars(key, props);
                return { ...fonts, ...vars };
            },
            extend: (tokens) => {
                const newTokens = {};
                Object.keys(tokens).forEach(key => {
                    const prevStyles = _tokens[key]?.styles;
                    const newStyles = tokens[key]?.styles;
                    newTokens[key] = {
                        ..._tokens[key],
                        ...tokens[key],
                        styles: (theme, ...args) => {
                            return newStyles(prevStyles ? prevStyles(theme, ...args) : {}, theme, ...args);
                        }
                    };
                });
                return createTokens(newTokens);
            },
        };
        return api;
    };
}
