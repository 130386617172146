import { jsx as _jsx } from "react/jsx-runtime";
import client from 'Utils/client';
import { useCallback, useEffect, useState } from 'react';
import { FlexRow } from '../Layout';
import { translate } from 'Services/Translator';
export const MediaPreview = ({ ...media }) => {
    const [fileType, setFileType] = useState();
    const updateFileType = useCallback((type) => {
        if (fileType !== (type = getFileType(type))) {
            setFileType(type);
        }
    }, [fileType]);
    useEffect(() => {
        if (media.type) {
            updateFileType(media.type);
        }
        else if (media.path) {
            client
                .get(media.path, { responseType: 'blob' })
                .then(response => response.data)
                .then(file => updateFileType(file.type))
                .catch(e => {
                console.error('Can not read file', e);
                updateFileType(null);
            });
        }
        else {
            updateFileType(null);
        }
    }, [media.path, media.type]);
    if (fileType === 'embed')
        return _jsx("embed", { className: "document-viewer", src: media.path, width: "100%", style: { minHeight: '100%', objectFit: 'contain' } });
    if (fileType === 'image')
        return _jsx("img", { style: { maxWidth: '100%', height: 'auto', maxHeight: '100%' }, alt: media.name, src: media.path });
    if (media.name)
        return _jsx(FlexRow, { style: { height: '100%' }, alignCenter: true, justifyCenter: true, children: media.name });
    return _jsx(FlexRow, { style: { height: '100%' }, alignCenter: true, justifyCenter: true, children: translate('noDocument') });
};
const getFileType = (type) => {
    if (type) {
        if (type.startsWith('application/') || type === 'embed')
            return 'embed';
        if (type.startsWith('image/') || type === 'image')
            return 'image';
    }
    return null;
};
