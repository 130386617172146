import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { CreditorState } from "Pages/Creditor/enums";
import { translate } from "Services/Translator";
import { Badge, SpinnerNew } from "Components/Atoms";
export const CreditorInvoiceState = ({ state }) => {
    const index = Object.values(CreditorState).indexOf(state);
    const key = Object.keys(CreditorState)[index];
    const label = React.useMemo(() => translate(`creditor.state.${key?.toLowerCase()}`), [state]);
    const variant = React.useMemo(() => {
        switch (state) {
            case CreditorState.QR_PENDING:
            case CreditorState.PARTIALLY_PAID: {
                return 'primary';
            }
            case CreditorState.OVERPAID: {
                return 'warning';
            }
            case CreditorState.QR_COMPLETED: {
                return 'successOutline';
            }
            case CreditorState.PAID: {
                return 'success';
            }
            case CreditorState.INFO_PENDING: {
                return 'danger';
            }
            case CreditorState.INFO_CONFIRMED:
            case CreditorState.INVOICE_ASSIGNED: {
                return 'secondary';
            }
            case CreditorState.READY_FOR_PAYMENT:
            case CreditorState.BANK_FILE_CREATED: {
                return 'secondary';
            }
        }
    }, [state]);
    return _jsxs(Badge, { "$color": variant, children: [label, " ", state === CreditorState.QR_PENDING && _jsx(SpinnerNew, {})] });
};
export const BankFileCreditorInvoiceState = ({ state }) => {
    const index = Object.values(CreditorState).indexOf(state);
    const key = Object.keys(CreditorState)[index];
    let label = React.useMemo(() => translate(`creditor.state.${key?.toLowerCase()}`), [state]);
    const variant = React.useMemo(() => {
        switch (state) {
            case CreditorState.PARTIALLY_PAID: {
                return 'primary';
            }
            case CreditorState.OVERPAID: {
                return 'warning';
            }
            case CreditorState.PAID: {
                return 'success';
            }
            case CreditorState.BANK_FILE_CREATED: {
                label = translate('open');
                return 'secondary';
            }
        }
    }, [state]);
    return _jsx(Badge, { "$color": variant, children: label });
};
