import styled, { css } from 'styled-components';
import { UseStyled } from '@fw/theme';
import { modalSlot } from 'Components/Modal/Modal.theme';
const ContainerBase = UseStyled.styledWithAttrs('div', (props) => ({
    'data-slot': 'container',
}), ({ theme, ...p }) => {
    const styles = UseStyled.css({
        '--background-color': (p.bg && theme.palette.parse(p.bg)) || (p.gray && theme.palette.dark.$25) || undefined,
        maxWidth: p.maxWidth ?? 'none',
    }, p.flex ? { display: 'flex', flexDirection: 'column' } : { display: 'block' });
    return css `
            ${styles};
            flex: 1 1 100%;
            overflow: ${p.scroll ? 'auto' : 'visible'};
            background-color: var(--background-color, transparent);
        `;
});
export const Container = Object.assign(ContainerBase, {
    Base: ContainerBase,
});
const BodyRoot = styled.div.attrs(({ scroll }) => ({
    'data-slot': 'body',
    'data-scroll': scroll ? '' : undefined,
})) `
  --background-color: ${p => p.theme.palette.white};
  --divider-color: ${p => p.theme.palette.dark.$50};
  --border-color: ${p => p.theme.palette.dark.$100};
  --fw-padding-y: ${p => p.paddingY ?? '13px'};
  --fw-padding-x: ${p => p.paddingX ?? '5%'};

  display: flex;
  padding: 0;
  background: var(--background-color, transparent);
  ${p => p.theme.typography.body.fontStyles};

  ${p => p.split ? css `
    flex-direction: row;

    & > ${ContainerBase} {
      overflow: auto;

      & + ${ContainerBase} {
        border-left: 1px solid var(--border-color, transparent);
      }
    }

  ` : css `
    flex-direction: column;

    & > ${ContainerBase} + ${ContainerBase} {
      border-top: 1px solid var(--border-color, transparent);
    }
  `};

  ${modalSlot.css('root')} & {
    flex: 1 1 auto;
    overflow: auto;
  }
`;
export const Body = Object.assign(BodyRoot, {
    Base: BodyRoot,
});
