import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { withDataTable } from '@f2w/data-table/DataTableProvider';
import { Col, Div, FlexCol, FlexRow, Row } from 'Components/Atoms';
import styled from 'styled-components';
function canFilterDebug(column) {
    const { id, isVisible, filterValue, filter, disableGlobalFilter, disableFilters, defaultCanFilter, canFilter, Filter, } = column;
    const red = '#ff1c1c';
    const green = '#19e619';
    const padLength = 12;
    console.log(`%c${id.padEnd(padLength)}`
        + '%cFilters '
        + '%cGlobalFilter '
        + '%ccanFilter ', 'color: ' + '#14cccc', `color: ${disableFilters ? red : green}`, `color: ${disableGlobalFilter ? red : green}`, `color: ${canFilter ? green : red}`, { filter, filterValue, Filter });
    return !column.disableFilters && column.canFilter;
}
function canFilter(column) {
    return !column.disableFilters && column.canFilter;
}
export const FilterInput = styled.div `
    label {
      ${p => p.theme.typography.small.css({ fontWeight: 500 })};
      margin: 0;
      flex-shrink: 1;
      word-break: keep-all;
    }
`;
const DataTableFilter = withDataTable((props) => {
    const { inline, instance: { allColumns, disableFilters, columns, hasPlugin, FiltersLayout, } = {}, instance, } = props;
    const filterableColumns = useMemo(() => {
        const data = {};
        for (const col of columns) {
            if (canFilter(col)) {
                data[col.id] = col;
            }
        }
        return data;
    }, [columns]);
    const renderFilter = useCallback((columnId, extraProps) => {
        const props = {
            key: `table-filter-${columnId}`,
            as: FilterInput,
            ...extraProps
        };
        const inputGroup = (_jsxs(Div, { children: [_jsx("label", { children: filterableColumns[columnId]?.Header }), _jsx("div", { style: { flex: '1' }, className: 'fw-dt-filter-value', children: filterableColumns[columnId]?.render?.('Filter') })] }));
        return filterableColumns[columnId] && inline
            ? _jsx(FlexRow, { inline: true, alignItems: 'end', gap: 5, ...props, children: inputGroup })
            : _jsx(FlexCol, { gap: 5, ...props, children: inputGroup });
    }, [filterableColumns, inline]);
    const columnProps = useMemo(() => {
        const columnsLength = Object.keys(filterableColumns).length;
        if (columnsLength === 2)
            return { md: 3 };
        if (columnsLength > 2)
            return { sm: 6, md: 4, lg: 3 };
        return { sm: 4 };
    }, [filterableColumns]);
    if (disableFilters || !hasPlugin('useFilters')) {
        return null;
    }
    return FiltersLayout
        ? _jsx(FiltersLayout, { ...{ renderFilter, instance } })
        :
            inline
                ? Object.keys(filterableColumns).map((id) => renderFilter(id))
                : _jsx(Row, { style: { flexGrow: 1 }, children: Object.keys(filterableColumns).map((columnId) => (_jsx(Col, { ...columnProps, children: renderFilter(columnId) }, columnId))) });
});
export default DataTableFilter;
