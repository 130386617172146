import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { registerEntrypoint } from '@fw/FairwalterApp';
import NotificationDropdown from '../components_new/Notifications/NotificationDropdown';
import NotificationManager from 'Components/Notifications/NotificationManager';
import NotificationEvents from 'Components/Notifications/NotificationEvents';
import LaunchpadController from 'Pages/Launchpad/LaunchpadPage';
import UserBarPage from 'Pages/User/UserBar/UserBarPage';
import NavigationPage from 'Pages/Navigation/NavigationPage';
registerEntrypoint('LaunchpadPageEntry', { noWrap: true }, (props) => (_jsx(LaunchpadController, {})));
registerEntrypoint('NotificationEntry', {
    noWrap: true,
    style: { display: 'flex', alignItems: 'center', flexDirection: 'row' },
}, (props) => (_jsxs(_Fragment, { children: [_jsx(NotificationDropdown, { getData: p => NotificationManager.list({ limit: 10, ...p }), updateMarkAsRead: NotificationManager.markAsRead }), _jsx(NotificationEvents, {})] })));
registerEntrypoint('UserBarPageEntry', { noWrap: true }, (props) => (_jsx(UserBarPage, { ...props })));
registerEntrypoint('NavigationPageEntry', { noWrap: true }, (props) => {
    return (_jsx(NavigationPage, {}));
});
