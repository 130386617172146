import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'Services/Translator';
import generateRoute from 'Services/Router';
import client from 'Utils/client';
import RentTableModel from './RentTableModel';
import PageOverview from '@f2w/data-table/layout/PageOverview';
import { ActiveView, useObjectViewModalContext } from 'Components/ObjectViewModal';
import { generateDocument } from 'Services/DocumentGenerator';
import { getScopeFilterLevel, getScopeFilters, getScopePropertyId } from 'Services/ScopeManager';
import { FormModal } from '@f2w/form';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { RentPotentialModal } from 'Pages/Contract/components/RentPotentialModal';
import ContractServices from 'Pages/Contract/services/contractServices';
import { RentalObjectFilter } from 'Pages/Rent/components/RentalObjectFilter';
import { BuildingFilter } from 'Pages/Rent/components/BuildingFilter';
import { DateFilter } from 'Pages/Rent/components/DateFilter';
import { useRenterMirrorDateExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/useRenterMirrorDateExportForm';
import { useRenterMirrorDateRangeExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateRangeExportForm/useRenterMirrorDateRangeExportForm';
import { useRenterMirrorTenantListExportForm, } from 'Pages/Rent/forms/RenterMirror/RenterMirrorDateExportForm/useRenterMirrorTenantListExportForm';
import { Dropdown, DropdownButton } from 'Components/Atoms';
import { ExcelIcon2, PdfIcon2 } from 'Components/App/Icons';
function useRentsTable({ filters, onViewRent, showRentPotential, buildingFilterOptions, rentalObjectFilterOptions, scopeFilterLevel, openRenterMirrorDateExportForm, openRenterMirrorDateRangeExportForm, openRenterMirrorTenantListExportForm }) {
    const disableBuildingFilter = scopeFilterLevel !== 'property';
    const tableModel = React.useMemo(() => RentTableModel.create({
        manualControl: true,
        initialState: {
            filters: filters ? Object.keys(filters).map(key => ({ id: key, value: filters[key] })) : []
        },
        BulkActions: () => {
            return (_jsxs(DropdownButton, { label: translate('document.rent.renterMirror.general.title', {}, 'documents'), children: [_jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.dateReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.dateReportPdf.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateRangeExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.dateRangeReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorDateRangeExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.dateRangeReportPdf.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('excel'), children: [_jsx(ExcelIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportXls.title', {}, 'documents')] }), _jsxs(Dropdown.Item, { color: "dark", onClick: () => openRenterMirrorTenantListExportForm('pdf'), children: [_jsx(PdfIcon2, {}), " ", translate('document.rent.renterMirror.tenantListReportPdf.title', {}, 'documents')] })] }));
        },
        columns: {
            buildingId: {
                hide: true,
                Header: 'Gebäude',
                useFilters: {
                    Filter: BuildingFilter,
                    disableFilters: disableBuildingFilter,
                },
            },
            rentalObjectId: {
                hide: true,
                Header: 'Mietobjekt',
                useFilters: {
                    Filter: RentalObjectFilter,
                    disableFilters: disableBuildingFilter,
                },
            },
            reportingStart: {
                hide: true,
                Header: 'Stichtag',
                useFilters: {
                    Filter: DateFilter,
                }
            }
        }
    }), []);
    const getData = React.useCallback(async ({ request }) => {
        const url = generateRoute('api.rents.list');
        const { data } = await client.get(url, { params: request });
        return data;
    }, []);
    return tableModel.createRemote({
        getData,
        showRentPotential,
        onViewRent,
        buildingFilterOptions,
        rentalObjectFilterOptions,
    });
}
const RentPage = () => {
    const { openViewModalObj } = useObjectViewModalContext();
    const portfolio = usePortfolio();
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [rentalObjectOptions, setRentalObjectOptions] = useState([]);
    const scopeFilterLevel = getScopeFilterLevel();
    const renterMirrorDateExportForm = useRenterMirrorDateExportForm();
    const renterMirrorDateRangeExportForm = useRenterMirrorDateRangeExportForm();
    const renterMirrorTenantListExportForm = useRenterMirrorTenantListExportForm();
    const instance = useRentsTable({
        filters: {},
        onViewRent: (rent) => {
            openViewModalObj({ id: rent.id, view: ActiveView.RENT, contractId: rent.contract.id, parentRentalObjectId: rent.rentalObject.id });
        },
        showRentPotential: (rentId) => {
            setRentIdForPotential(rentId);
        },
        openRenterMirrorDateExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorDateExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        openRenterMirrorDateRangeExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorDateRangeExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        openRenterMirrorTenantListExportForm: (type) => {
            let scopeFilters = getScopeFilters(portfolio);
            renterMirrorTenantListExportForm.openForm({
                type,
                ...scopeFilters
            });
        },
        buildingFilterOptions: buildingOptions,
        rentalObjectFilterOptions: rentalObjectOptions,
        scopeFilterLevel,
    });
    const buildingFilterValue = instance.state.filters.find(({ id }) => id === 'buildingId')?.value;
    const selectedPropertyId = getScopePropertyId(portfolio);
    useEffect(() => {
        if (scopeFilterLevel !== 'property' || !selectedPropertyId) {
            setBuildingOptions([]);
            return;
        }
        const options = portfolio.getBuildingsByPropertyId(selectedPropertyId)
            .map(building => {
            return {
                value: building.id,
                label: building.name,
            };
        });
        options.unshift({ value: 0, label: translate('rent.state.all') });
        setBuildingOptions(options);
    }, [selectedPropertyId, scopeFilterLevel]);
    useEffect(() => {
        if (!buildingFilterValue) {
            setRentalObjectOptions([]);
            instance.setFilter('rentalObjectId', null);
            return;
        }
        const rentalObjects = portfolio.getRentalObjectsByBuildingId(buildingFilterValue);
        const rentalObjectColumn = instance.columns.find(c => c.id === 'rentalObjectId');
        instance.setFilter('rentalObjectId', 0);
        if (!rentalObjects.find(r => r.id == rentalObjectColumn.filterValue)) {
            instance.setFilter('rentalObjectId', 0);
        }
        const options = rentalObjects
            .map(building => {
            return {
                value: building.id,
                label: building.name,
            };
        });
        options.unshift({ value: 0, label: translate('rent.state.all') });
        setRentalObjectOptions(options);
    }, [buildingFilterValue]);
    const [rentIdForPotential, setRentIdForPotential] = React.useState();
    const actionDropdown = useCallback(({ Link, }) => {
        const propertyId = getScopePropertyId(portfolio);
        return (_jsx(_Fragment, { children: propertyId && _jsx(_Fragment, { children: _jsx(Link, { icon: 'download', href: "#", onClick: (e) => {
                        e.preventDefault();
                        generateDocument('document.rent.vacancies', "App\\Entity\\Property\\Property", propertyId);
                    }, children: translate('document.rent.vacancies', {}, 'documents') }) }) }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(PageOverview, { actionDropdown: actionDropdown, instance: instance, showFilters: true, title: translate('rents.title') }), _jsx(RentPotentialModal, { rentId: rentIdForPotential, onCloseModal: () => setRentIdForPotential(null), getRentPotentialForRentId: ContractServices.getRentPotentialForRentId }), _jsx(FormModal, { modal: renterMirrorDateExportForm }), _jsx(FormModal, { modal: renterMirrorDateRangeExportForm }), _jsx(FormModal, { modal: renterMirrorTenantListExportForm })] }));
};
export default RentPage;
