import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { toPx } from '@f2w/theme/system';
import { Icon as BaseIcon } from 'Components/Atoms/Icon';
import { useTheme } from '@f2w/theme';
import { inputSizeToken, inputStateToken } from './Input.tokens';
import { OrFunction } from '@f2w/utils';
const useProps = (_props) => {
    const { $size, $variant, className, style, onClick, role, as, inputAs, loading, disabled, readOnly, wrapperProps: _wrapperProps, ...inputProps } = _props;
    const token = useMemo(() => {
        return inputStateToken.getVariant({
            loading,
            disabled,
            readonly: readOnly,
        }, $variant, true);
    }, [$variant, disabled, loading, readOnly]);
    const providerProps = {
        $size,
        ...token,
        inputProps: {
            ...inputProps,
            disabled: token.disabled,
            readOnly: token.readonly,
            as: inputAs
        },
    };
    const wrapperProps = {
        ..._wrapperProps,
        as,
        role,
        $variant: token.$variant,
        $size,
        className,
        style,
        onClick,
    };
    return [
        wrapperProps,
        providerProps,
    ];
};
export var InputTheme;
(function (InputTheme) {
    function getVars({ $variant, $size, theme }, sizeToken = inputSizeToken) {
        const { palette, palette: { neutral } } = theme;
        const token = inputStateToken.get($variant);
        const stateVariant = token?.styles?.(theme, {});
        const stateColor = stateVariant?.color;
        const stateIconColor = stateVariant?.iconColor ?? stateColor;
        const activeColor = palette.primary;
        const { fontFamily, fontSize, lineHeight, letterSpacing, fontWeight, paddingX, paddingY, borderRadius, height, } = sizeToken.getStyles($size, { theme });
        const font = {
            fontFamily,
            fontSize,
            lineHeight,
            letterSpacing,
            fontWeight,
        };
        return {
            font: font,
            ...font,
            paddingX: paddingX,
            paddingY: paddingY,
            borderRadius: borderRadius,
            height: height,
            stateColor: stateColor,
            stateIconColor: stateIconColor,
            activeColor: activeColor,
            iconColor: neutral.$200,
            ...(token?.disabled ? {
                disabled: true,
                placeholderColor: neutral.$400,
                color: neutral.$900,
                backgroundColor: neutral.$50,
                borderColor: neutral.$100,
            } : {
                disabled: false,
                placeholderColor: neutral.$200,
                color: neutral.$900,
                backgroundColor: palette.white,
                borderColor: stateColor?.$500 ?? neutral.$100,
            }),
            $variant,
            $size,
            theme,
        };
    }
    InputTheme.getVars = getVars;
    function useVars({ $variant, $size }, sizeToken = inputSizeToken) {
        const theme = useTheme();
        return useMemo(() => getVars({ $variant, $size, theme }, sizeToken), [$size, $variant]);
    }
    InputTheme.useVars = useVars;
})(InputTheme || (InputTheme = {}));
(function (InputTheme) {
    function Provider({ children, ..._props }) {
        const [rootProps, props] = useProps({
            role: 'textbox',
            ..._props,
        });
        return (_jsx(InputTheme.Root, { ...rootProps, "aria-busy": props.loading, "aria-disabled": props.disabled, "data-input-state": props.$variant, children: OrFunction(children, props) }));
    }
    InputTheme.Provider = Provider;
    Provider.displayName = 'InputThemeProvider';
})(InputTheme || (InputTheme = {}));
(function (InputTheme) {
    InputTheme.Icon = BaseIcon;
    InputTheme.Focusable = styled.div ``;
    InputTheme.Input = styled.input ``;
    InputTheme.StateIcon2 = styled(InputTheme.Icon) ``;
    InputTheme.StateIcon = styled(InputTheme.Icon) `
        position: absolute;
        right: var(--fw-input__padding-x, 6px);
        top: 50%;
        transform: translateY(-50%);
        text-align: right;
    `;
    InputTheme.Main = styled.div `
      display: flex;
      flex: 1;
      align-items: center;
      align-self: stretch;
      padding: 0 var(--fw-input__padding-x, 6px);
      gap: var(--fw-input__padding-x, 6px);
      position: relative;
    `;
    InputTheme.Side = styled.span `
      --fw-input__icon-color: var(--fw-color-neutral-400);
      --fw-input__icon-shadow-color: #fff;
      display: flex;
      align-self: stretch;
      align-items: center;
      background: var(--fw-color-dark-100);
      color: var(--fw-color-dark-500);
      padding: 0 var(--fw-input__padding-x, 6px);
      text-shadow: 0 0 3px rgba(255, 255, 255, .9), -1px 1px 2px rgba(255, 255, 255, .5);

      ${InputTheme.Icon} {
        filter: drop-shadow(0px 0px 2px var(--fw-input__icon-shadow-color));
      }
    `;
    InputTheme.components = {
        Icon: InputTheme.Icon,
        StateIcon: InputTheme.StateIcon,
        Focusable: InputTheme.Focusable,
        Input: InputTheme.Input,
        Main: InputTheme.Main,
        Side: InputTheme.Side,
    };
    const Base = styled.div `
      display: flex;
      align-items: center;
      overflow: hidden;
      transition-timing-function: ease-in-out;
      transition-duration: 100ms;
      transition-property: box-shadow, border;

      color: var(--fw-input__color, inherit);
      background: var(--fw-input__background-color, white);
      border: var(--fw-input__border-size, 1px) solid var(--fw-input__border-color, #aaa);
      border-radius: var(--fw-input__border-radius, 0);

      font-size: var(--fw-input__font-size, 14px);
      line-height: var(--fw-input__line-height, 1.25em);
      height: var(--fw-input__min-height, 2em);

      *:is(${InputTheme.Main}, ${InputTheme.Side}) ${InputTheme.Icon} {
        font-size: var(--fw-input__line-height, 1.375em);
        color: var(--fw-input__icon-color, #aaa);
      }

      .fw-btn {
        font-size: 20px;
      }

        &:not([aria-disabled="true"]) {
            ${InputTheme.StateIcon} {
                color: var(--fw-input__state-icon-color);
            }

            &:hover {
                border-color: var(--fw-input__state-color, var(--fw-color-neutral-800));
            }

            ${InputTheme.Focusable}:focus-within > &,
            &:focus-within {
                border-color: var(--fw-input__active-color);

                &:hover {
                    box-shadow: 0 0 0 4px var(--fw-input__active-shadow-color);
                }
            }
        }

        &[aria-busy="true"] {
            animation-name: loading;
            animation-duration: 1.2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }

        @keyframes loading {
            50% {
                border-color: var(--fw-input__state-icon-color, var(--fw-color-neutral-800));
            }
        }

      input {
        flex: 1;
        width: 100%;
        max-width: 100%;
        min-width: var(--fw-input__min-width, auto);
        padding: 0;

        font-size: var(--fw-input__font-size, 14px);
        line-height: var(--fw-input__line-height, 1.25em);
        letter-spacing: inherit;
        font-weight: inherit;
        font-family: inherit;
        background: inherit;
        color: inherit;
        outline: none;
        border: none;

        &::placeholder {
          color: var(--fw-input__placeholder-color, inherit);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px inset var(--fw-input__background-color, white) !important; /* set background color */
          -webkit-text-fill-color: unset !important;
        }

        &[type="number"] {
          /* Firefox */
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    `;
    InputTheme.Root = styled(Base)(({ variant, $variant = variant, $size, theme: { palette: { neutral } }, theme, }) => useMemo(() => {
        const vars = InputTheme.getVars({
            $size,
            $variant,
            theme,
        });
        const styles = {
            '--fw-input__font-size': toPx(vars.fontSize),
            '--fw-input__line-height': toPx(vars.lineHeight),
            '--fw-input__padding-x': toPx(vars.paddingX),
            '--fw-input__padding-y': toPx(vars.paddingY),
            '--fw-input__border-radius': toPx(vars.borderRadius),
            '--fw-input__placeholder-color': vars.placeholderColor,
            '--fw-input__min-width': ['lg'].includes($size) ? '60px' : 'auto',
            '--fw-input__icon-color': vars.iconColor,
            '--fw-input__border-color': vars.borderColor,
            '--fw-input__state-color': vars.stateIconColor?.$500,
            '--fw-input__state-icon-color': vars.stateIconColor?.$500,
            '--fw-input__active-color': vars.stateColor?.$500 ?? vars.activeColor.$500,
            '--fw-input__active-shadow-color': `${vars.stateColor?.$500 ?? vars.activeColor.$500}40`,
            ...vars.font,
            height: vars.height,
            color: vars.color,
            borderColor: vars.borderColor,
            backgroundColor: vars.backgroundColor,
        };
        if (vars.disabled) {
            return css `
                  ${styles};

                  ${InputTheme.Main}, ${InputTheme.Side} {
                    opacity: 0.5;
                  }

                  ${InputTheme.StateIcon} {
                    display: none;
                  }
                `;
        }
        return styles;
    }, [$size, $variant]));
})(InputTheme || (InputTheme = {}));
