import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { Div } from 'Atoms/Layout';
import { Text, useTextProps, useTextStyles } from 'Atoms/Text';
import { translate } from 'Services/Translator';
import { IbanType } from '@f2w/form';
import escapeHTML from 'escape-html';
import { isEmptyChildren } from 'Components/Base/helpers';
import { OverlayTrigger, Tooltip } from 'Atoms/Overlay';
import { Icon } from 'Atoms/Icon';
import { getUuid } from '@f2w/utils';
import { ClipboardButton } from 'Atoms/Clipboard/ClipboardButton';
const prefix = 'fw-card';
const CardTitle = styled.h6.attrs(() => ({
    className: `${prefix}-title`,
})) `
  ${p => p.theme.typography.small.css({ fontWeight: 400, textTransform: 'none' })};
  color: ${p => p.theme.palette.neutral.$500};
  word-break: break-word;
  margin: 0;
`;
const CardText = styled.div.attrs((p) => ({
    ...useTextProps(p),
    className: `${prefix}-text`,
}))(useTextStyles);
const CardBody = styled(Div).attrs(() => ({
    className: `${prefix}-body`,
})) `
`;
const CardRoot = styled.div.attrs(() => ({
    className: `${prefix}`,
})) `
    position: relative;
  ${p => p.theme.typography.medium.css()};
  color: ${p => p.theme.palette.neutral.$800};
`;
export const CardContentRoot = styled.div `
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;

    & + & {
        margin-top: var(--fw-card-spacer-y);
    }

    > * {
        margin: 0;
    }

    [data-content="address"]& {

        [data-trigger="clipboard"] {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
`;
const EmptyContent = ({ empty }) => (empty ? null : _jsx(Text, { as: 'div', body: 500, neutral: 800, children: "-" }));
export const CardContent = ({ type, title, children, text, empty, infoText, clipboard, ...props }) => {
    const isEmpty = isEmptyChildren(children);
    return isEmpty && !title ? null : (_jsxs(CardContentRoot, { "data-content": type, ...props, children: [title && (_jsxs(CardTitle, { children: [_jsx(Div, { as: 'span', pr: 5, children: title }), infoText && (_jsx(OverlayTrigger, { placement: 'top', overlay: _jsx(Tooltip, { id: `card-tooltip-${getUuid()}`, children: infoText }), children: _jsx(Icon, { size: 15, icon: 'alert-circle' }) }))] })), isEmpty
                ? _jsx(EmptyContent, { empty: empty })
                : _jsx(CardText, { ...text, children: children }), clipboard && _jsx(ClipboardButton, { "$size": 'sm', clipboard: clipboard, variant: 'linkFill' })] }));
};
export const CardValue = (props) => (_jsx(CardContent, { ...props, text: { medium: 500 }, type: 'value' }));
export const CardHtmlContent = ({ value, ...props }) => (_jsx(_Fragment, { children: _jsx(CardContent, { ...props, type: 'html', children: value ? _jsx("div", { dangerouslySetInnerHTML: {
                __html: escapeHTML(value).replace(/\n/g, '<br />'),
            } }) : null }) }));
export const CardBankInfo = ({ label, iban, children, ...props }) => (_jsx(_Fragment, { children: _jsxs(CardContent, { ...props, type: 'bank', children: [label && _jsx(Text, { as: 'div', body: 500, children: label }), iban && _jsx(Text, { as: 'div', children: IbanType.useFormat(iban) })] }) }));
export const CardAddress = ({ label, street, streetNr, zip, city, countryCode, clipboard = true, ...props }) => {
    return (_jsxs(CardContent, { ...props, as: 'address', clipboard: clipboard && `${street} ${streetNr}, ${zip} ${city}, ${countryCode}`, type: 'address', children: [label && _jsx(Div, { children: label }), (street || streetNr) && _jsxs(Div, { children: [street, " ", streetNr] }), (zip && city) && _jsxs(Div, { children: [zip, " ", city] }), !!countryCode && _jsx(Div, { children: translate(`country.${countryCode}`) })] }));
};
export const Card = React.forwardRef(({ className, bgColor, textColor, text, border, noBody, fit = false, children, ...props }, ref) => {
    return (_jsx(CardRoot, { ref: ref, ...props, className: classNames(className, prefix, fit && `${prefix}--fit`), children: noBody ? children : (_jsx(CardBody, { children: children })) }));
});
Card.displayName = 'Card';
Card.defaultProps = {
    noBody: false,
};
const blocks = {
    Title: CardTitle,
    Text: CardText,
    Body: CardBody,
    BankInfo: CardBankInfo,
    Value: CardValue,
    HtmlContent: CardHtmlContent,
    Address: CardAddress,
    Content: CardContent,
};
Object.assign(Card, blocks);
