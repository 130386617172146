import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleType } from '../core';
import { RichTextMode } from 'Components/CKEditor/enum/RichTextMode';
import CKEditor from 'Components/CKEditor';
import { useEventCallback } from '@restart/hooks';
import { FormControl } from 'Atoms/Input';
export class TextType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super(options, parent);
    }
    WidgetComponent(props) {
        return _jsx(FormControl.TextArea, { ...{
                ...props,
            } });
    }
}
export class EditorType extends SimpleType.ExtendString {
    constructor(options, parent) {
        super(options, parent);
    }
    _renderWidget(props) {
        const onChange = useEventCallback((value) => {
            this.field.setValue(value);
        });
        const onBlur = useEventCallback((value) => {
            this.field.setTouched(true, true);
        });
        return _jsx(CKEditor, { onChange: onChange, onBlur: onBlur, toolBarType: RichTextMode[this.options.type] ?? RichTextMode.BLOCK, initialValue: this.field.initialValue });
    }
}
