import generateRoute from 'Services/Router';
import client from 'Utils/client';
import axios from "axios";
export const getCalculation = (id) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.details', { id });
    return client
        .get(route)
        .then(response => response.data)
        .then(({ title, period: { from: periodFrom, to: periodTo }, administrativeCost, property: { id: propertyId }, nbPeriodDays, nbNominalDays }) => ({
        propertyId,
        title,
        periodFrom,
        periodTo,
        administrativeCost,
        nbPeriodDays,
        nbNominalDays
    }));
};
export const getCalculationPropertyDetails = (propertyId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.propertyDetails', {
        id: propertyId
    });
    return client
        .get(route)
        .then(response => response.data);
};
export const deleteCalculation = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.delete', {
        calculation: calculationId
    });
    return client.delete(route);
};
export const createCalculationDraft = ({ propertyId, periodFrom, periodTo, administrativeCost }) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.createDraft');
    return client
        .post(route, {
        propertyId,
        calculationPeriod: { from: periodFrom, to: periodTo },
        administrativeCost
    })
        .then(response => response.data);
};
export const createCalculationBill = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.bill', {
        calculation: calculationId
    });
    return client.post(route);
};
export const saveAndBillCalculation = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveAndBill', {
        calculation: calculationId
    });
    return client.post(route);
};
export const createCalculationMissingInvoices = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.createMissingInvoices', {
        calculation: calculationId
    });
    return client.post(route);
};
export const revertCalculationBilling = (calculationId) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.revertBilling', {
        calculationId: calculationId
    });
    return client.post(route);
};
export const listCalculations = (params) => {
    const route = generateRoute('ancillaryExpenseCalculations.list');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export const listCalculationIds = (params) => {
    const route = generateRoute('ancillaryExpenseCalculations.ids');
    return client
        .get(route, { params })
        .then(response => response.data);
};
export async function listCalculationFees(calculationId, params) {
    const route = generateRoute('api.ancillaryExpenseCalculations.flatAndFloatingFees', {
        calculationId
    });
    const response = await client.get(route, { params });
    return response?.data?.results ?? [];
}
export async function updateFloatingContribution(calculationId, amounts) {
    const route = generateRoute('api.ancillaryExpenseCalculations.floatingContribution.update', {
        calculationId
    });
    const response = await client.post(route, { amounts });
    return response?.data?.results ?? [];
}
export async function listCalculationAccounts(calculationId, params) {
    const route = generateRoute('api.ancillaryExpenseCalculations.expenses', {
        calculation: calculationId
    });
    const response = await client.get(route, { params });
    return response?.data?.expenses ?? [];
}
export async function updateCalculationAccounts(calculationId, data) {
    const route = generateRoute('api.ancillaryExpenseCalculations.expenses.update', { calculationId });
    return await client.put(route, { expenses: data });
}
export async function listDistributions(calculationId) {
    const route = generateRoute('api.ancillaryExpenseCalculations.distributions', {
        calculation: calculationId
    });
    const response = await client.get(route);
    return response?.data?.distributions ?? [];
}
export async function updateDistributionKey(calculationId, data) {
    const route = generateRoute('api.ancillaryExpenseCalculations.distributions.updateKey', {
        calculationId
    });
    const response = await client.patch(route, data);
    return response.data.distribution;
}
export async function updateHeatingDaysShare(calculationId, heatingDaysShare, accountId, type) {
    const route = generateRoute('api.ancillaryExpenseCalculations.distributions.updateHeatingDaysShare', {
        calculationId
    });
    const response = await client.patch(route, {
        calculationId, heatingDaysShare, accountId, type
    });
    return response.data.distribution;
}
export async function saveDraft(calculationId, basics) {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveDraft', {
        calculationId
    });
    await client.post(route, basics);
}
export const createFixCostDistribution = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpense.fixedCostDistribution.create', {
        calculationId
    });
    return client.post(route, data);
};
export const deleteFixCostDistribution = (calculationId, accountId) => {
    const route = generateRoute('api.ancillaryExpense.fixedCostDistribution.delete', {
        calculationId,
        accountId
    });
    return client.delete(route);
};
export const createCommonAreaDistribution = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpense.commonAreaDistribution.create', {
        calculationId
    });
    return client.post(route, data);
};
export const deleteCommonAreaDistribution = (calculationId, accountId) => {
    const route = generateRoute('api.ancillaryExpense.commonAreaDistribution.delete', {
        calculationId,
        accountId
    });
    return client.delete(route);
};
export const saveMeters = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.saveMeters', {
        calculationId
    });
    return client.post(route, data);
};
export const vkaExport = (calculationId, data) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.vkaExport', {
        calculationId
    });
    return client.post(route, { accounts: data }, { responseType: 'blob' })
        .then(response => {
        return response.data;
    });
};
export const importVkaFile = (calculationId, uploadFile, validateOnly) => {
    const route = generateRoute('api.ancillaryExpenseCalculations.importVka', {
        calculationId,
        validateOnly
    });
    const formData = new FormData();
    formData.append('file', uploadFile.file);
    return axios
        .post(route, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => response.data);
};
export const getFinancialAccountStatement = (financialAccountId, calculationId, periodFrom, periodTo) => {
    const route = generateRoute('api.calculations.financialAccountStatement', {
        financialAccountId,
        calculationId
    });
    return client.get(route, { params: { periodFrom, periodTo } })
        .then(response => response.data);
};
