import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from '@f2w/form';
import { Button } from 'Atoms/Button';
import { Header, Section } from 'Atoms/Layout/components';
import { DocumentTemplateGlobalSettingsType, } from 'Pages/DocumentTemplates/GlobalSettings/DocumentTemplateGlobalSettingsType';
import documentTemplateServices, { DocumentTemplateServices, } from 'Pages/DocumentTemplates/services/documentTemplateServices';
import { Page } from 'Atoms/Layout';
import { translate } from 'Services/Translator';
import { generate } from 'Services/Router';
import { useDispatcherApi } from 'Components/Dispatcher';
import { AlignmentTypeEnum } from 'Pages/DocumentTemplates/GlobalSettings/types/AlignmentType';
import { PagePreview } from 'Pages/DocumentTemplates/EditV2/PagePreview';
import { renderTemplate } from 'Services/HtmlTemplateRenderer';
var getGlobalSettings = DocumentTemplateServices.getGlobalSettings;
const DocumentTemplateGlobalSettingsPage = ({ previewStylesheets }) => {
    const [globalSettings, setGlobalSettings] = useState(null);
    React.useEffect(() => {
        getGlobalSettings()
            .then(({ templateHTML, settings: globalSettings }) => {
            setGlobalSettings({
                templateHTML: templateHTML,
                header: globalSettings.header,
                headerAlignment: globalSettings.headerAlignment || AlignmentTypeEnum.LEFT,
                logo: globalSettings.logo?.payload,
                logoSize: {
                    width: globalSettings.logoWidth,
                    height: globalSettings.logoHeight
                },
                logoPosition: {
                    alignment: globalSettings.logoAlignment || AlignmentTypeEnum.LEFT,
                    offset: {
                        top: globalSettings.logoOffsetTop || 0,
                        left: globalSettings.logoOffsetLeft || 0
                    }
                },
                addressAlignment: globalSettings.addressAlignment || AlignmentTypeEnum.LEFT,
                footerAlignment: globalSettings.footerAlignment || AlignmentTypeEnum.RIGHT,
            });
        });
    }, []);
    return globalSettings
        ? _jsx(DocumentTemplateGlobalSettingsPageForm, { globalSettings: globalSettings, previewStylesheets: previewStylesheets })
        : null;
};
const DocumentTemplateGlobalSettingsPageForm = ({ globalSettings, previewStylesheets }) => {
    const { toast } = useDispatcherApi();
    const { templateHTML, previewData, ..._initialValues } = globalSettings;
    const [previewHTML, setPreviewHTML] = useState();
    const form = useForm({
        name: 'document-template-global-settings-form',
        initialValues: _initialValues,
        type: () => new DocumentTemplateGlobalSettingsType(),
        successMessage: null,
        onSubmit: async (values, formikHelpers, type) => {
            const blob = type.children.logo.field.getBinaryFile();
            const file = new File([blob], 'logo');
            const fieldValue = type.children.logo.value;
            let image = { state: 'existing' };
            if (blob !== null) {
                const uploadResponseData = await documentTemplateServices.uploadLogo(file);
                image = {
                    state: 'new',
                    payload: uploadResponseData.fileName,
                };
            }
            else if (fieldValue === null) {
                image = { state: 'empty' };
            }
            await documentTemplateServices.updateGlobalSettings({
                ...type.getUpsertData(),
                logo: image
            });
            toast.success({
                timeout: 5,
                message: translate('toast.saved.title')
            });
        }
    });
    useEffect(() => {
        const settings = form.getType().getUpsertData();
        const html = renderTemplate(templateHTML, { settings, ...previewData });
        setPreviewHTML(html);
    }, [form.values]);
    return (_jsxs(Page.Preview, { side: previewHTML && _jsx(PagePreview, { html: previewHTML, stylesheets: previewStylesheets }), children: [_jsx(Header.Page, { sticky: 0, title: translate('globalTemplateSettings.title'), backLink: generate('documentTemplates.new-list'), toolbar: _jsx(_Fragment, { children: _jsx(Button.Toolbar, { children: _jsx(Button, { label: translate('btn.save'), icon: 'save', onClick: () => form.submitForm() }) }) }) }), _jsx(Section, { children: _jsx(FormProvider, { form: form, children: form.render() }) })] }));
};
export default DocumentTemplateGlobalSettingsPage;
