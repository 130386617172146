import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SplitViewTemplate } from '@f2w/view';
import { Header, Section } from 'Atoms/Layout/components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Div, FlexCol, FlexRow, Icon, Nav, Text, Title } from 'Components/Atoms';
import { translate } from 'Services/Translator';
import { UploadImages } from 'Components/ObjectViewModal/components/UploadImages';
import ViewModal from 'Components/ObjectViewModal/components/ViewModalStyled';
import { ActiveView, ObjectViewModalServices, useObjectViewModal } from 'Components/ObjectViewModal';
import { RentalObjectViewFormType } from 'Components/ObjectViewModal/forms/RentalObjectViewFormType';
import { isString } from 'Utils';
import { renderOccupancyStatusBadge } from 'Components/ObjectViewModal/components/RentalObjectView/RentalObjectTemplate';
import { MoneyType } from '@f2w/form';
import { FormModal } from '@f2w/form-new';
import { CreatePropertyType } from 'Pages/BaseData/types';
import { CreatePropertyApi } from 'Pages/BaseData/services';
import { Card } from 'Atoms/Card';
import { OccupancyStatus } from 'Enums/OccupancyStatus';
const LeftSide = ({ view: { updateData, data: building, openForm } }) => {
    const onUpload = useCallback(async (files) => {
        const newImages = await ObjectViewModalServices.uploadBuildingImages(building.id, files.map(f => f.file));
        updateData(({ images }) => ({
            images: [...images, ...newImages]
        }));
    }, [building.id]);
    const onRemove = useCallback(async ({ id }) => {
        await ObjectViewModalServices.deleteBuildingImage(building.id, id);
        updateData(({ images }) => ({
            images: images.filter(i => i.id !== id)
        }));
    }, [building.id]);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { sticky: true, children: _jsx(UploadImages, { data: building.images, onUpload: onUpload, onDelete: onRemove }) }), _jsxs(Header.Sticky, { children: [_jsx(Title, { h6: true, as: 'h3', children: building.name }), _jsx(Div, { children: _jsx(Button.Edit, { onClick: () => openForm('generalLeft') }) })] }), _jsx(Section, { children: _jsxs(FlexCol, { gap: 16, children: [_jsxs(FlexRow, { gap: 16, children: [_jsxs(FlexCol, { gap: 16, flex: '50%', children: [_jsxs(Card, { children: [_jsx(Card.Value, { title: translate('baseData.building.egid'), children: building.egid }), _jsx(Card.Value, { title: translate('baseData.building.street'), children: building.address }), _jsx(Card.Value, { title: translate('baseData.building.houseNumber'), children: building.houseNumber })] }), _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('baseData.building.taxValue'), children: MoneyType.useFormat(building.taxValue) }), _jsx(Card.Value, { title: translate('baseData.building.insuranceValue'), children: MoneyType.useFormat(building.insuranceValue) }), _jsx(Card.Value, { title: translate('baseData.building.totalInvestment'), children: MoneyType.useFormat(building.totalInvestment) })] })] }), _jsx(FlexCol, { gap: 16, flex: '50%', children: _jsxs(Card, { children: [_jsx(Card.Value, { title: translate('baseData.property.buildYear'), children: building.buildYear }), _jsx(Card.Value, { title: translate('baseData.property.lastRenovation'), children: building.lastRenovation }), _jsx(Card.Value, { title: translate('baseData.building.numberOfFloors'), children: building.numberOfFloors }), _jsx(Card.Value, { title: translate('baseData.building.area'), children: building.area }), _jsx(Card.Value, { title: translate('baseData.building.bunker.details'), children: building.bunker
                                                    ? translate('baseData.building.bunker.present')
                                                    : translate('baseData.building.bunker.notPresent') })] }) })] }), _jsx(Card, { children: _jsx(Card.HtmlContent, { title: translate('baseData.building.notes'), value: building.notes }) })] }) })] }));
};
function useRentalObjectFloorFilters(rentalObjects) {
    const [activeFloor, setActiveFloor] = React.useState(null);
    const [floorMap, floorFilters] = useMemo(() => {
        let objectMap = {};
        let floorMap = {};
        rentalObjects?.forEach((rentalObject) => {
            const { id, floor } = rentalObject;
            objectMap[id] = rentalObject;
            floorMap[floor] = floorMap[floor] ?? {
                label: RentalObjectViewFormType.getFloorTrans(floor),
                floor,
                rentalObjects: [],
            };
            floorMap[floor].rentalObjects.push(rentalObject);
        });
        const floorFilters = Object.values(floorMap)
            .sort((a, b) => {
            if (isString(a)) {
                return -1;
            }
            return Number(a.floor) - Number(b.floor);
        });
        floorFilters.unshift({
            floor: null,
            label: translate('baseData.building.allFloors'),
            rentalObjects: Object.values(objectMap),
        });
        return [floorMap, floorFilters];
    }, []);
    return {
        floorFilters,
        activeFloor,
        rentalObjects: floorMap[activeFloor]?.rentalObjects ?? rentalObjects,
        setActiveFloor,
    };
}
const RightSide = ({ view: { data: building }, view, }) => {
    const { setCurrentActiveView } = useObjectViewModal();
    const { rentalObjects, floorFilters, activeFloor, setActiveFloor } = useRentalObjectFloorFilters(building.rentalObjects);
    const modalRef = FormModal.useRef();
    const [settings, setSettings] = useState();
    useEffect(() => {
        CreatePropertyApi.getSettings().then(v => setSettings(v));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(FormModal, { apiRef: modalRef, use: (props, api) => {
                    const title = translate('createProperty.rentalObject');
                    const valueType = CreatePropertyType.CreateRentalObject.useValue({
                        initialValue: { settings }
                    });
                    return {
                        valueType,
                        title,
                        onSave: async () => {
                            const data = valueType.getResolvedValue();
                            const response = await CreatePropertyApi.createRentalObject(building.id, data);
                            const id = response?.data?.id;
                            if (id) {
                                view.updateData(prev => ({
                                    rentalObjects: [
                                        ...prev.rentalObjects,
                                        { id, name: data.name, floor: data.floor, occupancyStatus: OccupancyStatus.VACANT }
                                    ]
                                }));
                                return true;
                            }
                        }
                    };
                } }), _jsxs(Header.Sticky, { children: [_jsx(Title, { children: translate('rentalObjects') }), _jsx(Button.New, { label: translate('createProperty.rentalObject.new'), onClick: () => modalRef.current.open({}) })] }), _jsxs(Section.Row, { children: [_jsx(Nav, { sticky: 'top', style: { top: 91, alignSelf: 'flex-start' }, as: Section.Side, id: `rental-objects-filters-${building.id}`, position: 'left', variant: 'pills', children: floorFilters.map(({ label, floor }) => (_jsx(Nav.Link, { nowrap: true, active: activeFloor === floor, label: label, onClick: () => setActiveFloor(floor) }, floor))) }), _jsx("div", { children: rentalObjects?.map(({ activeContract, ...rentalObject }) => (_jsxs(ViewModal.ObjectItem, { onClick: () => setCurrentActiveView(ActiveView.RENTAL_OBJECT, rentalObject.id), children: [_jsxs(FlexCol, { children: [_jsx(Text, { body: 500, neutral: 700, children: rentalObject.name }), !activeContract?.contractParties?.length ? null : (_jsx(Text, { small: true, neutral: 500, children: activeContract?.contractParties?.map((contractParty, index) => (_jsxs(React.Fragment, { children: [_jsx("span", { children: contractParty.fullName }), index + 1 === activeContract.contractParties.length ? "" : ", "] }, contractParty.id))) }))] }), _jsxs(FlexRow, { justifyRight: true, alignCenter: true, gap: 10, children: [renderOccupancyStatusBadge(rentalObject.occupancyStatus), _jsx(Icon, { icon: "chevron-right", size: 21 })] })] }, rentalObject.id))) })] })] }));
};
export const BuildingTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, leftSide: {
            groups: ['generalLeft'],
            Template: props => _jsx(LeftSide, { ...props }),
        }, rightSide: {
            groups: ['generalRight'],
            Template: props => _jsx(RightSide, { ...props }),
        } });
};
