import React, { useState } from 'react';
import { AbstractFormType, BooleanType, CollectionType, DateType, MoneyType, MonthType, NumberType, ObjectType, SelectType, TextType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import ContractServices from '../services/contractServices';
import { AccountServices as AncillaryExpenseAccountServices } from 'Pages/AncillaryExpense/services';
import { AncillaryExpenseType } from 'Pages/AncillaryExpense/enums';
import PersonServices from 'Services/PersonServices';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import BankAccountServices from 'Pages/BankAccount/services/bankAccountServices';
import { BankAccountType } from 'Pages/BankAccount/enums/BankAccountType';
import moment from 'moment';
import { range } from '@f2w/utils';
export class ContractType extends AbstractFormType {
    buildFields() {
        return {
            general: new ContractType.General({
                inheritData: true,
            }),
            ancillaryExpense: new ContractType.AncillaryExpenses({
                inheritData: true
            }),
            persons: new ContractType.Persons({
                inheritData: true,
            }),
            deposit: new ContractType.Deposit({
                inheritData: true
            }),
        };
    }
}
(function (ContractType) {
    class General extends AbstractFormType {
        mainContracts = [];
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: false,
            };
        }
        _useField(props) {
            super._useField(props);
            const [mainContracts, setMainContracts] = useState([]);
            const rentalObjectId = this.context?.data?.rentalObject?.id;
            const billingCycle = this.context?.data?.rent?.billingCycle;
            const mainContract = this.context?.data?.mainContract;
            const portfolio = usePortfolio();
            this.mainContracts = mainContracts;
            React.useEffect(() => {
                if (rentalObjectId && billingCycle) {
                    const property = portfolio.getPropertyByRentalObjectId(rentalObjectId);
                    ContractServices
                        .getMainContractsByProperty(property?.id, billingCycle)
                        .then(response => {
                        const contracts = response?.filter?.(contract => contract.id !== this.context?.data?.id)
                            .filter?.(contract => contract.id !== mainContract?.id);
                        if (mainContract) {
                            contracts.push({
                                id: mainContract.id,
                                contractPartyLastNames: mainContract.contractPartyLastNames,
                                starts: mainContract.startDate,
                                ends: mainContract.endDate,
                                rentalObjectName: mainContract.rentalObjectName,
                            });
                        }
                        setMainContracts(contracts.map(contract => ({
                            label: `
                                    ${moment(contract.starts).format('DD.MM.YYYY')} -
                                    ${contract.ends ? moment(contract.ends).format('DD.MM.YYYY') : translate('contract.details.unlimited')} /
                                    ${contract.contractPartyLastNames.join(', ')} /
                                    ${contract.rentalObjectName}
                                `,
                            value: contract.id,
                        })));
                    });
                }
            }, [rentalObjectId, billingCycle, mainContract]);
        }
        buildFields() {
            return {
                startDate: new DateType({
                    label: translate('contract.starts'),
                    required: true,
                    constraints: {
                        typeError: translate('form.validation.notValidDate'),
                    },
                }),
                moveInAt: new DateType({
                    label: translate('contract.moveInAt'),
                    nullable: true,
                    constraints: {
                        typeError: translate('form.validation.notValidDate'),
                    },
                }),
                endDate: new DateType({
                    label: translate('contract.ends'),
                    nullable: true,
                    constraints: {
                        typeError: translate('form.validation.notValidDate'),
                    },
                    disabled: () => this.field.value.hasOffboarding,
                    help: () => this.field.value.hasOffboarding
                        ? translate('contract.details.endDateDisabled.help')
                        : null
                }),
                moveOutAt: new DateType({
                    label: translate('contract.moveOutAt'),
                    required: false,
                    constraints: {
                        typeError: translate('form.validation.notValidDate'),
                    },
                }),
                familyApartment: new BooleanType({
                    label: translate('contract.isFamilyApartment'),
                    defaultValue: false,
                }),
                mainContractId: new SelectType({
                    label: translate('contract.mainContract'),
                    choiceOptions: () => this.mainContracts,
                    help: translate('contract.mainContract.help'),
                    required: false,
                }),
                terminationTerms: new ObjectType({
                    label: false,
                    fields: {
                        possibleNoticeMonths: new MonthType({
                            label: translate('contract.possibleNoticeMonths'),
                            required: true,
                            showDeselectAll: true,
                            showSelectAll: true,
                            getAllSelectableMonths: () => range(11, 1),
                            selectAllLabel: translate('contract.possibleNoticeMonths.selectAll')
                        }),
                        firstPossibleNoticeDate: new DateType({
                            label: translate('contract.firstPossibleNotice'),
                            required: true,
                            constraints: {
                                typeError: translate('form.validation.notValidDate'),
                            },
                        }),
                        noticePeriod: new NumberType({
                            label: translate('contract.noticePeriod'),
                            constraints: {
                                typeError: translate('form.validation.notValidNumber'),
                                min: [0, translate('form.validation.noLessThan', { length: 0 })],
                            },
                            required: true,
                        }),
                    },
                }),
                sideRooms: new TextType({
                    label: translate('contract.sideRooms'),
                    required: false,
                    nullable: false,
                }),
                sharedAssets: new TextType({
                    label: translate('contract.sharedAssets'),
                    required: false,
                    nullable: false,
                }),
            };
        }
    }
    ContractType.General = General;
    class AncillaryExpenses extends AbstractFormType {
        fixedExpenses = [];
        floatingExpenses = [];
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: false,
            };
        }
        _useField(props) {
            super._useField(props);
            const [fixedExpenses, setFixedExpenses] = useState([]);
            const [floatingExpenses, setFloatingExpenses] = useState([]);
            const rentalObjectId = this.field?.value?.rentalObject?.id;
            const portfolio = usePortfolio();
            this.fixedExpenses = fixedExpenses;
            this.floatingExpenses = floatingExpenses;
            React.useEffect(() => {
                if (rentalObjectId) {
                    const property = portfolio.getPropertyByRentalObjectId(rentalObjectId);
                    property && AncillaryExpenseAccountServices
                        .listAllAccounts({
                        pageIndex: 1,
                        pageSize: 100000,
                        filters: {
                            propertyId: property.id,
                        }
                    })
                        .then((results) => {
                        const fixedExpenses = [];
                        const floatingExpenses = [];
                        results?.forEach?.(result => {
                            if (result.collectionType === AncillaryExpenseType.FIXED) {
                                fixedExpenses.push({
                                    label: result.name,
                                    value: result.id,
                                });
                            }
                            else {
                                floatingExpenses.push({
                                    label: result.name,
                                    value: result.id,
                                });
                            }
                        });
                        setFixedExpenses(fixedExpenses);
                        setFloatingExpenses(floatingExpenses);
                    });
                }
            }, [rentalObjectId]);
        }
        buildFields() {
            return {
                floating: new CollectionType({
                    actions: { add: true, delete: true },
                    widget: 'table',
                    label: translate('contract.details.ancillaryExpenses.floating'),
                    prototype: () => new AncillaryExpenses.Floating({
                        label: false,
                        ancillaryExpenses: () => this.floatingExpenses,
                    }),
                    defaultValue: () => []
                }),
                fixed: new CollectionType({
                    actions: { add: true, delete: true },
                    widget: 'table',
                    label: translate('contract.details.ancillaryExpenses.fixed'),
                    prototype: () => new AncillaryExpenses.Fixed({
                        label: false,
                        ancillaryExpenses: () => this.fixedExpenses,
                    }),
                    defaultValue: () => []
                }),
            };
        }
    }
    ContractType.AncillaryExpenses = AncillaryExpenses;
    (function (AncillaryExpenses) {
        class Fixed extends AbstractFormType {
            buildFields() {
                return {
                    id: new NumberType({
                        isVisible: () => false,
                        required: false,
                    }),
                    accountId: new SelectType({
                        label: translate('contract.ancillaryExpenseAccounts'),
                        choiceOptions: () => this.options.ancillaryExpenses?.(),
                        required: true,
                    }),
                    flatValue: new NumberType({
                        label: translate('ancillaryExpense.value'),
                        required: true,
                        constraints: {
                            typeError: translate('form.validation.notValidNumber'),
                            positive: translate('form.validation.positiveValue'),
                        },
                    }),
                };
            }
        }
        AncillaryExpenses.Fixed = Fixed;
        class Floating extends AbstractFormType {
            buildFields() {
                return {
                    id: new NumberType({
                        isVisible: () => false,
                        required: false,
                    }),
                    accountId: new SelectType({
                        label: translate('contract.ancillaryExpenseAccounts'),
                        choiceOptions: () => this.options.ancillaryExpenses?.(),
                        required: true,
                    }),
                };
            }
        }
        AncillaryExpenses.Floating = Floating;
    })(AncillaryExpenses = ContractType.AncillaryExpenses || (ContractType.AncillaryExpenses = {}));
    class Persons extends AbstractFormType {
        persons = [];
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: false,
            };
        }
        _useField(props) {
            super._useField(props);
            const [persons, setPersons] = React.useState([]);
            this.persons = persons;
            const rentalObjectId = this.field?.value?.rentalObject?.id;
            React.useEffect(() => {
                rentalObjectId && this.loadPersonOptions(rentalObjectId)
                    .then(options => {
                    setPersons(options);
                });
            }, [rentalObjectId]);
        }
        async loadPersonOptions(rentalObjectId) {
            return PersonServices.listPersonsForDropdown(true)
                .then(results => {
                return results
                    .map(result => ({
                    label: result.name,
                    value: result.id,
                }));
            });
        }
        buildFields() {
            return {
                contractParties: new CollectionType({
                    label: translate('contract.contractParties'),
                    widget: 'table',
                    actions: { add: true, delete: true },
                    prototype: () => new Persons.ContractParty({
                        label: false,
                        persons: () => this.persons,
                    }),
                }),
                residents: new CollectionType({
                    label: translate('contract.residents'),
                    widget: 'table',
                    actions: { add: true, delete: true },
                    prototype: () => new Persons.Resident({
                        label: false,
                        persons: () => this.persons,
                    }),
                }),
            };
        }
    }
    ContractType.Persons = Persons;
    (function (Persons) {
        class ContractParty extends AbstractFormType {
            buildFields() {
                return {
                    id: new NumberType({
                        isVisible: () => false,
                        required: false,
                    }),
                    isPrimary: new BooleanType({
                        label: translate('contract.party.primary'),
                        defaultValue: false,
                    }),
                    personId: new SelectType({
                        label: translate('person'),
                        choiceOptions: () => this.options.persons?.(),
                        searchable: true
                    }),
                };
            }
        }
        Persons.ContractParty = ContractParty;
        class Resident extends AbstractFormType {
            buildFields() {
                return {
                    id: new NumberType({
                        isVisible: () => false,
                        required: false,
                    }),
                    personId: new SelectType({
                        label: translate('person'),
                        choiceOptions: () => this.options.persons?.(),
                        searchable: true
                    }),
                };
            }
        }
        Persons.Resident = Resident;
    })(Persons = ContractType.Persons || (ContractType.Persons = {}));
    class Deposit extends AbstractFormType {
        __getDefaultOptions() {
            return {
                ...super.__getDefaultOptions(),
                label: false,
            };
        }
        bankAccounts = [];
        _useField(props) {
            const [bankAccounts, setBankAccounts] = useState([]);
            this.bankAccounts = bankAccounts;
            React.useEffect(() => {
                BankAccountServices.getBankAccounts(BankAccountType.DEPOSIT)
                    .then(bankAccounts => {
                    setBankAccounts(bankAccounts.map(bankAccount => ({
                        label: bankAccount.name,
                        value: bankAccount.id
                    })));
                });
            }, []);
        }
        buildFields() {
            return {
                deposit: new MoneyType({
                    label: translate('contract.depositAmount'),
                    constraints: {
                        min: [0, translate('form.validation.noLessThan', { length: 0 })],
                    }
                }),
                depositBankAccountId: new SelectType({
                    label: translate('contract.depositBankAccount'),
                    required: false,
                    help: translate('helper.contract.depositAccount'),
                    choiceOptions: () => this.bankAccounts
                }),
                depositReceptionDate: new DateType({
                    label: translate('contract.depositReceptionDate'),
                    required: false
                }),
                depositIsInsurance: new BooleanType({
                    label: translate('contract.depositIsInsurance'),
                    help: translate('helper.contract.depositIsInsurance'),
                    required: false
                })
            };
        }
    }
    ContractType.Deposit = Deposit;
})(ContractType || (ContractType = {}));
