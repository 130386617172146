import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentTemplate } from '../types/DocumentTemplate';
import { translate } from 'Services/Translator';
import { generate } from 'Services/Router';
import DocumentTemplateServices from 'Pages/DocumentTemplates/services/documentTemplateServices';
import CKEditor from 'Components/CKEditor';
import { FieldValueErrorType } from 'Pages/DocumentTemplates/enums/FieldValueErrorType';
import { Button, Dropdown, FlexRow, Icon, Input, Page, Select, Title, Tooltip2 } from 'Components/Atoms';
import { useEventCallback } from '@restart/hooks';
import { downloadFile } from '@f2w/utils';
import { PagePreview } from 'Pages/DocumentTemplates/EditV2/PagePreview';
import { renderTemplate } from 'Services/HtmlTemplateRenderer';
import { Header, Section } from 'Atoms/Layout/components';
import { useDispatcherApi } from 'Components/Dispatcher';
var FieldType = DocumentTemplate.FieldType;
import { Checkbox } from "Atoms/Input/controls";
import { DocumentArea } from "Pages/DocumentTemplates/enums/DocumentArea";
const DocumentTemplatesEditV2Page = ({ template, htmlTemplate, previewStylesheets, globalSettings, initialDocumentTemplate: { id: templateId, templateInstanceKey: templateName, title: templateTrans, mandate: mandate, isDefault, areas: _initialAreas, fields: _initialFields, ..._initialConfig } = {}, availableUserScopeKeys: availableScopeKeys = [], }) => {
    const { toast } = useDispatcherApi();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [fields, _setFields] = useState([..._initialFields]);
    const [areas, setAreas] = useState([..._initialAreas]);
    const [previewHTML, setPreviewHTML] = useState();
    const isLoading = loading;
    const fieldsData = React.useMemo(() => {
        return Object.fromEntries(fields.map(({ name, value }) => [name, value]));
    }, [fields]);
    const updateField = useEventCallback((fieldName, value) => {
        _setFields(prev => prev.map(field => (field.name !== fieldName ? field : {
            ...field,
            value: value ?? '',
        })));
    });
    const updatePreview = useCallback(() => {
        const html = renderTemplate(htmlTemplate, { ...globalSettings, ...fieldsData });
        setPreviewHTML(html);
    }, [fieldsData]);
    const handleReset = useCallback(() => {
        _setFields([..._initialFields]);
    }, [_initialFields]);
    const handleDownloadPdf = useCallback(() => {
        return DocumentTemplateServices
            .downloadPdf(template, fieldsData)
            .then(({ fileName, data }) => downloadFile(data, fileName));
    }, [template, fieldsData]);
    const handleSubmit = useCallback(async () => {
        if (loading)
            return;
        setLoading(true);
        setError(undefined);
        return DocumentTemplate.editV2Action({
            ..._initialConfig,
            id: templateId ?? null,
            fields: fields,
            areas: areas,
            instanceKey: templateName,
            mandateId: mandate?.id,
        })
            .then(() => {
            toast.success({
                message: translate('flash.documentTemplate.successfullyModified'),
            });
        })
            .catch((error) => {
            if (error?.response?.status === 400 && error?.response?.data) {
                setError(error?.response?.data);
            }
            else {
                console.error(error);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    }, [loading, templateId, templateName, fields, areas]);
    const availableScopeKeysTooltipContent = React.useMemo(() => {
        const middleIndex = Math.ceil(availableScopeKeys.length / 2);
        const groups = [
            availableScopeKeys.slice(0, middleIndex),
            availableScopeKeys.slice(middleIndex),
        ].filter(group => group?.length > 0);
        return _jsxs(_Fragment, { children: [_jsxs("div", { children: [translate('documentTemplates.supportedKeys'), ":"] }), _jsx(FlexRow, { children: groups.map((group, groupKey) => (_jsx("ul", { children: group.map(key => (_jsx("li", { children: key }, key))) }, `group-${groupKey}`))) })] });
    }, [availableScopeKeys]);
    const fieldDataContent = React.useMemo(() => {
        return fields.map((field) => {
            let hasError = false;
            if (error) {
                if (error.type === FieldValueErrorType.SYNTAX_ERROR) {
                    hasError = !!error.values.find(fieldName => field.name === fieldName);
                }
                else {
                    hasError = !!Object.keys(error.values).find(fieldName => field.name === fieldName);
                }
            }
            return (_jsx(React.Fragment, { children: _jsxs(Section, { children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, minh: 40, children: [_jsx(Title, { h6: true, as: 'h3', children: field.title }), _jsx(FlexRow, { alignCenter: true, children: availableScopeKeys.length > 0 && (_jsx(Tooltip2, { placement: 'left-start', flip: true, content: availableScopeKeysTooltipContent, children: _jsx(Icon, { size: '1.5em', icon: 'info' }) })) })] }), _jsxs("div", { children: [field.fieldType === FieldType.INPUT && _jsxs(_Fragment, { children: [!!field.description && _jsx("div", { className: "form-text text-muted", children: field.description }), _jsx(Input, { hasError: hasError, placeholder: field.description, value: field.value, onChange: (value) => updateField(field.name, value) })] }), field.fieldType === FieldType.RICHTEXT && _jsxs(_Fragment, { children: [!!field.description && _jsx("div", { className: "form-text text-muted", children: field.description }), _jsx(CKEditor, { hasError: hasError, placeholder: field.description, initialValue: field.value, toolBarType: field.richTextMode, onChange: (value) => updateField(field.name, value), onBlur: () => { } })] }), field.fieldType === FieldType.CHECKBOX &&
                                    _jsx(Checkbox, { hasError: hasError, placeholder: field.description, checked: !!field.value, onChange: (value) => updateField(field.name, value), children: field.description })] })] }) }, field.name));
        });
    }, [fields, error]);
    const errorContent = React.useMemo(() => (error ? (_jsx("div", { className: "alert alert-danger", role: "alert", children: _jsx("div", { className: "alert-text", children: error.type === FieldValueErrorType.SYNTAX_ERROR
                ? translate('documentTemplates.error.invalidSyntax')
                : (_jsxs("div", { children: [_jsxs("div", { children: [translate('documentTemplates.error.unsupportedKeys'), ":"] }), _jsx("ul", { children: Object.values(error.values).flat(1).map((unsupportedKey) => (_jsx("li", { children: unsupportedKey }, unsupportedKey))) })] })) }) })) : null), [error]);
    useEffect(() => {
        updatePreview();
    }, [fields]);
    return (_jsxs(Page.Preview, { side: previewHTML && _jsx(PagePreview, { html: previewHTML, stylesheets: previewStylesheets }), children: [_jsx(Header.Page, { sticky: 0, title: _jsxs(_Fragment, { children: [templateTrans, " ", mandate ? _jsxs(_Fragment, { children: ["(", mandate.name, ")"] }) : null] }), backLink: generate('documentTemplates.new-list'), toolbar: _jsx(_Fragment, { children: _jsxs(Button.Toolbar, { children: [_jsx(Button, { label: translate('btn.save'), icon: 'save', disabled: isLoading, onClick: () => handleSubmit() }), _jsxs(Dropdown, { variant: 'dark', drop: 'down', alignRight: true, container: true, children: [_jsx(Dropdown.Toggle, { nocaret: true, variant: 'linkFill', color: 'neutral', dark: true, split: true, role: "combobox", "$size": 'md', icon: 'more-vertical' }), _jsxs(Dropdown.Menu, { children: [_jsx(Dropdown.Link, { label: translate('btn.reset'), disabled: isLoading, onClick: () => handleReset(), confirm: {
                                                    message: translate('documentTemplate.reset.message'),
                                                    btnConfirm: translate('documentTemplate.reset.confirm'),
                                                } }), _jsx(Dropdown.Link, { label: translate('btn.download'), icon: 'download', onClick: () => handleDownloadPdf() })] })] })] }) }) }), errorContent && _jsx(Section, { children: errorContent }), !isDefault && _jsx(React.Fragment, { children: _jsxs(Section, { children: [_jsxs(FlexRow, { alignCenter: true, justifySpaceBetween: true, minh: 40, children: [_jsx(Title, { h6: true, as: 'h3', children: translate('documentTemplate.list.areas') }), _jsx(Tooltip2, { placement: 'left-start', flip: true, content: translate('documentTemplate.list.areas.tooltip'), children: _jsx(Icon, { size: '1.5em', icon: 'info' }) })] }), _jsx("div", { children: _jsx(Select, { value: areas, options: Object.values(DocumentArea).map(area => ({
                                    label: translate(`document_area.title.${area}`),
                                    value: area
                                })), isMulti: true, onChange: (values) => {
                                    setAreas(values.map(v => v.value));
                                } }) })] }) }, "areas"), fieldDataContent] }));
};
export default DocumentTemplatesEditV2Page;
