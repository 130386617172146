import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Section } from 'Atoms/Layout/components';
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Icon } from "Atoms/Icon";
import { Text } from "Atoms/Text";
export const PropertiesTab = ({ group }) => {
    const { data, } = group;
    const propertiesRefs = data.propertiesRefs;
    if (propertiesRefs.length === 0) {
        return null;
    }
    return (_jsx(Section, { children: _jsx(FlexCol, { gap: 24, children: propertiesRefs.map(({ property, financialAccount }, index) => (_jsxs(FlexRow, { gap: 5, alignCenter: true, children: [_jsx(Div, { p: 8, style: { background: '#EDF0FF', color: '#4766FF', borderRadius: '32px' }, children: _jsx(Icon, { icon: 'buildings', size: 24 }) }), _jsx(Text, { medium: 400, primary: 500, children: property.name })] }, index))) }) }));
};
