import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { PersonType } from '@fw/enums/PersonType';
import { usePersonView } from 'Pages/Person/Details/components/PersonView/hooks/usePersonView';
import { DetailViewModal } from '@f2w/view';
import { translate } from 'Services/Translator';
export const PersonView = ({ personId, onClose, ...props }) => {
    const view = usePersonView(props);
    const title = useMemo(() => {
        const { personType, naturalPerson, corporatePerson } = view.data;
        if (personType === PersonType.NATURAL_PERSON) {
            return `${naturalPerson?.firstName} ${naturalPerson?.lastName}`;
        }
        return corporatePerson?.companyName ?? translate('person');
    }, [personId, view?.data?.personType]);
    React.useEffect(() => {
        view.load(personId);
    }, [personId]);
    return _jsx(DetailViewModal, { view: view, title: title, onClose: onClose });
};
