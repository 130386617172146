import { jsx as _jsx } from "react/jsx-runtime";
import createTheme from './system/createTheme';
import React, { useMemo } from 'react';
import { cleanObj, isString } from 'Utils';
import { createGlobalStyle, css, StyleSheetManager, ThemeContext as StyledThemeContext, ThemeProvider as StyledThemeProvider, useTheme as useStyledTheme, } from 'styled-components';
export { ThemeConsumer, ThemeContext } from "styled-components";
export const defaultTheme = createTheme();
export function useTheme() {
    return useStyledTheme() || defaultTheme;
}
useTheme.default = defaultTheme;
(function (useTheme) {
    function variantFactory(factory, variants) {
        const api = {
            ...{},
            list: variants,
            get(name, theme = defaultTheme) {
                return {
                    ...factory(theme),
                    ...(isString(name) ? variants[name]?.(theme) : name),
                };
            },
            use(name, props) {
                return {
                    ...api.get(name, useStyledTheme()),
                    ...props
                };
            },
        };
        return api;
    }
    useTheme.variantFactory = variantFactory;
})(useTheme || (useTheme = {}));
const ThemeStyles = createGlobalStyle(({ theme: { palette } }) => {
    return css `
  :root {
    --fw-a: 1px;
  }
`;
});
export function ThemeProvider({ theme: _theme, tokens: _tokens, children, ...vars }) {
    const prevTheme = React.useContext(StyledThemeContext) || defaultTheme;
    const theme = useMemo(() => ({
        ...prevTheme,
        ..._theme,
        ...vars,
        tokens: {
            ...prevTheme?.tokens,
            ...cleanObj(_tokens),
        },
    }), [prevTheme, _theme, _tokens, vars.mode]);
    if (!children) {
        return null;
    }
    return (_jsx(StyleSheetManager, { disableVendorPrefixes: true, children: _jsx(StyledThemeProvider, { theme: theme, children: children }) }));
}
