import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { useLayoutProps } from './hooks';
import { Root } from './theme';
export const Box = forwardRef((props, ref) => {
    return (_jsx(Root, { ...useLayoutProps(props), ref: ref }));
});
export const Div = forwardRef((props, ref) => {
    return (_jsx(Root, { ...useLayoutProps(props), ref: ref }));
});
