import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { OrFunction } from 'Utils';
import { FlexRow } from 'Atoms/Layout';
import { Button } from 'Atoms/Button/Button';
import { OverlayTrigger, Tooltip, tooltipSlotCss } from 'Atoms/Overlay';
import { Text } from './text';
export const MultiText = Object.assign(({ values, emptyValue = '-', ...props }) => {
    const [firstValue, ...rest] = OrFunction(values);
    if (!firstValue) {
        return _jsx(_Fragment, { children: emptyValue });
    }
    return rest.length > 0 ? (_jsx(OverlayTrigger, { flip: true, trigger: 'click', rootClose: true, ...props, overlay: _jsx(MultiText.Tooltip, { id: `tooltip-mt`, children: _jsx("ul", { children: rest.map((value, key) => (_jsx("li", { children: value }, `tooltip-mt-${key}`))) }) }), children: _jsxs(FlexRow, { inline: true, maxw: '100%', alignCenter: true, justifyFlexStart: true, children: [_jsx(Text, { ellipsis: true, children: firstValue }), _jsxs(Button, { variant: 'link', style: { paddingRight: 0, paddingLeft: 4 }, children: ["+", rest.length] })] }) })) : (_jsx(Text, { ellipsis: true, children: firstValue }));
}, {
    Tooltip: styled(Tooltip) `
          ${tooltipSlotCss('content')} {
            text-align: left;

            ul {
              padding-left: 20px;
              margin: 0;

              > li {
                padding: 4px 0;
              }
            }
          }
        `,
});
