import { jsx as _jsx } from "react/jsx-runtime";
import Portfolio from 'Models/Portfolio';
import PortfolioContext from 'Components/Base/PortfolioContext';
import { ThemeProvider } from '@f2w/theme';
import { DispatcherProvider } from 'Components/Dispatcher';
import '@f2w/polyfills';
import useStableMemo from '@restart/hooks/useStableMemo';
import { IdProvider } from 'Hooks/useId';
import useForceUpdate from '@restart/hooks/useForceUpdate';
import '../styles/fairwalter.scss';
import { AppRoot } from './layout/root';
export const FairwalterApp = ({ children, noWrap = false, className = "fw-v2", ...props }) => {
    if (noWrap) {
        return _jsx(AppProvider, { children: children });
    }
    return (_jsx("div", { className: className, ...props, children: _jsx(AppProvider, { children: children }) }));
};
const AppProvider = ({ children }) => {
    const dispatch = useForceUpdate();
    const portfolio = useStableMemo(() => Portfolio.instance({ dispatch }), []);
    return (_jsx(IdProvider, { children: _jsx(PortfolioContext.Provider, { value: portfolio, children: _jsx(ThemeProvider, { children: _jsx(DispatcherProvider, { children: _jsx(AppRoot, { children: children }) }) }) }) }));
};
