import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleType } from '../core';
import { FormControl } from 'Atoms/Input';
import { formatMoney } from 'Services/NumberHelper';
import { toSafeInteger } from 'lodash';
export class NumberType extends SimpleType.ExtendNumber {
    constructor(options, parent) {
        super(options, parent);
    }
    getWidgetProps() {
        return {
            ...super.getWidgetProps(),
            step: this.options.step,
            min: this.options.min,
            max: this.options.max,
        };
    }
    cast(value, ignoreDefaults) {
        value = super.cast(value, ignoreDefaults);
        if (this.options.step === 1) {
            if (value != null) {
                return toSafeInteger(value);
            }
        }
        return value;
    }
    WidgetComponent(props) {
        if (props.step > 0) {
            return _jsx(FormControl.Number, { ...props });
        }
        return _jsx(FormControl.Input, { type: 'number', ...props });
    }
}
export class DecimalType extends SimpleType.MaskedNumber {
    constructor({ scale = 2, ...options }, parent) {
        super({
            ...options,
            constraints: {
                ...options?.constraints,
            },
            mask: {
                mask: Number,
                thousandsSeparator: "'",
                scale,
                padFractionalZeros: true,
                normalizeZeros: true,
                radix: '.',
                mapToRadix: [','],
            },
        }, parent);
    }
    WidgetComponent(props) {
        return super.WidgetComponent({
            prefix: this.options.prefix ?? '.00',
            ...props,
        });
    }
}
export class MoneyType extends SimpleType.MaskedNumber {
    constructor(options, parent) {
        super({
            currency: 'CHF',
            ...options,
            constraints: {
                ...options?.constraints,
            },
            mask: {
                mask: Number,
                autofix: false,
                thousandsSeparator: "'",
                scale: 2,
                padFractionalZeros: true,
                normalizeZeros: true,
                radix: '.',
                mapToRadix: [','],
            },
        }, parent);
    }
    format(value) {
        return formatMoney(value);
    }
    WidgetComponent(props) {
        return super.WidgetComponent({
            ...props,
            prefix: this.options.currency
        });
    }
}
