import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { UseStyled, useTheme } from '@f2w/theme';
import { Icon } from 'Atoms/Icon';
import { Dropdown, dropdownSlot } from 'Atoms/Dropdown';
import { selectComponents, UseSelect } from '../base';
import defaultTheme from './defaultTheme';
import { mergeStyles } from 'react-select';
import { Badge } from 'Atoms/Badge';
import { Button } from 'Atoms/Button';
import { useDropdownToggle } from 'react-overlays/DropdownToggle';
import { FlexRow } from 'Atoms//Layout';
export const SelectToggle = (props) => {
    const selectProps = {
        hideSelectedOptions: false,
        ...props,
        ...toggleTheme,
        autoFocus: false,
        isSearchable: true,
        tabIndex: 1,
        closeMenuOnSelect: true,
        closeMenuOnScroll: false,
        openMenuOnClick: false,
        openMenuOnFocus: false,
        controlShouldRenderValue: false,
    };
    return _jsx(UseSelect, { clo: true, ...selectProps });
};
const Toggle = ({ selectProps, ...api }) => {
    const [toggleProps, meta] = useDropdownToggle();
    const { onClick, ...props } = toggleProps;
    const { maxValueWidth = 200, badgeProps } = selectProps;
    return _jsx(_Fragment, { children: _jsxs(FlexRow, { alignStart: true, gap: 4, flexWrap: true, ...props, children: [!api.hasValue && (_jsx(Button, { style: { padding: 0, fontSize: 18 }, "$size": 'md', variant: 'link', color: 'neutral', icon: 'plus-circle', onClick: onClick })), api.hasValue && api.getValue()?.map(v => (_createElement(Badge, { "$size": 'xs', "$color": 'primaryOutline', maxWidth: maxValueWidth, ...badgeProps, key: v.value, label: v.label, onClick: onClick })))] }) });
};
const DropdownStyled = UseStyled.styled('div', ({ theme: { palette } }) => ({
    '&&&': {
        [dropdownSlot.css('menu')]: {
            zIndex: 699,
            padding: 0,
        },
    }
}));
const { styles: { ...styles }, components } = defaultTheme;
export const toggleTheme = {
    components: {
        ...selectComponents,
        ...components,
        ClearIndicator: null,
        SelectContainer: ({ children, ...props }) => {
            const { fixed, menuIsOpen } = props.selectProps;
            return _jsxs(Dropdown, { container: true, as2: DropdownStyled, show: menuIsOpen, onToggle: (show, e, meta) => {
                    if (show) {
                        console.log('toggle show', show);
                        props.selectProps.onMenuOpen();
                    }
                    else {
                        props.selectProps.onMenuClose();
                    }
                }, variant: 'custom', focusFirstItemOnShow: false, children: [_jsx(Toggle, { ...props }), _jsx(Dropdown.Menu, { fixed: fixed, children: _jsx(selectComponents.SelectContainer, { ...props, children: children }) })] });
        },
        DropdownIndicator: (props) => {
            return _jsx(Icon, { icon: 'search' });
        },
        MenuList: ({ children, ...props }) => {
            const selectedOptions = [], options = [];
            React.Children.forEach(children, (child, i) => {
                if (React.isValidElement(child)) {
                    if (child.props.isSelected)
                        selectedOptions.push(child);
                    else
                        options.push(child);
                }
            });
            return _jsxs(selectComponents.MenuList, { ...props, children: [selectedOptions, options] });
        },
        Option: ({ children, ...props }) => {
            const clear = (e) => {
                if (props.isSelected && !props.isMulti) {
                    e.stopPropagation();
                    props.clearValue();
                    props.selectProps.onMenuClose();
                }
                else if (props.selectProps.closeMenuOnSelect) {
                    props.selectProps.onMenuClose();
                }
            };
            return (_jsxs(selectComponents.Option, { ...props, children: [_jsx("span", { children: children }), (props.selectProps.isClearable && props.isSelected) && _jsx(Icon, { onClick: clear, size: 20, icon: 'x' })] }));
        }
    },
    styles: mergeStyles(styles, {
        container: (base) => {
            const { palette } = useTheme();
            return {
                ...base,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                padding: '16px',
                gap: 8,
                width: '300px',
                background: palette.white,
                border: `2px solid ${palette.primary.$50}`,
                boxShadow: 'none',
                overflow: 'hidden',
                borderRadius: 8,
            };
        },
        menu: (base, state) => ({
            margin: '0',
            padding: '0',
            overflow: 'hidden',
        }),
        option: (base, state) => ({
            ...base,
            justifyContent: 'space-between',
        }),
        menuList: (base, state) => ({
            ...base,
            marginRight: -20,
            paddingRight: 8,
            overflowY: 'scroll',
        }),
        input: (base, state) => ({
            ...base,
            marginLeft: 0,
        }),
        indicatorsContainer: (base, state) => {
            const { paddingX } = state.theme.vars;
            return {
                ...base,
                order: 1,
                paddingLeft: paddingX,
            };
        },
        valueContainer: base => ({
            ...base,
            order: 2,
            padding: 0,
        }),
    }),
};
