import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CircularProgressbar, { buildStyles } from 'Components/CircularProgressbar';
import { RentalObjectIcon, StorageIcon, UserIcon } from "Components/App/Icons";
import UpgradeSubscriptionStyled from './UpgradeSubscriptionStyled';
import { translate } from "Services/Translator";
import { formatBytes } from "@f2w/utils";
const { Body, BottomPart, BottomPartTitle, Meters, MeterSection, MeterForm, MeterCaption } = UpgradeSubscriptionStyled;
const Meter = ({ value, maxValue, pathColor, trailColor, MeterIcon, caption }) => (_jsxs(MeterForm, { children: [_jsxs("div", { style: { width: 57, position: "relative" }, children: [_jsx(CircularProgressbar, { value: value, maxValue: maxValue, background: false, strokeWidth: 6, styles: buildStyles({
                        pathColor,
                        trailColor
                    }) }), _jsx("div", { style: { position: "absolute", left: 0, top: 0, width: "100%", height: "100%", display: "inline-flex", alignItems: "center", justifyContent: "center", color: "#565D6" }, children: _jsx(MeterIcon, { size: 16 }) })] }), _jsxs("div", { style: { color: pathColor }, children: [formatBytes(value), _jsxs("span", { style: { color: trailColor }, children: [" / ", maxValue !== null && maxValue !== 0 ? formatBytes('maxValue') : '∞'] })] }), _jsx(MeterCaption, { children: caption })] }));
const COLORS = {
    withinLimits: { path: '#16ab88', trail: '#b8e6db' },
    hitLimits: { path: '#ffa424', trail: '#ffe3bd' },
    overLimits: { path: '#ff3838', trail: '#ffc4c4' },
};
const getColors = (value, maxValue) => {
    if (maxValue === null || value < maxValue) {
        return COLORS.withinLimits;
    }
    if (value === maxValue) {
        return COLORS.hitLimits;
    }
    return COLORS.overLimits;
};
const ObjectsMeter = ({ value, maxValue }) => {
    const colors = getColors(value, maxValue);
    return (_jsx(_Fragment, { children: _jsx(Meter, { value: value, maxValue: maxValue, pathColor: colors.path, trailColor: colors.trail, MeterIcon: RentalObjectIcon, caption: translate('subscriptions.objects') }) }));
};
const UsersMeter = ({ value, maxValue }) => {
    const colors = getColors(value, maxValue);
    return (_jsx(Meter, { value: value, maxValue: maxValue, pathColor: colors.path, trailColor: colors.trail, MeterIcon: UserIcon, caption: translate('subscriptions.users') }));
};
const StorageMeter = ({ value, maxValue }) => {
    const colors = getColors(value, maxValue);
    return (_jsx(Meter, { value: value, maxValue: maxValue, pathColor: colors.path, trailColor: colors.trail, MeterIcon: StorageIcon, caption: translate('subscriptions.storage_mb') }));
};
const UpgradeSubscription = ({ planName, limits, usages }) => {
    return (_jsx(_Fragment, { children: _jsxs(Body, { children: [_jsxs(Meters, { children: [_jsx(MeterSection, { children: _jsx(ObjectsMeter, { value: usages.nbRentalObjects, maxValue: limits.nbRentalObjects }) }), _jsx(MeterSection, { children: _jsx(UsersMeter, { value: usages.nbUsers, maxValue: limits.nbUsers }) }), _jsx(MeterSection, { children: _jsx(StorageMeter, { value: usages.dmsLimitInBytes, maxValue: limits.dmsLimitInBytes }) })] }), _jsxs(BottomPart, { children: [_jsx(BottomPartTitle, { children: translate('subscriptions.upgradeSubscriptionTitle') }), _jsx("div", { children: translate('subscriptions.upgradeSubscriptionText', { planName }) })] })] }) }));
};
export default UpgradeSubscription;
