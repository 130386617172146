import { generate } from "Services/Router";
import axios from "axios";
import client from "Utils/client";
export var AuthServices;
(function (AuthServices) {
    AuthServices.login = async (data) => {
        const route = generate('api.login');
        return axios
            .post(route, data)
            .then(res => res.data);
    };
    AuthServices.twoFactorCheck = async (data) => {
        return client
            .post("/2fa_check", data)
            .then(res => res.data);
    };
    AuthServices.forgotPassword = (data) => {
        const route = generate('api.forgotPassword.inquire');
        return client.post(route, data);
    };
    AuthServices.resetPassword = (data, verificationToken) => {
        const route = generate('api.forgotPassword.reset', {
            verificationToken
        });
        return client.post(route, data);
    };
})(AuthServices || (AuthServices = {}));
