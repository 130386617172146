import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AbstractFormType, PasswordType } from "@f2w/form";
import { translate } from "Services/App";
import { Div, FlexRow } from "Atoms/Layout";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { Text } from "Atoms/Text";
import { CustomEmailType } from "Pages/Auth/types/CustomEmailType";
class ForgetPasswordType extends PasswordType {
    constructor(options, parent) {
        super({
            ...options,
            constraints: {
                max: 255,
                ...options?.constraints,
            }
        }, parent);
    }
    _renderLabel(_iProps) {
        return _jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, mb: 12, children: [_jsx(Div, { children: _jsx(Text, { neutral: 500, body: 400, children: this.options.label }) }), _jsx(SafeAnchor, { onClick: e => {
                        e.preventDefault();
                        this.options?.onForgotPassword?.();
                    }, style: { whiteSpace: 'nowrap' }, children: _jsx(Text, { medium: 400, children: translate('login.forgotPassword') }) })] });
    }
}
export class LoginType extends AbstractFormType {
    buildFields() {
        return {
            username: new CustomEmailType({
                label: translate('emailAddress'),
                getProps: () => ({
                    autoFocus: true,
                })
            }),
            password: new ForgetPasswordType({
                label: translate('password'),
                displayStrengthIndicator: false,
                onForgotPassword: () => this.options?.onForgotPassword(this.field.value.username)
            })
        };
    }
}
