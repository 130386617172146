import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { Modal as ModalNew } from 'Components/Modal';
import { Table, Tbody, Td, Tr } from 'Atoms/Table';
import { Icon } from 'Atoms/Icon';
import { Text } from 'Atoms/Text';
import { FlexCol } from 'Atoms/Layout';
import { formatBytes, orFunction, setDebugVariable } from '@f2w/utils';
import { UploadManager } from './UploadManager';
export const UploadDispatcher = forwardRef((props, ref) => {
    const [manager, upload] = UploadManager.useProvider(props);
    ref.current = manager;
    setDebugVariable({ manager, upload });
    return (_jsxs(ModalNew, { dialogAs: UploadManagerRoot, show: manager.isVisible, zIndex: 100100100, position: 'TOP_RIGHT', backdrop: false, autoFocus: false, enforceFocus: false, offset: 20, size: { width: 420, maxHeight: '60vh' }, onHide: () => manager.close(), children: [_jsx(ModalNew.Header, { closeButton: true, children: upload?.title() }), _jsx(ModalNew.Body, { padding: 10, children: _jsx(Table, { as: FilesTable.Root, colSpan: 2, border: false, borderRadius: false, rowRadius: false, rowDivider: true, rowSpacing: false, children: _jsx(Tbody, { children: upload?.files?.map(file => {
                            const { id, isLoading, isLoaded, error } = file;
                            const themeProps = file.error ? {
                                icon: 'alert-circle',
                                color: 'error.$500',
                            } : file.isLoaded ? {
                                icon: 'check',
                                color: 'success.$500',
                            } : {
                                icon: 'spinner',
                            };
                            return (_jsx(React.Fragment, { children: _jsxs(Tr, { status: error ? 'error' : isLoaded ? 'success' : undefined, loading: isLoading, children: [_jsx(Td, { auto: true, children: _jsxs(FlexCol, { children: [_jsxs(Text, { children: [file.name, " ", _jsx(Text, { neutral: 300, small: true, children: formatBytes(file.size) })] }), error && (_jsx(Text, { error: 500, children: orFunction(error.message) }))] }) }), _jsx(Td, { min: true, children: _jsx(Text, { colorName: themeProps.color, children: _jsx(Icon, { size: 20, icon: themeProps.icon }) }) })] }) }, id));
                        }) }) }) })] }));
});
const UploadManagerRoot = styled.div(() => css `
  ${ModalNew.slotCss('dialog')} {
    box-shadow: 0 8px 8px rgba(30, 43, 107, 0.1);
  }
`);
var FilesTable;
(function (FilesTable) {
    FilesTable.Root = styled.div `

    `;
    FilesTable.Info = styled.div `
      font-size: 20px;
      // ${Icon} {
      //   box-shadow: 0 8px 8px rgba(30, 43, 107, 0.1);
      // }
    `;
})(FilesTable || (FilesTable = {}));
