import { AbstractFormType, AddressType, BooleanType, DateType, MoneyType, NumberType, StringType, TextType } from "@f2w/form";
import { translate } from "Services/Translator";
export class BuildingViewFormType extends AbstractFormType {
    buildFields() {
        return {
            generalLeft: new BuildingType({})
        };
    }
}
export class BuildingType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    buildFields() {
        return {
            name: new StringType({
                label: translate('baseData.building.name'),
                required: true
            }),
            egid: new StringType({
                label: translate('baseData.building.egid'),
                required: false
            }),
            address: new AddressType({
                inherit: true,
                hideCity: true,
                hideZip: true,
                hideCountry: true
            }),
            buildYear: new DateType({
                label: translate('baseData.building.buildYear'),
                required: false,
                type: "year",
                constraints: {
                    moreThan: [999, translate('baseData.building.invalidYear')],
                }
            }),
            lastRenovation: new DateType({
                label: translate('baseData.building.lastRenovation'),
                required: false,
                type: "year",
                constraints: {
                    moreThan: [999, translate('baseData.building.invalidYear')],
                }
            }),
            numberOfFloors: new NumberType({
                label: translate('baseData.building.numberOfFloors'),
                nullable: false,
                required: false,
                step: 1,
                min: 0,
                max: 21,
                defaultValue: () => 0,
            }),
            area: new NumberType({
                label: translate('baseData.building.area'),
                required: false,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                }
            }),
            bunker: new BooleanType({
                label: translate('baseData.building.bunker'),
                required: false
            }),
            taxValue: new MoneyType({
                label: translate('baseData.building.taxValue'),
                required: true,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                }
            }),
            insuranceValue: new MoneyType({
                label: translate('baseData.building.insuranceValue'),
                required: true,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                }
            }),
            totalInvestment: new MoneyType({
                label: translate('baseData.building.totalInvestment'),
                required: true,
                constraints: {
                    min: [0, translate('form.validation.positiveValue')]
                }
            }),
            notes: new TextType({
                label: translate('baseData.building.notes'),
                required: false,
                nullable: false,
            }),
        };
    }
}
