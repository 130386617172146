import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { dataAttr, toPx, UseStyled } from '@f2w/theme';
import { useModalContext } from '../ModalContext';
import { createPositionMargin, getModalSizeProps, modalSlot } from '../Modal.theme';
export const ModalRoot = modalSlot.create('root', {
    tag: 'div',
    props: ({ children, ...props }) => {
        const { scrollable, size, offset, fullScreen } = useModalContext();
        return {
            ...props,
            style: {
                ...props.style,
                padding: toPx(offset),
            },
            children: fullScreen ? children : _jsx(ModalDialog, { children: children })
        };
    }
});
export const ModalDialog = UseStyled.styledWithAttrs('div', ({ className, ...props }) => {
    const { scrollable } = useModalContext();
    return ({
        ...modalSlot.attr('dialog'),
        'data-scrollable': dataAttr(scrollable),
        'data-scrollable2': dataAttr(props.scrollable),
        className: classNames(className, 'fw-modal-dialog'),
    });
}, ({ theme: { palette } }) => {
    const { scrollable, size, position } = useModalContext();
    const { height, maxHeight, minHeight, ...rest } = getModalSizeProps(size);
    let props = {
        '--border-radius': '16px',
        ...rest,
        margin: createPositionMargin(0, position),
    };
    if (scrollable || height || maxHeight || minHeight) {
        props = {
            ...props,
            height: height || undefined,
            maxHeight: maxHeight || '100%',
            minHeight,
        };
    }
    return {
        '&&&': props
    };
});
ModalDialog.displayName = 'ModalDialog';
