import React from 'react';
import { AbstractFormType, BooleanType, CollectionType, SelectType, } from '@f2w/form';
import { translate } from 'Services/Translator';
import { usePortfolio } from 'Components/Base/PortfolioContext';
import { UpdateCreditBankAccountType, UpdateDepositBankAccountType, UpdateRentBankAccountType, UpdateTenantBankAccountType } from "Pages/BankAccount/forms/UpdateBankAccountTypes";
import { useFinancialAccountsHook } from "Hooks/useFinancialAccountsHook";
import useStableMemo from '@restart/hooks/useStableMemo';
export class BankAccountViewFormType extends AbstractFormType {
    buildFields() {
        return {
            generalRent: new UpdateRentBankAccountType({
                inheritData: true,
            }),
            generalDeposit: new UpdateDepositBankAccountType({
                inheritData: true,
            }),
            generalTenant: new UpdateTenantBankAccountType({
                inheritData: true,
            }),
            generalCredit: new UpdateCreditBankAccountType({
                inheritData: true,
            }),
            properties: new BankAccountPropertiesType({
                inheritData: true,
            }),
        };
    }
}
class PropertyRefType extends AbstractFormType {
    properties = [];
    _useField(props) {
        super._useField(props);
        const otherPropertyRefFields = this.parent
            ? Object.entries(this.parent.children)
                .filter(([childName]) => childName !== this.field.props.name)
                .map(([, field]) => field.children.propertyId.field.value)
            : [];
        this.properties = useStableMemo(() => {
            return this.options?.getPropertiesOptions?.().map(property => ({
                ...property,
                isDisabled: otherPropertyRefFields.includes(property.value)
            })) ?? [];
        }, [otherPropertyRefFields]);
    }
    buildFields() {
        return {
            isPrimary: new BooleanType({
                label: translate('bank.primary'),
                required: false,
                defaultValue: false,
                onChange: value => {
                    if (value && this.parent) {
                        Object.entries(this.parent.children)
                            .filter(([childName]) => childName !== String(this.field.props.name))
                            .forEach(([, field]) => field.children.isPrimary.field.setValue(false));
                    }
                }
            }),
            propertyId: new SelectType({
                label: translate('property'),
                choiceOptions: () => this.properties,
            }),
            financialAccountId: new SelectType({
                label: translate('bank.financialAccount'),
                searchable: true,
                choiceOptions: () => this.options?.getGroupedFinancialAccountOptions?.()
            }),
        };
    }
}
export class BankAccountPropertiesType extends AbstractFormType {
    __getDefaultOptions() {
        return {
            ...super.__getDefaultOptions(),
            label: false,
        };
    }
    groupedFinancialAccountOptions = [];
    propertiesOptions = [];
    _useField(props) {
        const portfolio = usePortfolio();
        const { groupedFinancialAccountOptions } = useFinancialAccountsHook({});
        this.groupedFinancialAccountOptions = groupedFinancialAccountOptions;
        React.useEffect(() => {
            this.propertiesOptions = portfolio.getProperties().map(({ id, name }) => ({ value: id, label: name }));
        }, []);
    }
    buildFields() {
        return {
            propertiesRefs: new CollectionType({
                label: translate('properties'),
                actions: { add: true, delete: true },
                prototype: () => new PropertyRefType({
                    label: false,
                    getPropertiesOptions: () => this.propertiesOptions,
                    getGroupedFinancialAccountOptions: () => this.groupedFinancialAccountOptions,
                })
            }),
        };
    }
}
