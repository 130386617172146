import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { translate } from "Services/App";
import { useRef, useState } from "react";
import { SafeAnchor } from "Atoms/SafeAnchor";
import moment from "moment";
import { AuthServices } from "Pages/Auth/services";
import { LoginSection } from "Pages/Auth/components/LoginSection";
import "./auth.scss";
import { Dropdown } from "Atoms/Dropdown";
import generateRoute from "Services/Router";
import logo from "../../../images/auth/fw-logo@1x.svg";
import { TwoFactorSection } from "Pages/Auth/components/TwoFactorSection";
import { ForgotPasswordSection } from "Pages/Auth/components/ForgotPasswordSection";
import { ResetPasswordSection } from "Pages/Auth/components/ResetPasswordSection";
import { MaintenanceBanner } from "Pages/Administration/Maintenance/components/MaintenanceBanner";
import { MaintenanceType } from "Pages/Administration/Maintenance/enums";
export const LoginPage = ({ authServices = AuthServices, csrfToken, isLanguageSwitcherActive, activeLocale, activeSection: initialActiveSection = 'login', verificationToken, email, maintenance }) => {
    const initialEmailRef = useRef(null);
    const [activeSection, setActiveSection] = useState(initialActiveSection);
    return (_jsxs(FlexCol, { h: '100vh', children: [maintenance.isActive && (_jsx(MaintenanceBanner, { message: maintenance.message, severity: maintenance.severity })), _jsxs(FlexRow, { flexGrow: 1, bg: 'white', children: [_jsxs(FlexCol, { justifySpaceBetween: true, px: 64, py: 80, w: 800, children: [_jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, children: [_jsx("img", { src: logo }), isLanguageSwitcherActive && (_jsxs(Dropdown, { children: [_jsx(Dropdown.Toggle, { variant: 'link', children: _jsxs(Text, { medium: 400, neutral: 500, children: [activeLocale === 'de' && (_jsxs(FlexRow, { gap: 5, children: [_jsx(Div, { h: 20, w: 20, brd: 20, className: "ch-flag" }), " CH"] })), activeLocale === 'en' && (_jsxs(FlexRow, { gap: 5, children: [_jsx(Div, { h: 20, w: 20, brd: 20, className: "us-flag" }), " EN"] }))] }) }), _jsxs(Dropdown.Menu, { children: [_jsxs(Dropdown.Item, { href: generateRoute('switch_locale', { locale: 'de' }), target: "_blank", children: [_jsx(Div, { h: 20, w: 20, brd: 20, className: "ch-flag" }), " CH"] }), _jsxs(Dropdown.Item, { href: generateRoute('switch_locale', { locale: 'en' }), target: "_blank", children: [_jsx(Div, { h: 20, w: 20, brd: 20, className: "us-flag" }), " EN"] })] })] }))] }), activeSection === 'login' && (_jsx(LoginSection, { canLogin: maintenance.type !== MaintenanceType.EXTERNAL || !maintenance.isActive, csrfToken: csrfToken, authServices: authServices, onForgotPassword: (initialEmail) => {
                                    setActiveSection('forgot_password');
                                    initialEmailRef.current = initialEmail;
                                } })), activeSection === '2fa' && (_jsx(TwoFactorSection, { twoFactorCheck: authServices.twoFactorCheck })), activeSection === 'forgot_password' && (_jsx(ForgotPasswordSection, { initialEmail: initialEmailRef.current, forgotPassword: authServices.forgotPassword, onBack: () => setActiveSection('login') })), activeSection === 'reset_password' && (_jsx(ResetPasswordSection, { email: email, verificationToken: verificationToken, resetPassword: authServices.resetPassword, toLogin: () => setActiveSection('login') })), _jsxs(FlexCol, { gap: 16, children: [_jsxs(Text, { body: 400, children: ["\u00A9Fairwalter AG 2017-", moment().format('YYYY')] }), _jsxs(FlexRow, { gap: 8, children: [_jsx(SafeAnchor, { target: '_blank', href: "https://www.fairwalter.com/rechtliches?tab=agb", children: translate('termsAndServices.short') }), _jsx("span", { children: "|" }), _jsx(SafeAnchor, { target: '_blank', href: "https://www.fairwalter.com/ressourcen/datensicherheit", children: translate('rgpd') }), _jsx("span", { children: "|" }), _jsx(SafeAnchor, { target: '_blank', href: "https://www.fairwalter.com/rechtliches?tab=impressum", children: translate('imprint') })] })] })] }), _jsx(Div, { className: 'login-image' })] })] }));
};
