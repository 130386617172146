import { uploadFile, uploadFiles } from "./UploadManager";
import axios from "axios";
import generateRoute from "Services/Router";
const UPLOAD_URL = '/_uploader/customer_documents/upload';
export function uploadCustomerDocument(file, params) {
    return uploadFile(UPLOAD_URL, file, params);
}
export function uploadCustomerDocuments(files, params, onFileUploaded = null) {
    return uploadFiles(UPLOAD_URL, files, params, onFileUploaded);
}
export function getDocuments(criteria, limit, offset) {
    const url = `/documents/search`;
    return axios.get(url, {
        params: { ...criteria, limit, offset },
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentFileName(customerDocumentId, fileName) {
    const url = `/documents/${customerDocumentId}/fileName`;
    return axios.patch(url, { fileName }, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentCategory(customerDocumentId, customerDocumentCategory) {
    const url = `/documents/${customerDocumentId}/categories/${customerDocumentCategory}`;
    return axios.patch(url, {}, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentProperty(customerDocumentId, customerDocumentProperty) {
    const url = `/documents/${customerDocumentId}/properties/${customerDocumentProperty}`;
    return axios.patch(url, {}, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentBuilding(customerDocumentId, customerDocumentBuilding) {
    const url = `/documents/${customerDocumentId}/buildings/${customerDocumentBuilding}`;
    return axios.patch(url, {}, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentRentalObject(customerDocumentId, customerDocumentRentalObject) {
    const url = `/documents/${customerDocumentId}/rentalObjects/${customerDocumentRentalObject}`;
    return axios.patch(url, {}, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function updateCustomerDocumentContract(customerDocumentId, customerDocumentContract) {
    const url = `/documents/${customerDocumentId}/contracts/${customerDocumentContract}`;
    return axios.patch(url, {}, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
}
export function deleteCustomerDocument(customerDocumentId) {
    const url = `/documents/${customerDocumentId}`;
    return axios.delete(url);
}
export const exportCustomerDocuments = () => {
    const url = generateRoute('customerDocuments.export');
    return axios.post(url);
};
