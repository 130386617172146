import generateRoute from "Services/Router";
import client from 'Utils/client';
export var DocumentTemplate;
(function (DocumentTemplate) {
    let FieldType;
    (function (FieldType) {
        FieldType["INPUT"] = "input";
        FieldType["CHECKBOX"] = "checkbox";
        FieldType["RICHTEXT"] = "richtext";
    })(FieldType = DocumentTemplate.FieldType || (DocumentTemplate.FieldType = {}));
    DocumentTemplate.editV2Action = async ({ instanceKey, mandateId, id, ...config }) => {
        const url = generateRoute('documentTemplate.editv2', {
            instanceKey: instanceKey,
            mandateId: mandateId || null,
        });
        const data = {
            ...config,
            id: id || null,
        };
        return client
            .post(url, {
            ...data,
            id: id || null,
        })
            .then(response => response.data);
    };
    DocumentTemplate.editAction = async ({ instanceKey, mandateId, id, ...config }) => {
        const url = generateRoute('documentTemplate.edit', {
            instanceKey: instanceKey,
            mandateId: mandateId || null,
        });
        const data = {
            ...config,
            id: id || null,
        };
        return client
            .post(url, {
            ...data,
            id: id || null,
        })
            .then(response => response.data);
    };
})(DocumentTemplate || (DocumentTemplate = {}));
