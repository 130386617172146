import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLoginForm } from "Pages/Auth/hooks/useLoginForm";
import { FlexCol, FlexRow } from "Atoms/Layout";
import { Text } from "Atoms/Text";
import { translate } from "Services/App";
import { Form, FormProvider } from "@f2w/form";
import { Button } from "Atoms/Button";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { useToastDispatcherApi } from "Components/Dispatcher";
import { generate } from "Services/Router";
export const LoginSection = ({ authServices, csrfToken, onForgotPassword, canLogin }) => {
    const toastApi = useToastDispatcherApi();
    const form = useLoginForm({
        login: authServices.login,
        csrfToken,
        toastApi,
        onForgotPassword
    });
    return (_jsxs(FlexCol, { gap: 40, children: [_jsxs(FlexCol, { gap: 8, children: [_jsx(Text, { h6: 500, children: "Hey, Hello \uD83D\uDC4B" }), _jsx(Text, { medium: 400, children: translate('login.enterCredentials') })] }), _jsxs(FlexCol, { gap: 24, children: [_jsx(FormProvider, { form: form, children: _jsx(Form, { noValidate: true, onKeyPress: e => {
                                if (e.key === "Enter") {
                                    void form.submitForm();
                                }
                            }, children: form.render() }) }), _jsx(Button, { disabled: !canLogin, onClick: () => {
                            void form.submitForm();
                        }, children: "Login" }), _jsxs(FlexRow, { gap: 8, children: [_jsx(Text, { children: translate('account.notRegistered') }), _jsx(SafeAnchor, { href: generate('app_register'), children: translate('account.register.now') })] })] })] }));
};
