import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React from 'react';
import { MenuBuilder } from '../MenuBuilder';
import { isFunction } from 'Utils';
import { Dropdown } from '../../Dropdown';
import { ButtonGroup } from 'Atoms/ButtonGroup';
import { translate } from 'Services/Translator';
import { Div } from 'Atoms/Layout';
export var RenderMode;
(function (RenderMode) {
    RenderMode[RenderMode["Nested"] = 0] = "Nested";
    RenderMode[RenderMode["Flat"] = 1] = "Flat";
})(RenderMode || (RenderMode = {}));
export class BaseMenuItem {
    parent;
    _renderMode = RenderMode.Nested;
    options = Object.create(null);
    constructor(options) {
        Object.assign(this.options, options);
    }
    __init(parentPath = (this.parent?.path ?? '')) {
        const { label, translationKey, translationParams } = this.options;
        const translation = translationKey && translate(translationKey, translationParams ?? {});
        this._path = [parentPath, this._index].filter(v => v).join('-');
        this._label = isFunction(label) ? label(translation) : translation ?? label;
    }
    init(parentPath) {
        if (!this._initialized) {
            this.__init(parentPath);
        }
        return this;
    }
    get props() {
        return this.options.props;
    }
    get path() {
        return this._path;
    }
    get label() {
        return this._label;
    }
    get id() {
        return this.options.id ?? `fw-menu-${this.path}`;
    }
    getProps() {
        return { ...this.options.props };
    }
    isNested() {
        return (this instanceof NestedMenu);
    }
    isLink() {
        return false;
    }
    render(props, renderMode) {
        this._renderMode = renderMode || RenderMode.Nested;
        return this._render(props);
    }
    setParent(parent, index) {
        this.parent = parent;
        this._index = index;
        parent.add(this);
    }
}
export class NestedMenu extends BaseMenuItem {
    _children = new Set;
    _dropdownProps;
    constructor({ children, ...options }) {
        super(options);
        this.add(children);
    }
    __init(parentPath) {
        super.__init(parentPath);
        const { dropdown } = this.options;
        this._dropdownProps = dropdown && (dropdown === true ? {} : dropdown);
        this._children.forEach(c => c.init());
    }
    getChildren() {
        return [...this._children];
    }
    hasChildren() {
        return this._children.size > 0;
    }
    has(child) {
        return this._children.has(child);
    }
    hasDropdown() {
        return !!(this._dropdownProps && this.hasChildren());
    }
    static resolve(child, items = []) {
        if (Array.isArray(child))
            child.forEach(c => this.resolve(c, items));
        else if (isFunction(child))
            this.resolve(child(MenuBuilder.create()), items);
        else if (child instanceof MenuBuilder)
            this.resolve(child.getChildren(), items);
        else if (child instanceof BaseMenuItem)
            items.push(child);
        return items;
    }
    add(child) {
        NestedMenu.resolve(child).forEach(child => {
            if (!this.has(child)) {
                this._children.add(child);
                child.setParent(this, this._children.size);
            }
        });
        return this;
    }
    renderChildren(props) {
        return this.getChildren().map((child, key) => child.render({ key }, this._renderMode));
    }
    _render(props) {
        const itemProps = { ...this.getProps() };
        if (this.hasDropdown() && this._renderMode !== RenderMode.Flat) {
            return _createElement(Dropdown, { ...this._dropdownProps, as2: ButtonGroup, id: this.id, key: this._index, ...props },
                this._renderItem(itemProps),
                _jsx(Dropdown.Menu, { children: this.renderChildren() }));
        }
        return _jsxs(React.Fragment, { children: [this._renderItem(itemProps), _jsx(Div, { style: { marginLeft: this._renderMode !== RenderMode.Flat ? 0 : 30 }, children: this.renderChildren() })] }, props?.key ?? this._index);
    }
}
