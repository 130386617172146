import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { ModalNew } from "Components/Modal";
import { UseStyled } from "@fw/theme";
import { Div, FlexCol, FlexRow } from "Atoms/Layout";
import { css } from "styled-components";
import { translate } from "Services/App";
import { IbanType } from "@f2w/form";
import { Button } from "Atoms/Button";
import { generate } from "Services/Router";
import { BankAccountType } from "Pages/BankAccount/enums";
import { SafeAnchor } from "Atoms/SafeAnchor";
import { Spinner } from "Atoms/Spinner";
const WarningBlock = UseStyled.styled(FlexCol, ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.warning.$50};
    color: ${palette.warning.$500};
    border-radius: ${rem(8)};
    padding: ${rem(16)};
    ${typography.medium.fontStyles}
`));
const ErrorBlock = UseStyled.styled(FlexCol, ({ theme: { palette, typography, rem } }) => (css `
    background: ${palette.error.$50};
    color: ${palette.error.$500};
    border-radius: ${rem(8)};
    padding: ${rem(16)};
    ${typography.medium.fontStyles}
`));
const ModalTitle = UseStyled.styled(ModalNew.Title, () => (css `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`));
export const BankingFileUploadModal = ({ fileToUpload, onClose, upload }) => {
    const [uploadResponse, setUploadResponse] = useState();
    useEffect(() => {
        upload(fileToUpload).then(setUploadResponse);
    }, []);
    const body = useMemo(() => {
        if (uploadResponse === undefined) {
            return undefined;
        }
        if (uploadResponse.invalidErrorMessage !== undefined && uploadResponse.invalidErrorMessage !== null) {
            return _jsxs(ErrorBlock, { gap: 5, children: [_jsxs(Div, { children: [translate('bankingReconciliation.upload.error.invalidFile'), ":"] }), _jsx("code", { style: { whiteSpace: 'break-spaces' }, children: uploadResponse.invalidErrorMessage })] });
        }
        if (uploadResponse.isIgnored) {
            return translate('bankingReconciliation.duplicatedFiles');
        }
        if (uploadResponse.ibansMissing.length > 0) {
            return _jsxs(WarningBlock, { gap: 10, children: [_jsx(Div, { children: translate('bankingReconciliation.upload.error.missingRentBankAccounts.pre') }), _jsx(Div, { children: translate('bankingReconciliation.upload.error.missingRentBankAccounts.text') }), _jsxs(Div, { children: [translate('bankingReconciliation.upload.error.missingRentBankAccounts.end'), ":"] }), _jsxs(FlexCol, { children: [uploadResponse.ibansMissing.map((iban, index) => (_jsxs(FlexRow, { gap: 5, children: [_jsx(Div, { children: IbanType.format(iban) }), _jsx(SafeAnchor, { href: generate('bank_accounts_overview_page', {
                                            openCreateModal: BankAccountType.RENT,
                                            iban
                                        }), target: "_blank", children: translate('bankAccount.new.rent') })] }, index))), uploadResponse.banksWithMissingData.map(bankAccount => (_jsxs(FlexRow, { gap: 5, children: [_jsx(Div, { children: bankAccount.iban }), _jsx(SafeAnchor, { href: generate('bank_accounts_overview_page', {
                                            bankAccountId: bankAccount.id,
                                        }), target: "_blank", children: translate('bankAccount.edit.rent') })] }, bankAccount.id)))] })] });
        }
        return (_jsxs(FlexCol, { gap: 10, children: [_jsxs(Div, { children: [uploadResponse.transactionsAutoAssigned, " ", translate('bankingReconciliation.transactionsAutoAssigned')] }), _jsxs(Div, { children: [uploadResponse.transactionsNotAssigned, " ", translate('bankingReconciliation.transactionsNotAllocated')] }), _jsxs(Div, { children: [uploadResponse.transactionsIgnored, " ", translate('bankingReconciliation.transactionsIgnored')] })] }));
    }, [uploadResponse]);
    if (uploadResponse === undefined) {
        return (_jsxs(ModalNew, { show: true, onHide: onClose, size: { maxWidth: '500px' }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalTitle, { title: fileToUpload.name, children: fileToUpload.name }) }), _jsx(ModalNew.Body, { children: _jsx(FlexRow, { h: '100%', justifyCenter: true, alignCenter: true, children: _jsx(Spinner, {}) }) }), _jsx(ModalNew.Footer, { children: _jsx(Button, { onClick: onClose, disabled: true, children: translate('btn.close') }) })] }));
    }
    return (_jsxs(ModalNew, { show: true, onHide: onClose, size: {
            maxWidth: uploadResponse.ibansMissing.length > 0
                || (uploadResponse.invalidErrorMessage !== undefined && uploadResponse.invalidErrorMessage !== null)
                ? '800px'
                : '500px'
        }, children: [_jsx(ModalNew.Header, { closeButton: true, onHide: onClose, children: _jsx(ModalTitle, { title: uploadResponse.ibansMissing.length > 0 ? undefined : fileToUpload.name, children: uploadResponse.ibansMissing.length > 0
                        ? translate('bankingReconciliation.upload.error.missingBankAccounts')
                        : fileToUpload.name }) }), _jsx(ModalNew.Body, { children: body }), _jsx(ModalNew.Footer, { children: _jsx(Button, { onClick: onClose, children: translate('btn.close') }) })] }));
};
