import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ButtonColorDeprecated, buttonSizeToken, ButtonVariantTokens, } from './Button.tokens';
import { Icon } from 'Components/Atoms/Icon';
import styled from 'styled-components';
import { BaseButton } from './BaseButton';
import { isString } from 'Utils/types';
import { cssVars, ThemeProvider } from '@f2w/theme';
import { tokenGetStyles } from '@f2w/theme/system';
import { translate } from 'Services/Translator';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { ButtonToolbar } from '../ButtonToolbar/ButtonToolbar';
function strToPositions(value) {
    const [top, right = top, bottom = top, left = right] = value.split(' ');
    return { top, right, bottom, left };
}
const buttonStyles = ({ $color, theme: { palette, tokens }, $size = tokens?.size, color: _colorName = tokens?.color, rounded, theme, block, dark, ...props }) => {
    const styleDeps = Object.keys(ButtonVariantTokens).map(key => props[key]);
    const [_cssVars, _css] = useMemo(() => {
        const colorName = palette?.hasOwnProperty(_colorName) ? _colorName : 'primary';
        const color = palette[colorName];
        const variantCss = (isString($color)
            ? tokenGetStyles(ButtonColorDeprecated, $color, 'primary')?.(theme)
            : tokenGetStyles(ButtonVariantTokens, props, tokens?.variant ?? 'fill')?.(theme, {
                color,
                colorName,
                dark
            }));
        const { lineHeight, borderRadius, paddingX, paddingY, height, ...styles } = buttonSizeToken.getStyles($size, { theme });
        if (block) {
            styles.width = '100%';
            styles.justifyContent = 'center';
            styles.flex = 1;
        }
        return [
            cssVars({
                lineHeight,
                borderRadius: rounded ? '50%' : borderRadius,
                paddingX,
                paddingY,
                borderSize: 1,
                minHeight: height,
            }, 'btn-'),
            {
                ...styles,
                ...variantCss,
            }
        ];
    }, [_colorName, props.variant, $size, block, $color, ...styleDeps]);
    return {
        ..._cssVars,
        transitionProperty: 'color, background-color, border-color, box-shadow',
        transitionDuration: '250ms',
        transitionTimingFunction: 'ease-in',
        gap: '.65em',
        ..._css,
        [`${Icon}, .fw-icon`]: {
            fontSize: 'calc(1em + 2px)',
        },
    };
};
const StyledButton = styled(BaseButton)
    .attrs((props) => {
    return {
        className: props.link || props.variant === 'link' ? 'fw-btn-nogap' : '',
    };
})
    .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['color', ...Object.keys(ButtonVariantTokens)].includes(prop),
})(buttonStyles);
export const ButtonTheme = ({ children, size, color, variant, }) => {
    const tokens = useMemo(() => ({ size, color, variant }), [size, color, variant]);
    return (_jsx(ThemeProvider, { tokens: tokens, children: children }));
};
export const Button = Object.assign(Object.assign(StyledButton, {
    ButtonTheme,
    Base: StyledButton,
    Group: ButtonGroup,
    Toolbar: ButtonToolbar,
}), btnTypes({
    Edit: (props) => _jsx(StyledButton, { icon: 'edit', variant: 'outline', label: translate('btn.edit'), ...props }),
    Action: (props) => _jsx(StyledButton, { variant: 'outline', ...props }),
    New: (props) => _jsx(StyledButton, { icon: 'plus', variant: 'outline', label: translate('btn.new'), ...props }),
    Cancel: (props) => _jsx(StyledButton, { variant: 'linkFill', color: 'error', label: translate('btn.cancel'), ...props }),
    Save: (props) => _jsx(StyledButton, { label: translate('btn.save'), ...props }),
    Delete: (props) => _jsx(StyledButton, { variant: 'link', icon: 'trash-2', color: 'error', label: translate('btn.delete'), confirm: translate('confirm.message.delete'), ...props }),
}));
function btnTypes(components) {
    Object.values(components).forEach((c) => {
        c.displayName = 'Button';
    });
    return components;
}
