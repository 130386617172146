import { jsx as _jsx } from "react/jsx-runtime";
import { ContentTemplateNew, SplitViewTemplate } from '@f2w/view';
import { Header } from 'Atoms/Layout/components';
import { PersonType } from '@fw/enums/PersonType';
import { translate } from 'Services/Translator';
const getPersonInitials = (person) => {
    if (person.personType === PersonType.NATURAL_PERSON) {
        return `${person.naturalPerson?.firstName?.charAt(0)}${person.naturalPerson?.lastName?.charAt(0)}`.toUpperCase();
    }
    return person?.corporatePerson?.companyName?.charAt(0).toUpperCase();
};
const HeaderTemplate = ({ view: { data: person, }, }) => {
    const isNatural = person.personType === PersonType.NATURAL_PERSON;
    return (_jsx(Header.Avatar, { avatarText: getPersonInitials(person), pretitle: translate(isNatural ? 'person.type.natural' : 'person.type.corporate'), title: isNatural
            ? `${person.naturalPerson.firstName} ${person.naturalPerson.lastName}`
            : person.corporatePerson.companyName }));
};
export const PersonTemplate = (props) => {
    return _jsx(SplitViewTemplate, { ...props, Template: ContentTemplateNew, leftSide: {
            Header: HeaderTemplate,
            groups: ['profile', 'financial', 'contracts', 'pastAddress'],
        }, rightSide: {
            groups: ['invoices', 'waitingList'],
        } });
};
