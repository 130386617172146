import { extend } from 'Utils/extend';
import { isString } from 'Utils';
import tokens from './palette.tokens';
export var Palette;
(function (Palette) {
    Palette.tokenMap = tokens;
})(Palette || (Palette = {}));
export function createPalette({ mode = 'light', colors: tokens, } = {}) {
    const colors = extend(true, Palette.tokenMap, tokens || {});
    const parse = (colorName) => {
        const [token, level] = isString(colorName) ? colorName?.split('.') : [colorName].flat(3);
        return colors[token]?.[level];
    };
    return {
        mode,
        colors,
        parse,
        color: (...args) => {
            const [name, level = '$500'] = args.flat(3);
            let color = colors[name] ?? colors.neutral;
            return color[level] ?? color.$500;
        },
        ...colors,
        white: '#ffffff',
        black: '#000000',
    };
}
