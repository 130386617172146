import styled from 'styled-components';
const ContentBase = styled.div `
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
  overflow: auto;
`;
ContentBase.defaultProps = {
    'data-slot': 'content'
};
export const Content = Object.assign(ContentBase, {
    Base: ContentBase,
});
