import { jsx as _jsx } from "react/jsx-runtime";
import { Select } from 'Atoms/Select';
import _ from 'lodash';
import { translate } from 'Services/Translator';
import { useMemo } from 'react';
const YearSelect = ({ min, max, ...props }) => {
    const options = useMemo(() => {
        const now = new Date();
        const maxYear = max || now.getFullYear();
        const minYear = min || maxYear - 10;
        if (minYear > maxYear) {
            return [];
        }
        return _.range(maxYear, minYear - 1, -1).map((year) => ({ value: year, label: year.toString() }));
    }, [min, max]);
    return (_jsx(Select, { placeholder: translate('Year'), options: options, ...props }));
};
export default YearSelect;
