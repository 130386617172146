import { jsx as _jsx } from "react/jsx-runtime";
import { translate } from "Services/Translator";
import { Badge } from "Atoms/Badge";
const colors = {
    "upcoming": "primary",
    "inactive": "danger",
    "active": "success",
};
const RentState = ({ state }) => {
    const color = state ? colors[state] : null;
    return (_jsx(Badge, { "$color": color, children: state ? translate(`rent.state.${state}`) : '' }));
};
export default RentState;
