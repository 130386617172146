import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AbstractFormType, NumberType } from '@f2w/form';
import { FlexCol, FlexRow } from 'Components/Atoms';
import { translate } from 'Services/Translator';
export class LogoOffsetType extends AbstractFormType {
    constructor(props) {
        super({ label: false, ...props });
    }
    buildFields() {
        return {
            left: new NumberType({
                label: translate('globalTemplateSettings.fromLeft'),
                step: 1,
                defaultValue: 0
            }),
            top: new NumberType({
                label: translate('globalTemplateSettings.fromTop'),
                step: 1,
                defaultValue: 0
            }),
        };
    }
    _renderWidget(props) {
        return (_jsx(_Fragment, { children: _jsxs(FlexRow, { gap: 30, children: [_jsx(FlexCol, { flex: 1, children: this.renderChild('left') }), _jsx(FlexCol, { flex: 1, children: this.renderChild('top') })] }) }));
    }
}
