import { isNum, toString } from 'Utils';
export var Breakpoint;
(function (Breakpoint) {
    const defaultRules = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1399,
    };
    const _createRules = ({ values: _values, unit = 'px', step = 5 } = {}) => {
        const keys = Object.keys(defaultRules);
        const rules = {};
        keys
            .map((key, index) => ({
            key,
            val: _values?.[key] ?? defaultRules[key],
            prevKey: keys[index - 1],
            nextKey: keys[index + 1],
        }))
            .map(rule => {
            rules[rule.key] = rule;
        });
        const minRule = (val) => isNum(val = rules[val]?.val ?? val) ? `(min-width: ${val}${unit})` : '';
        const maxRule = (val) => isNum(val = rules[val]?.val ?? val) ? `(max-width: ${val - step / 100}${unit})` : '';
        const andRule = (...args) => args.map(v => toString(v).trim()).filter(v => v).join(' and ');
        return {
            rules,
            keys,
            minRule,
            maxRule,
            andRule,
        };
    };
    function create(options = {}) {
        const { rules, minRule, maxRule, andRule, } = _createRules(options);
        const api = {
            values: rules,
            up: (key) => `@media ${minRule(key)}`,
            down: (key) => `@media ${maxRule(key)}`,
            between: (start, end) => `@media ${andRule(minRule(start), maxRule(end))}`,
            only: (key) => rules[key]?.nextKey ? api.between(key, rules[key].nextKey) : api.up(key),
            not: (key) => {
                const rule = rules[key];
                if (!rule.prevKey)
                    return api.up(rule.nextKey);
                if (!rule.nextKey)
                    return api.down(rule.key);
                return api
                    .between(key, rule.nextKey)
                    .replace('@media', '@media not all and');
            },
        };
        return api;
    }
    Breakpoint.create = create;
})(Breakpoint || (Breakpoint = {}));
const sortBreakpointsValues = (values) => {
    const breakpointsAsArray = Object.keys(values).map((key) => ({ key, val: values[key] })) || [];
    breakpointsAsArray.sort((a, b) => a.val - b.val);
    return breakpointsAsArray.reduce((acc, obj) => {
        return { ...acc, [obj.key]: obj.val };
    }, {});
};
