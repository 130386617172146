import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { Select } from 'Atoms/Select';
import moment from 'moment';
import { FlexRow } from 'Atoms/Layout';
import { translate } from 'Services/Translator';
import { FormControl } from 'Atoms/Input';
export function SelectFilter({ table: { column: { filterValue, setFilter, preFilteredRows, id } }, ...props }) {
    const options = React.useMemo(() => {
        const options = new Set;
        const loadedOptions = {};
        preFilteredRows.forEach(row => {
            const value = row.values[id];
            loadedOptions[value] = { value, label: row.values[id] };
            options.add({ value: row.values[id], label: row.values[id] });
        });
        return [...Object.values(loadedOptions)];
    }, [id, preFilteredRows]);
    const onChange = useCallback((p) => {
        let value = undefined;
        if (p) {
            if (props.isMulti) {
                if (Array.isArray(p)) {
                    value = p.map(v => v?.value).filter(v => v);
                }
            }
            else {
                value = p?.value;
            }
        }
        setFilter(value);
    }, [setFilter]);
    return (_jsx(Select, { isSearchable: true, isClearable: true, options: options, ...props, value: filterValue, onChange: onChange }));
}
SelectFilter.displayName = 'TableSelectFilter';
export function DateRangeFilter({ filterValue, min, max, onChange, isClearable, ...iProps }) {
    const props = {
        minWidth: true,
        ...iProps,
        startDate: filterValue?.from,
        endDate: filterValue?.to,
    };
    return (_jsxs(FlexRow, { gap: 10, alignCenter: true, children: [_jsx("span", { children: translate('from') }), _jsx("div", { children: _jsx(FormControl.DatePicker, { ...props, isClearable: isClearable && props.startDate != null, selectsStart: true, minDate: min, maxDate: filterValue?.to ?? max, onChange: (date, e, manager) => {
                        let value = undefined;
                        if (date) {
                            date = moment(date);
                            if (date?.isValid())
                                value = date.format('YYYY-MM-DD');
                        }
                        onChange?.({
                            ...filterValue,
                            from: value,
                        }, manager);
                    } }) }), _jsx("span", { children: translate('to') }), _jsx("div", { children: _jsx(FormControl.DatePicker, { ...props, isClearable: isClearable && props.endDate != null, selectsEnd: true, minDate: filterValue?.from ?? min, maxDate: max, onChange: (date, e, manager) => {
                        let value = undefined;
                        if (date) {
                            date = moment(date);
                            if (date?.isValid())
                                value = date.format('YYYY-MM-DD');
                        }
                        onChange?.({
                            ...filterValue,
                            to: value,
                        }, manager);
                    } }) })] }));
}
