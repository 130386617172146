import { isDevEnv } from '@f2w/utils';
import Translator from 'Services/Translator';
import { getGlobalContext, saveFavouritesNew } from 'Services/GlobalContext';
let globPortfolioData;
if (isDevEnv()) {
    globPortfolioData = require('./data/portfolio_2.json');
}
class Portfolio {
    _dispatchUpdate;
    propertiesMap = new Map();
    buildingsMap = new Map();
    rentalObjectsMap = new Map();
    contractsMap = new Map();
    mandatesMap = new Map();
    favourites = {
        properties: new Map(),
        rentalObjects: new Map(),
    };
    constructor(portfolioData, _dispatchUpdate) {
        this._dispatchUpdate = _dispatchUpdate;
        if (portfolioData) {
            const { navFavourites, scope } = getGlobalContext();
            portfolioData?.properties?.forEach(data => {
                if (navFavourites?.properties?.includes?.(data.id)) {
                    this.favourites.properties.set(data.id, data);
                }
                this.propertiesMap.set(data.id, data);
                this.mandatesMap.set(data.mandate.id, data.mandate);
            });
            portfolioData?.buildings?.forEach(data => {
                this.buildingsMap.set(data.id, data);
            });
            portfolioData?.rentalObjects?.forEach(data => {
                if (navFavourites?.rentalObjects?.includes?.(data.id)) {
                    this.favourites.rentalObjects.set(data.id, data);
                }
                this.rentalObjectsMap.set(data.id, data);
            });
            portfolioData?.contracts?.forEach(data => {
                this.contractsMap.set(data.id, data);
            });
        }
    }
    static instance(props) {
        if (!props?.reload && window['__fw_portfolio']) {
            return window['__fw_portfolio'];
        }
        return window['__fw_portfolio'] = Portfolio.load(props);
    }
    static load(props) {
        let data = globalThis?.globalContext?.portfolioData ?? globPortfolioData;
        data = props?.data ? props?.data(data) : data;
        if (isDevEnv())
            console.debug('Loaded Portfolio data: ', data);
        return new Portfolio(data, props?.dispatch);
    }
    getScope() {
        return getGlobalContext()?.scope;
    }
    hasScope(level, id) {
        return Portfolio.hasScope(level, id);
    }
    static hasScope(level, id) {
        const { scope } = getGlobalContext();
        return scope?.level === level && (!id || scope.id == id);
    }
    static hasPropertyScope(id) {
        return Portfolio.hasScope('property', id);
    }
    static hasRentalObjectScope(id) {
        return Portfolio.hasScope('rentalObject', id);
    }
    updateScope(scope) {
        if (scope === null) {
            window.location.href = global.addScopeToUrl(window.location.href, 'portfolio', 0);
            return;
        }
        switch (scope?.level) {
            case 'property':
                window.location.href = global.addScopeToUrl(window.location.href, 'property', scope.id);
                break;
            case 'rentalObject':
                window.location.href = global.addScopeToUrl(window.location.href, 'rentalObject', scope.id);
                break;
        }
    }
    getFavourites() {
        if (!this.hasFavourites())
            return null;
        return {
            properties: Array.from(this.favourites.properties.values()),
            rentalObjects: Array.from(this.favourites.rentalObjects.values())
        };
    }
    getFavouritesCount() {
        return this.favourites.properties.size + this.favourites.rentalObjects.size;
    }
    hasFavourites() {
        return this.getFavouritesCount() > 0;
    }
    isFavouriteProperty(id) {
        return this.favourites.properties.has(id);
    }
    toggleFavouriteProperty(id, add = !this.isFavouriteProperty(id)) {
        const item = this.getPropertyById(id);
        if (item) {
            add ? this.favourites.properties.set(item.id, item) : this.favourites.properties.delete(item.id);
            saveFavouritesNew({
                properties: Array.from(this.favourites.properties.keys()),
                rentalObjects: Array.from(this.favourites.rentalObjects.keys())
            });
        }
    }
    isFavouriteRentalObject(id) {
        return this.favourites.rentalObjects.has(id);
    }
    toggleFavouriteRentalObject(id, add = !this.isFavouriteRentalObject(id)) {
        const item = this.getRentalObjectById(id);
        if (item) {
            add ? this.favourites.rentalObjects.set(item.id, item) : this.favourites.rentalObjects.delete(item.id);
            saveFavouritesNew({
                properties: Array.from(this.favourites.properties.keys()),
                rentalObjects: Array.from(this.favourites.rentalObjects.keys())
            });
        }
    }
    getMandates() {
        let result = Array.from(this.mandatesMap.values());
        return result.sort(function (a, b) {
            return a.name.localeCompare(b.name, Translator.locale, {
                numeric: true,
                sensitivity: 'base'
            });
        });
    }
    findProperties(options) {
        return mapArray(this.propertiesMap, options);
    }
    getPropertyById(id) {
        return this.propertiesMap.get(id);
    }
    getProperties(filter) {
        return this.findProperties({ filter });
    }
    getPropertiesByMandateId(id, sort) {
        return this.findProperties({
            filter: v => v.mandate.id === id,
            sort
        });
    }
    getPropertyByRentalObjectId(id) {
        return this.getPropertyById(this.getBuildingByRentalObjectId(id)?.propertyId);
    }
    findBuildings(options) {
        return mapArray(this.buildingsMap, options);
    }
    getBuildingById(id) {
        return this.buildingsMap.get(id);
    }
    getBuildings(filter) {
        return this.findBuildings({ filter });
    }
    getBuildingsByPropertyId(id, sort) {
        return this.findBuildings({
            filter: v => v.propertyId === id,
            sort
        });
    }
    getBuildingByRentalObjectId(id) {
        return this.getBuildingById(this.getRentalObjectById(id)?.buildingId);
    }
    findRentalObjects(options) {
        return mapArray(this.rentalObjectsMap, options);
    }
    getRentalObjectById(id) {
        return this.rentalObjectsMap.get(id);
    }
    getRentalObjects(filter) {
        return this.findRentalObjects({ filter });
    }
    getRentalObjectsByBuildingId(id, sort) {
        return this.findRentalObjects({
            filter: v => v.buildingId === id,
            sort
        });
    }
    getRentalObjectsByPropertyId(id, sort) {
        const buildingIds = this.getBuildingsByPropertyId(id).map(v => v.id);
        return this.findRentalObjects({
            filter: v => buildingIds.includes(v.buildingId),
            sort
        });
    }
    findContracts(options) {
        return mapArray(this.contractsMap, options);
    }
    getContracts(filter) {
        return this.findContracts({ filter });
    }
    getContractById(id) {
        return this.contractsMap.get(id);
    }
    getContractsByRentalObjectId(id) {
        return this.getContracts(c => c.rentalObjectId === id);
    }
    getContractsByBuildingId(id) {
        const ids = this.getRentalObjectsByBuildingId(id).map(ro => ro.id);
        return this.getContracts(c => ids.includes(c.rentalObjectId));
    }
    getContractsByPropertyId(id) {
        const ids = this.getRentalObjectsByPropertyId(id).map(ro => ro.id);
        return this.getContracts(c => ids.includes(c.rentalObjectId));
    }
}
export default Portfolio;
export function mapArray(map, props) {
    let result = Array.from(map.values());
    if (props?.filter)
        result = result.filter(props.filter);
    if (props?.sort)
        result = props.sort === true
            ? result.sort(() => 1)
            : result.sort(props.sort);
    return result;
}
