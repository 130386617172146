import React from "react";
import { useObjectViewModal } from "Components/ObjectViewModal";
import { useBuildingView } from "Components/ObjectViewModal/hooks/useBuildingView";
export const BuildingView = ({ buildingId, getBuildingDetails, updateBuilding }) => {
    const { updateBuildingNameForBreadcrumb } = useObjectViewModal();
    const view = useBuildingView(getBuildingDetails, updateBuilding, updateBuildingNameForBreadcrumb);
    React.useEffect(() => {
        view.load({ id: buildingId });
    }, [buildingId]);
    return view.render();
};
