import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useCallback, useMemo } from 'react';
import { Button } from 'Atoms/Button';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { BaseInput } from '../base';
import './theme';
import { FlexRow } from 'Atoms/Layout';
import { DateManager } from './helpers';
import { Select } from 'Atoms/Select';
import classnames from 'classnames';
import { range } from '@f2w/utils';
function useDatePicker({ type, value, minDate, maxDate, ...rest }) {
    const manager = useMemo(() => DateManager({
        minDate, maxDate
    }), [minDate, maxDate]);
    const startDate = useMemo(() => manager.castDate(rest.startDate), [manager, rest.startDate]);
    const endDate = useMemo(() => manager.castDate(rest.endDate), [manager, rest.endDate]);
    const selected = useMemo(() => {
        let val = rest.selected ?? value;
        if (!val) {
            if (rest.selectsEnd) {
                return endDate;
            }
            else {
                return startDate;
            }
        }
        return manager.castDate(val);
    }, [manager, rest.selected, value, rest.selectsStart, rest.selectsEnd, endDate, startDate]);
    return [
        {
            type,
            ...manager,
        },
        {
            dateFormat: 'dd.MM.yyyy',
            ...rest,
            minDate: manager.minDate,
            maxDate: manager.maxDate,
            selected,
            startDate,
            endDate,
        }
    ];
}
export const DatePicker = forwardRef(({ className, onChange: _onChange, $variant, $size, variant, minWidth, ...iProps }, ref) => {
    const [manager, props] = useDatePicker(iProps);
    const typeProps = useMemo(() => {
        switch (manager.type) {
            case 'year':
                return {
                    dateFormat: 'yyyy',
                    showYearPicker: true,
                    yearItemNumber: 16,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: props?.date?.getFullYear() })),
                };
            case 'quarter':
                return {
                    dateFormat: 'MMMM, yyyy',
                    showQuarterYearPicker: true,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: _jsx(DatePickerHeader.YearSelect, { ...props, dateManager: manager }) })),
                };
            case 'month':
                return {
                    dateFormat: 'MMMM, yyyy',
                    showMonthYearPicker: true,
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.YearsNav, { ...props, children: _jsx(DatePickerHeader.YearSelect, { ...props, dateManager: manager }) })),
                };
            default:
                return {
                    dateFormat: 'dd.MM.yyyy',
                    renderCustomHeader: (props) => (_jsx(DatePickerHeader.NavMonths, { ...props, children: _jsxs(FlexRow, { justifySpaceEvenly: true, w: '100%', children: [_jsx(DatePickerHeader.YearSelect, { ...props, dateManager: manager }), _jsx(DatePickerHeader.MonthSelect, { ...props, dateManager: manager })] }) })),
                };
        }
    }, [manager.type]);
    const onChange = useCallback((value, event) => {
        if (value instanceof Date) {
            if (iProps.selectsEnd) {
                if (manager.type === 'month') {
                    const endDate = moment(new Date(value.getFullYear(), value.getMonth(), 1));
                    value = endDate.add(1, 'month').subtract(1, 'day').toDate();
                }
                else if (manager.type === 'year') {
                    value = new Date(value.getFullYear(), 11, 31);
                }
            }
        }
        _onChange?.(value, event, manager);
    }, [_onChange]);
    return (_jsx(ReactDatePicker, { ...{
            ref: ref,
            popperProps: { strategy: 'fixed' },
            autoComplete: 'off',
            calendarStartDay: 1,
            preventOpenOnFocus: true,
            calendarClassName: classnames('fw-menu', `fw-menu--${variant ?? 'light'}`, {
                'fw-menu--inline': props.inline,
            }),
            ...props,
            ...typeProps,
            onChange,
            customInputRef: 'ref',
            customInput: _jsx(BaseInput, { ...{
                    style: minWidth ? { maxWidth: 160 } : {},
                    className,
                    icon: 'calendar',
                    $variant,
                    $size,
                } }),
        } }));
});
var DatePickerHeader;
(function (DatePickerHeader) {
    const BaseNav = ({ size = 'md', variant = 'linkFill', color, prev, next, children, ...props }) => (_jsx(Button.ButtonTheme, { size: size, variant: variant, color: color, children: _jsxs(FlexRow, { justifySpaceBetween: true, alignCenter: true, ...props, children: [_jsx(Button, { label: '<', ...prev, disabled: !prev || prev?.disabled }), children, _jsx(Button, { label: '>', ...next, disabled: !next || next?.disabled })] }) }));
    DatePickerHeader.NavMonths = ({ theme, children, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, }) => _jsx(BaseNav, { ...theme, prev: { onClick: decreaseMonth, disabled: prevMonthButtonDisabled }, next: { onClick: increaseMonth, disabled: nextMonthButtonDisabled }, children: children });
    DatePickerHeader.YearsNav = ({ theme, children, decreaseYear, increaseYear, prevYearButtonDisabled, nextYearButtonDisabled, }) => _jsx(BaseNav, { ...theme, prev: { onClick: decreaseYear, disabled: prevYearButtonDisabled }, next: { onClick: increaseYear, disabled: nextYearButtonDisabled }, children: children });
    function renderSelect(props) {
        return (_jsx(Select, { "$inline": true, menuShouldBlockScroll: true, ...props }));
    }
    DatePickerHeader.YearSelect = ({ dateManager: { minDate, maxDate }, date, changeYear, $size = 'xs', }) => {
        const currentYear = date?.getFullYear();
        const yearOptions = useMemo(() => {
            const minYear = minDate.getFullYear();
            const maxYear = maxDate.getFullYear();
            return range(maxYear - minYear + 1, minYear).map(year => ({
                value: year,
                label: year,
            }));
        }, [minDate, maxDate]);
        const value = useMemo(() => currentYear && yearOptions.find(o => o.value == currentYear), [yearOptions, currentYear]);
        return renderSelect({
            options: yearOptions,
            value,
            isSearchable: true,
            $size,
            onChange: (option) => {
                changeYear(option?.value);
            },
        });
    };
    DatePickerHeader.MonthSelect = ({ dateManager: { minDate, maxDate, castDate }, monthDate: date, changeMonth, $size = 'xs', }) => {
        const currentYear = date?.getFullYear();
        const currentMonth = date?.getMonth();
        const monthOptions = useMemo(() => {
            return moment.months().map((label, value) => ({
                value, label
            }));
        }, [minDate, maxDate]);
        const value = useMemo(() => {
            return currentMonth && monthOptions.find(o => o.value == currentMonth);
        }, [monthOptions, currentMonth]);
        return renderSelect({
            options: monthOptions,
            value,
            isSearchable: true,
            $size,
            onChange: (option) => {
                changeMonth(option?.value);
            },
        });
    };
})(DatePickerHeader || (DatePickerHeader = {}));
